import { AlertType } from '../shared/alert.model';
import { Sparte } from '../integrationlayer/api.model';
import { LinkListGroupItem } from '../shared/link-list-group-item.model';

export const JSON_PATH = 'projects/meine.wienenergie.at/assets/json/';
export const IMG_PATH = 'projects/meine.wienenergie.at/assets/images/';
export const MEDIA_PATH = 'projects/meine.wienenergie.at/assets/media/';

export interface ICampaignData {
  validFrom?: string;
  validTo?: string;
  slider: ICampaignSlider;
  tariffSelection: CampaignTariffSelectionData;
}

export interface CampaignTariffSelectionData {
  isEnabled: boolean;
  alert?: {
    title: string;
    text: string;
  };
  voucher?: CampaignTariffSelectionVoucherData;
}

export interface CampaignTariffSelectionVoucherData {
  isEnabledFor: string[];
  defaultValue?: string;
  infoText?: string;
  faq?: LinkListGroupItem[];
}

export interface FrontpageContentData {
  isEnabled: boolean;
  titleSmall?: string;
  titleLarge?: string;
  subtitle: string;
  copyText: string;
  link?: {
    name: string;
    target: string;
  };
  imageUri: string;
  imageHref?: string;
  imageAlt?: string;
  imageSmartphoneBg?: boolean;
  legalText: string;
}

export interface FrontpageData {
  content: FrontpageContentData;
  alert?: FrontpageAlertData;
}

export interface ICampaignSlider {
  isEnabled: boolean;
  text: string;
  popup: ICampaignSliderPopup;
}

export interface ICampaignSliderPopup {
  title: string;
  newClientText: string;
  newClientTextParagraph: string;
  newClientButton: string;
  newClientUri: string;
  tariffChangeText: string;
  tariffChangeTextParagraph: string;
  tariffChangeButton: string;
  tariffChangeUri: string;
}

export interface ILiveChatConfigurationOpeningTimes {
  openFrom: string;
  openUntil: string;
}

export interface ILiveChatConfiguration {
  mondayToThursday: ILiveChatConfigurationOpeningTimes;
  friday: ILiveChatConfigurationOpeningTimes;
}

export interface IPdfBillActivationConfiguration {
  campaign: IPdfBillActivationCampaign;
}

export interface IPdfBillActivationCampaign {
  title: string;
  reason_1: string;
  reason_2: string;
  agreement: string;
  btnSuccess: string;
  btnCancel: string;
}

export interface ISepaBillActivationConfiguration {
  campaign: ISepaBillActivationCampaign;
}

export interface ISepaBillActivationCampaign {
  header: string;
  title: string;
  subtitle: string;
  imgSrc: string;
  reason_1: string;
  reason_2: string;
  reason_3: string;
  btnSuccess: string;
  btnCancel: string;
}

export interface BannerData {
  id: string;
  banner: BannerContentData;
  popup: BannerPopupData;
  dataForCustomLogic?: BannerCustomLogicData;
  validFrom?: string;
  validTo?: string;
}

export interface BannerContentData {
  hideAfterFirstUserInteraction?: boolean;
  hideOnEmptyProductsOverview?: boolean;
  icon?: string;
  content: string;
  button: BannerButtonData;
}

export interface BannerPopupData {
  title: string;
  content: string;
  image: string;
  acceptButton: BannerButtonData;
  rejectButton: string;
}

export interface BannerButtonData {
  text: string;
  click: {
    showPopup?: boolean;
    internalRoute?: string;
    externalUrl?: {
      url: string;
      openInNewTab?: boolean;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BannerCustomLogicData {
  // at the moment we don't have any custom logic
}

export interface MaintenanceStaticResponse {
  maintenance: IMaintenanceData;
  toggledOutInfo: IMaintenanceData;
}

export interface IMaintenanceData {
  headline: string;
  subheadline?: string;
  text: string;
  button: IMaintenanceButton;
  buttonAnonymous?: IMaintenanceButton;
}

export interface IMaintenanceButton {
  text: string;
  url?: string;
  isActive?: boolean;
  isDirectExternalUrl?: boolean;
  newTabForExternalUrl?: boolean;
}

export interface InfoText {
  code?: string;
  title?: string;
  description?: string;
  link?: {
    name: string;
    target: string;
  };
}

export interface InfoTextList {
  rechnungen: {
    zahlungsproblemeLoesen: { ratenplan: InfoText[] };
    interaktiveRechnung: {
      details: {
        rechnungsDetails: InfoText[];
        rechnungsSumme: InfoText[];
        teilBetraege: InfoText[];
        enthalteneProdukte: InfoText[];
      };
      produktDetails: {
        tarifKarte: InfoText[];
        energieVerbrauch: InfoText[];
        berechnung: InfoText[];
        energieQuellen: InfoText[];
      };
      teilBetraege: InfoText[];
    };
  };
  tarifwelt: {
    optionen: Record<'zusammensetzung' | 'preisobergrenze' | 'vertragsbindung' | 'zusatzoptionen', InfoText[]>;
  };
}

export interface InteractiveInvoiceStaticData {
  alert?: {
    type: string;
    icon: string;
    title: string;
    text: string;
    urls: string[];
  };
  faqList?: StaticFAQList;
}

export interface FrontpageAlertData {
  type: AlertType;
  title: string;
  message: string;
  showIcon?: boolean;
  icon?: string;
  validFrom?: string;
  validTo?: string;
}

export interface PushPdfData {
  changeToPdfInvoiceBox: PushPdfInfo;
  changeToSepaBox: PushPdfInfo;
  changeToSepaBoxNoStrom: PushPdfInfo;
  boniForChangeToSepa: PushPdfInfo;
  kundeWerdenSelectSepa: PushPdfInfo & { summaryText?: string };
}

export interface PushPdfInfo {
  isVisible: boolean;
  iconClass: string;
  title: string;
  text: string;
  btn?: string;
}

interface SpotConfig {
  tariffKey: string;
  url: string;
}

export interface ProductDetailsStaticData {
  tariffCard: ProductDetailsStaticDataTariffCard;
  discountUnits: ProductDetailsStaticDataDiscountUnit[];
  spot: SpotConfig[];
}

export interface ProductDetailsStaticDataTariffCard {
  allowedCategories: ProductDetailsStaticDataTariffCardData[];
}

export interface ProductDetailsStaticDataTariffCardData {
  enabled: boolean;
  category: Sparte | string;
  tariffs: ProductDetailsStaticDataTariffCardDataTariff[];
}

export interface ProductDetailsStaticDataTariffCardDataTariff {
  key: string;
  discounts: string[];
}

export interface ProductDetailsStaticDataDiscountUnit {
  key: string;
  value: string;
  code: ProductDetailsStaticDataDiscountUnitCode;
}

export type ProductDetailsStaticDataDiscountUnitCode = 'fet' | 'cent';

export interface StaticFAQList {
  title: string;
  isEnabledFor: string[];
  items: LinkListGroupItem[];
}

<mwe-basic-card [hasBackground]="true" class="d-block mb-4">
  <mwe-basic-card-title
    *ngIf="isTitleVisible"
    [cardTitle]="sepaTitle"
    iconClass="fas fa-wallet"
    headingNodeType="h2"
  ></mwe-basic-card-title>

  <ng-container *ngIf="isSepaOrZahlscheinSliderVisible">
    <mwe-form
      #paymentForm
      *ngIf="showPaymentSelectForm"
      [formModel]="paymentSelectionForm"
      (formIsValid)="onPaymentFormChange($event)"
      (formIsInvalid)="onPaymentFormChange($event)"
    ></mwe-form>
    <p *ngIf="!showPaymentSelectForm" class="control-label mb-2 d-inline-block" [translate]="'invoiceInformation.paymentOptions.title'"></p>
    <p *ngIf="!showPaymentSelectForm" class="text--big" translate="{{ toggleInvoiceLabel }}"></p>
  </ng-container>

  <mwe-iban-form
    *ngIf="invoiceSEPA"
    [config]="ibanConfig"
    (formSubmitted)="ibanFormSubmitted($event)"
    (formError)="ibanFormError($event)"
  ></mwe-iban-form>
</mwe-basic-card>

<mwe-basic-card
  [hasBackground]="true"
  *ngIf="isInternationalAddressInputVisible"
  [cardTitle]="newInvoiceAddressTitle"
  headingNodeType="h2"
  iconClass="fas fa-home"
  class="d-block mb-4"
>
  <div class="pb-2" data-test="invoice-information-international-address-selection">
    <mwe-international-address-input
      [initialOlavEntry]="invoiceInfoConfig.initialAddressData?.olavEntry"
      [initialDataForManualForm]="invoiceInfoConfig.initialAddressData?.address"
      (selectionFinishedEvent)="checkAddress()"
    ></mwe-international-address-input>
  </div>
</mwe-basic-card>

<mwe-basic-card
  [hasBackground]="true"
  *ngIf="isERechnungVisible"
  [cardTitle]="eRechnungTitle"
  headingNodeType="h2"
  iconClass="fas fa-envelope-open-text"
  class="d-block mb-4"
>
  <div class="row pb-3">
    <div *ngIf="showInvoiceSelectForm">
      <mwe-form
        #invoiceForm
        [formModel]="invoiceSelectionForm"
        (formIsValid)="onInvoiceFormChange($event)"
        (formIsInvalid)="onInvoiceFormChange($event)"
      ></mwe-form>
    </div>
    <p *ngIf="!showInvoiceSelectForm" class="text--big mb-0 pt-0 pb-2" translate="{{ toggleERechnungLabel }}"></p>
    <div *ngIf="erechnungEnabled" data-test="invoice-information-eBilling-text">
      <mwe-form
        #emailForm
        [formModel]="emailAddressForm"
        (formIsValid)="onEmailFormChange($event, true)"
        (formIsInValid)="onEmailFormChange($event, false)"
      ></mwe-form>
    </div>
    <div *ngIf="!erechnungEnabled && address" data-test="invoice-information-eBilling-text">
      <mwe-form #emailForm [formModel]="readonlyAddressForm"></mwe-form>
    </div>
  </div>
  <div class="row pb-7" *ngIf="invoiceInfoConfig.customerDataChangeEmailMsgKey">
    <div class="col-12 col-sm-12" data-test="invoice-information-customerDataChangeEmailMsg-text">
      <p
        class="text--big mb-0 pt-1"
        translate="{{ invoiceInfoConfig.customerDataChangeEmailMsgKey }}"
        [translateParams]="{
          email: erechnungData?.rechnungEmail,
          address: address,
        }"
      ></p>
    </div>
  </div>

  <mwe-alert
    *ngIf="showInvoiceAddressChangeWarning"
    type="warning"
    [messageKey]="eRechnungAddressChangeKey"
    [placeholderMap]="addressChangeWarningPlaceholders"
  ></mwe-alert>
</mwe-basic-card>

import { Injectable } from '@angular/core';
import {
  CommonMappingData,
  everyTrue,
  IBulletConfirmationStatusChangeResponse,
  IOrderDetailsInfo,
  IProduktAuswahlDetails,
  ITarif,
  IVertragskonto,
  TariffChangeDTO,
  Tarifwechsel,
} from '@mwe/models';
import { AbstractBackendProcessHandler } from '../backend-process/backend-process-handler';
import { BackendProcessStore } from '../backend-process/backend-process.store';
import { LoggingService } from '../logging/logging.service';
import { TariffChangeStateService } from './tariff-change-state.service';
import { TariffChangeService } from './tariff-change.service';
import {
  decryptIBAN,
  getBankDetailsFrom,
  getExtendedHashCode,
  getFormattedCategory,
  getOrderDetailsForProduktSelektion,
  hasVoucherInfoForCategory,
  isArrayWithMinOneItem,
  sortAccountCategoriesMapping,
  sortTariffByAccountNumberAndCategory,
  wasSepaChanged,
} from '@mwe/utils';
import { StepDefinition, StepText, SubmitIdentifier } from '../backend-process/backend-process-step.model';
import { ServiceStateEnum } from '@mwe/constants';

@Injectable({ providedIn: 'any' })
export class TariffChangeBackendHandlerService extends AbstractBackendProcessHandler {
  hasSepaChanges = false;
  decryptedInvoiceData: IVertragskonto;
  dataDto?: TariffChangeDTO;
  isSecureOffer = false;
  stepBuilder?: (dto: TariffChangeDTO) => StepDefinition[];
  retried = false;

  constructor(
    public processStore: BackendProcessStore,
    private loggingService: LoggingService,
    private tariffChangeStateService: TariffChangeStateService,
    private tariffChangeService: TariffChangeService,
  ) {
    super();
  }

  async getDecryptedInvoiceData(): Promise<IVertragskonto> {
    if (!this.decryptedInvoiceData) {
      this.decryptedInvoiceData = (await decryptIBAN(
        this.tariffChangeStateService.invoiceData,
        this.tariffChangeStateService.tariffChangeAddress?.hashCode,
      )) as IVertragskonto;
    }
    return this.decryptedInvoiceData;
  }

  async checkForSepaChanges(): Promise<void> {
    for (const twProduct of this.tariffChangeStateService.selectedTariffs.anlagen) {
      if (!this.hasSepaChanges) {
        const orderDetail = getOrderDetailsForProduktSelektion(this.tariffChangeStateService.orderDetails, twProduct);
        const decryptedEingangskonto = await decryptIBAN(
          orderDetail?.verrechnungskonto?.eingangskonto,
          this.tariffChangeStateService.tariffChangeAddress?.hashCode,
        );
        const decryptedOrderDetail = { verrechnungskonto: { eingangskonto: decryptedEingangskonto } } as IOrderDetailsInfo;
        if (wasSepaChanged(this.decryptedInvoiceData, decryptedOrderDetail)) {
          this.hasSepaChanges = true;
        }
      }
    }
  }

  buildWatchStatusDTO(
    mappingData: CommonMappingData[],
    tarifwechsel: Tarifwechsel[],
    customerDataChangeData: { email: string; customerNumbers: string[] },
  ): TariffChangeDTO {
    return {
      mappingData,
      tarifwechsel,
      customerDataChangeData,
    } as unknown as TariffChangeDTO;
  }

  buildDataDTO(rebuild = false): TariffChangeDTO {
    if (!this.dataDto || rebuild) {
      this.dataDto = this.buildDataChangeDTO();
    }
    return this.dataDto;
  }

  override async initializeProcess(process: BackendProcessStore, isRetry: boolean): Promise<void> {
    if (isRetry) {
      // we don't change anything on retry
      return;
    }
    // create steps from dto
    const dto = this.dataDto;
    if (!dto) {
      throw new Error('This should not happen. The dto should be created before initializing the process');
    }
    this.buildSteps(process, dto);
  }

  override sendConfirm(): Promise<IBulletConfirmationStatusChangeResponse> {
    return this.tariffChangeService.confirm(this.dataDto, this.isSecureOffer).then(r => r.payload);
  }

  override getConfirmStatus(submitId: SubmitIdentifier): Promise<IBulletConfirmationStatusChangeResponse> {
    return this.tariffChangeService.confirmStatus(submitId).then(tce => {
      // if (this.infoMode) {
      // code to map data
      // for old entries we have user either the accountNummber or the customerNumber to define bullet ids
      /** @TODO if handler is used in tariff change we need to make this BackendProcessStore compatible */
      // mapProcessStatusForOldEntries(tce, this.bulletIds, this.commonMappingData, this.customerDataChangeData);
      // }
      return tce;
    });
  }

  override sendRetry(submitId: SubmitIdentifier): Promise<IBulletConfirmationStatusChangeResponse> {
    if (this.retried) {
      throw new Error('Already retried');
    }
    this.retried = true;
    return this.tariffChangeService.retry(submitId, this.dataDto).then(r => r.payload);
  }

  override shouldAutoRetry(): boolean {
    return !this.retried;
  }

  protected getRelevantSystems(): IProduktAuswahlDetails[] {
    return this.tariffChangeStateService.selectedTariffs.anlagen.filter(a => {
      if (isArrayWithMinOneItem(this.tariffChangeStateService.userSelectedOffers)) {
        return this.tariffChangeStateService.userSelectedOffers.some(id => a.anlageId === id);
      }
      return true;
    });
  }

  protected handleVouchers(tariffChanges: Tarifwechsel[], tariffs: ITarif[]): void {
    tariffChanges.forEach(tc => {
      const tarif = tariffs.find(tariff => tariff.shortcut === tc.tarifTyp);
      if (tarif) {
        tc.tarifBezeichnung = tarif.name;
      }
      if (hasVoucherInfoForCategory(this.tariffChangeStateService.voucherInfo, tarif.typ)) {
        tc.aktionen.push({
          aktionsCode: this.tariffChangeStateService.voucherInfo.aktionscode,
          zusatzInformationen: this.tariffChangeStateService.voucherInfo.zusatzInformationen,
        });
      }
    });
  }

  protected buildDataChangeDTO(): TariffChangeDTO {
    const tariffChanges: Tarifwechsel[] = [];
    const tariffs: ITarif[] = [];
    const mappingDataMap = new Map<string, CommonMappingData>();
    const relevantAnlagen = this.getRelevantSystems();
    relevantAnlagen.forEach(twProduct => {
      const orderDetails = getOrderDetailsForProduktSelektion(this.tariffChangeStateService.orderDetails, twProduct);
      if (!orderDetails) {
        this.loggingService.logError(
          new Error('TC Object Does Not Exist'),
          JSON.stringify(this.tariffChangeStateService.orderDetails) + ' should have match for ' + JSON.stringify(twProduct),
        );
      }
      tariffs.push({
        shortcut: twProduct.tarif.ISUTarifKey,
        name: twProduct.tarif.tarifBeschreibung,
        typ: twProduct.sparte,
      });
      tariffChanges.push({
        geschaeftsPartnerId: twProduct.customerNumber,
        vertragsKontoNummer: twProduct.accountId,
        anlageId: twProduct.anlageId,
        tarifTyp: twProduct.tarif.ISUTarifKey,
        isuTarifKey: twProduct.tarif.ISUTarifKey,
        tarifBezeichnung: twProduct.tarif.tarifName,
        aktionen: [],
        sparte: twProduct.sparte.substring(0, 1).toUpperCase() + twProduct.sparte.substring(1),
        ruecktrittsFristAbwarten: !this.tariffChangeStateService.immediateDelivery,
        tarifOptionen: twProduct.tarif.tarifOptionen,
      });
      if (!mappingDataMap.has(twProduct.accountId)) {
        mappingDataMap.set(twProduct.accountId, {
          customerNumber: twProduct.customerNumber,
          businessPartnerNumber: twProduct.customerNumber,
          accountNumber: twProduct.accountId,
          categories: [],
        });
      }
      const mappingData = mappingDataMap.get(twProduct.accountId);
      mappingData.categories.push(twProduct.sparte);

      if (this.hasSepaChanges) {
        mappingData.changeSepaMandat = true;
      }
      if (
        everyTrue(
          !orderDetails?.eRechnungInfo?.isERechnungsEmpfaenger,
          this.tariffChangeStateService.eRechnungsInfo?.isERechnungsEmpfaenger,
        )
      ) {
        mappingData.changeERechnung = true;
      }
    });

    this.handleVouchers(tariffChanges, tariffs);

    let _customerDataChangeData;
    if (this.tariffChangeStateService.customerDataChangeData) {
      _customerDataChangeData = {
        email: this.tariffChangeStateService.customerDataChangeData.email,
        customerNumbers: [...Array.from(new Set(this.tariffChangeStateService.customerDataChangeData.customerNumbers))].sort((a, b) =>
          a > b ? 1 : -1,
        ),
      };
    }
    const dob = this.tariffChangeStateService.differentInvoiceRecipient?.physischePerson.geburtsdatum;
    if (dob && dob.indexOf('T') === -1) {
      this.tariffChangeStateService.differentInvoiceRecipient.physischePerson.geburtsdatum = dob + 'T00:00:00';
    }
    const mappingDataArray = [];
    mappingDataMap.forEach(value => mappingDataArray.push(value));
    return {
      addressCode: getExtendedHashCode(this.tariffChangeStateService.tariffChangeAddress),
      differentInvoiceRecipient: this.tariffChangeStateService.differentInvoiceRecipient,
      sepaMandat: getBankDetailsFrom(this.tariffChangeStateService.invoiceData),
      eRechnungsInfo: this.tariffChangeStateService.eRechnungsInfo,
      voucher: this.tariffChangeStateService.voucherInfo,
      tarifwechsel: sortTariffByAccountNumberAndCategory(tariffChanges),
      customerDataChangeData: _customerDataChangeData,
      mappingData: sortAccountCategoriesMapping(mappingDataArray),
      priceIndications: this.tariffChangeStateService.selectedPriceIndications,
    };
  }

  protected buildStepsFromMappingData(process: BackendProcessStore, dto: TariffChangeDTO): void {
    const mappingData = dto.mappingData ?? [];
    const categories: string[] = [];
    mappingData.forEach(md => {
      md.categories.forEach(cat => {
        if (!categories.includes(cat)) {
          categories.push(cat);
        }
      });
      if (md.changeERechnung) {
        process.appendStep({
          stepId: `erechnung_${md.orderIdx}`,
          title: 'tariffChange.confirmation.stepPdfRechnungChange.title',
          label: 'tariffChange.confirmation.stepPdfRechnungChange.inProgress',
          translationParams: {
            accountId: md.accountNumber,
            category: md.categories.map(getFormattedCategory).join(', '),
          },
          textMap: new Map<ServiceStateEnum, Partial<StepText>>([
            [ServiceStateEnum.ERROR, { label: 'tariffChange.confirmation.stepPdfRechnungChange.error' }],
            [ServiceStateEnum.SUCCESS, { label: 'tariffChange.confirmation.stepPdfRechnungChange.success' }],
          ]),
        });
      }
      if (md.changeSepaMandat) {
        process.appendStep({
          stepId: `sepa_${md.orderIdx}`,
          title: 'tariffChange.confirmation.stepSepaChange.title',
          label: 'tariffChange.confirmation.stepSepaChange.inProgress',
          translationParams: {
            accountId: md.accountNumber,
            category: md.categories.map(getFormattedCategory).join(', '),
          },
          textMap: new Map<ServiceStateEnum, Partial<StepText>>([
            [ServiceStateEnum.ERROR, { label: 'tariffChange.confirmation.stepSepaChange.error' }],
            [ServiceStateEnum.SUCCESS, { label: 'tariffChange.confirmation.stepSepaChange.success' }],
          ]),
        });
      }
      if (dto.customerDataChangeData?.customerNumbers.includes(md.businessPartnerNumber)) {
        process.appendStep({
          stepId: `customerData_${Number(md.customerNumber)}`,
          title: 'tariffChange.confirmation.stepCustomerDataChange.title',
          label: 'tariffChange.confirmation.stepCustomerDataChange.inProgress',
          translationParams: {
            accountId: md.accountNumber,
            category: md.categories.map(getFormattedCategory).join(', '),
          },
          textMap: new Map<ServiceStateEnum, Partial<StepText>>([
            [ServiceStateEnum.ERROR, { label: 'tariffChange.confirmation.stepCustomerDataChange.error' }],
            [ServiceStateEnum.SUCCESS, { label: 'tariffChange.confirmation.stepCustomerDataChange.success' }],
          ]),
        });
      }
    });
  }

  protected buildStepsFromTariffChange(process: BackendProcessStore, dto: TariffChangeDTO): void {
    dto.tarifwechsel.forEach((tc, idx) => {
      const title = 'tariffChange.confirmation.stepTariffChange.title';
      const label = 'tariffChange.confirmation.stepTariffChange.inProgress';
      process.appendStep({
        stepId: `tariff_${idx}`,
        title,
        label,
        translationParams: {
          customerNumber: tc.geschaeftsPartnerId,
          accountId: tc.vertragsKontoNummer,
          tariff: tc.tarifBezeichnung,
          category: tc.sparte,
        },
        textMap: new Map<ServiceStateEnum, Partial<StepText>>([
          [ServiceStateEnum.ERROR, { label: 'tariffChange.confirmation.stepTariffChange.error' }],
          [ServiceStateEnum.SUCCESS, { label: 'tariffChange.confirmation.stepTariffChange.success' }],
        ]),
      });
    });
  }

  protected buildSteps(process: BackendProcessStore, dto: TariffChangeDTO): void {
    if (this.stepBuilder) {
      const steps = this.stepBuilder(dto);
      steps.forEach(step => process.appendStep(step));
      return;
    }
    this.buildStepsFromMappingData(process, dto);
    this.buildStepsFromTariffChange(process, dto);
  }
}

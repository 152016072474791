import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';
import { EnvironmentService } from '../environment/environment.service';
import { NewClientDetailsDTO, OfferStatusDTO } from '@mwe/models';
import { Observable, of } from 'rxjs';
import { NewClientStateService } from '../new-client/new-client-state.service';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private http = inject(HttpClient);
  private environmentService = inject(ENVIRONMENT_SERVICE_TOKEN) as EnvironmentService;
  private stateService = inject(NewClientStateService);

  get resourceUrl(): string {
    return `${this.environmentService.getApiUrl()}api/public/offer`;
  }

  confirmOffer(offerId: string): Observable<OfferStatusDTO> {
    if (this.stateService.offerConfirmationStatus?.offerId === offerId) {
      return of(this.stateService.offerConfirmationStatus);
    }

    return this.http.get<OfferStatusDTO>(`${this.resourceUrl}/confirm/${offerId}`);
  }

  confirmStatus(offerId: string): Observable<OfferStatusDTO> {
    return this.http.get<OfferStatusDTO>(`${this.resourceUrl}/confirmStatus?offerId=${offerId}`);
  }

  getProcessData(offerId: string): Observable<NewClientDetailsDTO> {
    return this.http.get<any>(`${this.resourceUrl}/processData?offerId=${offerId}`);
  }
}

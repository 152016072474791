<mwe-pre-load-data
  [state]="state()"
  [successTemplate]="success"
  alertMessageKey="error.service.default.message"
  alertTitleKey="error.service.default.title"
></mwe-pre-load-data>

<ng-template #success>
  @if (hasNoAddress()) {
    <mwe-no-associations-found-warning
      [navigatingBackTo]="navigatingBackTo()"
      [noAddressGroupsTitle]="noAddressGroupsTitle()"
      [noAddressGroupsText]="noAddressGroupsText()"
    ></mwe-no-associations-found-warning>
  } @else {
    @for (item of addressMultiProductCards(); track item.address?.hashCode) {
      <div class="mb-5">
        <mwe-address [address]="item.address"></mwe-address>
        <div class="row g-4">
          @for (card of item.multiProductCards; track $index) {
            <div class="col-lg-6">
              <mwe-basic-card-clickable (cardClicked)="onMultiCardClicked(item, card)">
                <div class="row">
                  <div class="col">
                    <div class="mb-2">
                      <mwe-product-icons [categories]="card.categories" size="2xl" />
                    </div>
                    <p class="mb-2"><strong [translate]="card.displayAccountNumberLabel"></strong>: {{ card.displayAccountNumber }}</p>
                    <p class="text--legal text-gray mb-0">{{ card.categories[0] | correlatedCompanyReference }}</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <i class="fas fa-chevron-right text-secondary"></i>
                  </div>
                </div>
              </mwe-basic-card-clickable>
            </div>
          }
        </div>
      </div>
    }
  }
</ng-template>

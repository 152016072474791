import { ServiceStateEnum } from '@mwe/constants';
export type StepIdentifier = string; // Identifies Step in a Process
export type SubmitIdentifier = number; // Identifies a Backend Process

export const DEFAULT_ICON_MAP = new Map<ServiceStateEnum, string>([
  [ServiceStateEnum.INIT, 'fa-clock'],
  [ServiceStateEnum.LOADED, 'fa-clock'],
  [ServiceStateEnum.INFO, 'fa-exclamation-circle'],
  [ServiceStateEnum.LOADING, 'fa-cog-circle'],
  [ServiceStateEnum.SUCCESS, 'fa-check-circle'],
  [ServiceStateEnum.ERROR, 'fa-times-circle'],
  [ServiceStateEnum.FAILED, 'fa-times-circle'],
]);

export const DEFAULT_CLASS_MAP = new Map<ServiceStateEnum, string>([
  [ServiceStateEnum.INIT, 'has-text-grey'],
  [ServiceStateEnum.LOADED, 'has-text-blue'],
  [ServiceStateEnum.INFO, 'has-text-blue'],
  [ServiceStateEnum.LOADING, 'has-text-blue'],
  [ServiceStateEnum.SUCCESS, 'has-text-green'],
  [ServiceStateEnum.ERROR, 'has-text-red'],
  [ServiceStateEnum.FAILED, 'has-text-red'],
]);

// A Step that can be rendered (i.E. as a bullet). No optional values are allowed.
export interface StepText {
  title: string;
  label: string | string[];
}
export interface StepInterface extends StepText {
  stepId: StepIdentifier;
  translationParams: object;
  iconMap: Map<ServiceStateEnum, string>;
  classMap: Map<ServiceStateEnum, string>;
  textMap: Map<ServiceStateEnum, Partial<StepText>>;
}

// The StepDefinition only requires a stepId and a title. The rest will be filled with default values if not provided.
export type StepDefinition = Partial<StepInterface> & Required<Pick<StepInterface, 'stepId' | 'title'>>;

// Class to handle default values for a Step
export function buildStepFromDefinition(step: StepDefinition): StepInterface {
  return {
    iconMap: DEFAULT_ICON_MAP,
    classMap: DEFAULT_CLASS_MAP,
    textMap: new Map<ServiceStateEnum, StepText>(),
    translationParams: {},
    label: [],
    ...step,
  } as StepInterface;
}

// The state that can be mapped to a Step
export interface StepStateInterface {
  state: ServiceStateEnum;
  errorCode?: number;
  retryAllowed?: boolean;
}

export type IdentifyableAsStep = StepInterface | StepIdentifier | StepInterface[] | StepIdentifier[];

@if (formModel) {
  <mwe-form [formModel]="formModel" (formChanged)="onFormChanged()" />

  @if (isErrorVisible()) {
    <div class="row gx-1 font-size-12 text-danger mt-1">
      <div class="col-auto"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="col"><span [translate]="errorLabel()"></span></div>
    </div>
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@mwe/models';
import { getAddressPostCodeAndStreetFormatted } from '@mwe/utils';

@Pipe({
  name: 'getAddressPostCodeAndStreet',
})
export class GetAddressPostCodeAndStreetPipe implements PipeTransform {
  transform(address: Address) {
    return getAddressPostCodeAndStreetFormatted(address);
  }
}

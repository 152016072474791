import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import {
  Address,
  IAktionen,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
  IOrderDetailsInfo,
  IProduktSelektion,
  ITariffChangeTrackingData,
  IVertragskonto,
  PredefinedTariffSelectionData,
  PreisIndikation,
  Product,
  TariffChangeAvailabilities,
  createAddress,
} from '@mwe/models';
import { ProductsWhiteListLogic } from '../products/products-white-list.logic';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { VoucherStateService } from '../voucher/voucher-state.service';

const directAddressSelectionKey = 'tariffChange_directAddressSelection';
const addressKey = 'tariffChange_address';
const productsKey = 'tariffChange_products';
const tariffChangeAvailabilitiesKey = 'tariffChange_tariffChangeAvailabilities';
const productsForSelectionKey = 'tariffChange_productsForSelection';
const selectedTariffsKey = 'tariffChange_selectedTariffs';
const predefinedTariffsKey = 'tariffChange_predefinedTariffs';
const detailsKey = 'tariffChange_details';
const eRechnungsInfoKey = 'tariffChange_eRechnungsInfo';
const submitIdKey = 'tariffChange_submitId';
const invoiceDataKey = 'tariffChange_invoiceData';
const differentInvoiceRecipientKey = 'tariffChange_differentInvoiceRecipient';
const voucherInfoKey = 'tariffChange_voucherInfo';
const predefinedVoucherCodeKey = 'tariffChange_predefinedVoucherCode';
const immediateDeliveryKey = 'tariffChange_immediateDelivery';
const lastOrderIdKey = 'tariffChange_lastOrderId';
const retriedOnErrorKey = 'tariffChange_retriedOnError';
const customerDataChangeDataKey = 'tariffChange_customerDataChangeData';
const trackingDataKey = 'tariffChange_trackingData';
const preselectedProductKey = 'tariffChange_preselectedProduct';
const selectedPriceIndicationsKey = 'tariffChange_selectedPriceIndications';
const userSelectedOffersKey = 'tariffChange_userSelectedOffers';
const hasUserSelectedOfferKey = 'tariffChange_hasUserSelectedOffer';
const isAddressSelectionVisibleKey = 'tariffChange_isAddressSelectionVisible';
const isSelectedTariffSpot = 'tariffChange_isSelectedTariffSpot';

@Injectable({ providedIn: 'root' })
export class TariffChangeStateService extends VoucherStateService {
  keepStorageOnce = false;
  // only this service can know what have to be map from this state model
  tempProductSelectionForVoucher: IProduktSelektion;
  @SessionStorage(directAddressSelectionKey)
  private _tariffChangeDirectAddressSelection: boolean;
  @SessionStorage(tariffChangeAvailabilitiesKey)
  private _tariffChangeAvailabilities: TariffChangeAvailabilities;

  constructor(
    protected override storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {
    super(storage, lastOrderIdKey, retriedOnErrorKey, submitIdKey);
  }

  @SessionStorage(addressKey)
  private _tariffChangeAddress: Address;

  get tariffChangeAddress(): Address {
    return (this._tariffChangeAddress && createAddress(this._tariffChangeAddress)) || undefined;
  }

  set tariffChangeAddress(_tariffChangeAddress: Address) {
    if (_tariffChangeAddress) {
      this._tariffChangeAddress = _tariffChangeAddress;
    } else {
      this.storage.clear(addressKey);
    }
  }

  @SessionStorage(productsKey)
  private _tariffChangeProducts: Product[];

  get tariffChangeProducts(): Product[] {
    return (this._tariffChangeProducts && this._tariffChangeProducts.map(elem => elem)) || undefined;
  }

  set tariffChangeProducts(_tariffChangeProducts: Product[]) {
    if (_tariffChangeProducts) {
      this._tariffChangeProducts = this.productsWhiteListLogic.removeNotWhiteListedProducts(_tariffChangeProducts);
    } else {
      this.storage.clear(productsKey);
    }
  }

  @SessionStorage(productsForSelectionKey)
  private _productsForSelection: Product[];

  get productsForSelection(): Product[] {
    return this._productsForSelection || undefined;
  }

  set productsForSelection(productsForSelection: Product[]) {
    if (productsForSelection) {
      this._productsForSelection = productsForSelection;
    } else {
      this.storage.clear(productsForSelectionKey);
    }
  }

  @SessionStorage(preselectedProductKey)
  private _preselectedProduct: Product;

  get preselectedProduct(): Product {
    return this._preselectedProduct || undefined;
  }

  set preselectedProduct(preselectedProduct: Product) {
    if (preselectedProduct) {
      this._preselectedProduct = preselectedProduct;
    } else {
      this.storage.clear(preselectedProductKey);
    }
  }

  @SessionStorage(selectedTariffsKey)
  private _selectedTariffs: IProduktSelektion;

  get selectedTariffs(): IProduktSelektion {
    return this._selectedTariffs || { anlagen: [] };
  }

  set selectedTariffs(selectedTariffs: IProduktSelektion) {
    if (selectedTariffs) {
      this._selectedTariffs = selectedTariffs;
    } else {
      this.storage.clear(selectedTariffsKey);
    }
  }

  @SessionStorage(predefinedTariffsKey)
  private _predefinedTariffs: PredefinedTariffSelectionData[];

  get predefinedTariffs(): PredefinedTariffSelectionData[] {
    return this._predefinedTariffs || undefined;
  }

  set predefinedTariffs(predefinedTariffs: PredefinedTariffSelectionData[]) {
    if (predefinedTariffs) {
      this._predefinedTariffs = predefinedTariffs;
    } else {
      this.storage.clear(predefinedTariffsKey);
    }
  }

  @SessionStorage(detailsKey)
  private _orderDetails: IOrderDetailsInfo[];

  get orderDetails(): IOrderDetailsInfo[] {
    return this._orderDetails || undefined;
  }

  set orderDetails(relocationDetails: IOrderDetailsInfo[]) {
    if (relocationDetails) {
      this._orderDetails = relocationDetails;
    } else {
      this.storage.clear(detailsKey);
    }
  }

  @SessionStorage(eRechnungsInfoKey)
  private _eRechnungsInfo: IERechnungsInfo;

  get eRechnungsInfo(): IERechnungsInfo {
    return this._eRechnungsInfo || undefined;
  }

  set eRechnungsInfo(eRechnungsInfo: IERechnungsInfo) {
    if (eRechnungsInfo) {
      this._eRechnungsInfo = eRechnungsInfo;
    } else {
      this.storage.clear(eRechnungsInfoKey);
    }
  }

  @SessionStorage(invoiceDataKey)
  private _invoiceData: IVertragskonto;

  get invoiceData(): IVertragskonto {
    return this._invoiceData || undefined;
  }

  set invoiceData(invoiceData: IVertragskonto) {
    if (invoiceData) {
      this._invoiceData = invoiceData;
    } else {
      this.storage.clear(invoiceDataKey);
    }
  }

  @SessionStorage(differentInvoiceRecipientKey)
  private _differentInvoiceRecipient: IDifferentInvoiceRecipient;

  get differentInvoiceRecipient(): IDifferentInvoiceRecipient {
    return this._differentInvoiceRecipient || undefined;
  }

  set differentInvoiceRecipient(invoiceAddress: IDifferentInvoiceRecipient) {
    if (invoiceAddress) {
      this._differentInvoiceRecipient = invoiceAddress;
    } else {
      this.storage.clear(differentInvoiceRecipientKey);
    }
  }

  @SessionStorage(voucherInfoKey)
  private _voucherInfo: IAktionen;

  get voucherInfo(): IAktionen {
    return this._voucherInfo || undefined;
  }

  set voucherInfo(voucherInfo: IAktionen) {
    if (voucherInfo) {
      this._voucherInfo = voucherInfo;
    } else {
      this.storage.clear(voucherInfoKey);
    }
  }

  @SessionStorage(predefinedVoucherCodeKey)
  private _predefinedVoucherCode: string;

  get predefinedVoucherCode(): string {
    return this._predefinedVoucherCode || undefined;
  }

  set predefinedVoucherCode(code: string) {
    if (code) {
      this._predefinedVoucherCode = code;
    } else {
      this.storage.clear(predefinedVoucherCodeKey);
    }
  }

  @SessionStorage(immediateDeliveryKey)
  private _immediateDelivery: boolean;

  get immediateDelivery(): boolean {
    return this._immediateDelivery || undefined;
  }

  set immediateDelivery(immediateDelivery: boolean) {
    if (immediateDelivery) {
      this._immediateDelivery = immediateDelivery;
    } else {
      this.storage.clear(immediateDeliveryKey);
    }
  }

  @SessionStorage(customerDataChangeDataKey)
  private _customerDataChangeData: { email: string; customerNumbers: string[] };

  get customerDataChangeData(): { email: string; customerNumbers: string[] } {
    return this._customerDataChangeData || undefined;
  }

  set customerDataChangeData(customerDataChangeData: { email: string; customerNumbers: string[] }) {
    if (customerDataChangeData) {
      this._customerDataChangeData = customerDataChangeData;
    } else {
      this.storage.clear(customerDataChangeDataKey);
    }
  }

  @SessionStorage(trackingDataKey)
  private _trackingData: ITariffChangeTrackingData;

  get trackingData(): ITariffChangeTrackingData {
    return this._trackingData || undefined;
  }

  set trackingData(trackingData: ITariffChangeTrackingData) {
    if (trackingData) {
      this._trackingData = trackingData;
    } else {
      this.storage.clear(trackingDataKey);
    }
  }

  @SessionStorage(selectedPriceIndicationsKey)
  private _selectedPriceIndications: PreisIndikation[];

  get selectedPriceIndications(): PreisIndikation[] {
    return this._selectedPriceIndications || [];
  }

  set selectedPriceIndications(value: PreisIndikation[]) {
    if (value) {
      this._selectedPriceIndications = value;
    } else {
      this.storage.clear(selectedPriceIndicationsKey);
    }
  }

  @SessionStorage(userSelectedOffersKey)
  private _userSelectedOffers: string[];

  get userSelectedOffers(): string[] {
    return this._userSelectedOffers || [];
  }

  set userSelectedOffers(value: string[]) {
    if (value) {
      this._userSelectedOffers = value;
    } else {
      this.storage.clear(userSelectedOffersKey);
    }
  }

  @SessionStorage(hasUserSelectedOfferKey)
  private _hasUserSelectedOffer: boolean;

  get hasUserSelectedOffer(): boolean {
    return !!this._hasUserSelectedOffer;
  }

  set hasUserSelectedOffer(value: boolean) {
    if (!value) {
      this.storage.clear(hasUserSelectedOfferKey);
      return;
    }

    this._hasUserSelectedOffer = value;
  }

  @SessionStorage(isAddressSelectionVisibleKey)
  private _isAddressSelectionVisible: boolean;

  get isAddressSelectionVisible(): boolean {
    return !!this._isAddressSelectionVisible;
  }

  set isAddressSelectionVisible(value: boolean) {
    if (!value) {
      this.storage.clear(isAddressSelectionVisibleKey);
      return;
    }

    this._isAddressSelectionVisible = value;
  }

  @SessionStorage(isSelectedTariffSpot)
  private _isSelectedTariffSpot: boolean;

  get isSelectedTariffSpot(): boolean {
    return this._isSelectedTariffSpot || false;
  }

  set isSelectedTariffSpot(value: boolean) {
    if (value) {
      this._isSelectedTariffSpot = value;
    } else {
      this.storage.clear(isSelectedTariffSpot);
    }
  }

  get directAddressSelection(): boolean {
    return this._tariffChangeDirectAddressSelection;
  }

  set directAddressSelection(isDirectAddressSelection: boolean) {
    this._tariffChangeDirectAddressSelection = isDirectAddressSelection;
  }

  get tariffChangeAvailability(): TariffChangeAvailabilities {
    return this._tariffChangeAvailabilities || undefined;
  }

  set tariffChangeAvailability(_tariffChangeAvailability: TariffChangeAvailabilities) {
    if (_tariffChangeAvailability) {
      this._tariffChangeAvailabilities = _tariffChangeAvailability;
    } else {
      this.storage.clear(tariffChangeAvailabilitiesKey);
    }
  }

  clearAll() {
    this.clearData();
    super.clear();
  }

  // VoucherService methods start

  clearData(keepLastOrderId = false): void {
    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(directAddressSelectionKey);
    this.storage.clear(addressKey);
    this.storage.clear(productsKey);
    this.storage.clear(tariffChangeAvailabilitiesKey);
    this.storage.clear(productsForSelectionKey);
    this.storage.clear(selectedTariffsKey);
    this.storage.clear(predefinedTariffsKey);
    this.storage.clear(detailsKey);
    this.storage.clear(eRechnungsInfoKey);
    this.storage.clear(submitIdKey);
    this.storage.clear(invoiceDataKey);
    this.storage.clear(differentInvoiceRecipientKey);
    this.storage.clear(voucherInfoKey);
    this.storage.clear(predefinedVoucherCodeKey);
    this.storage.clear(immediateDeliveryKey);
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(customerDataChangeDataKey);
    this.storage.clear(trackingDataKey);
    this.storage.clear(preselectedProductKey);
    this.storage.clear(selectedPriceIndicationsKey);
    this.storage.clear(userSelectedOffersKey);
    this.storage.clear(hasUserSelectedOfferKey);
    this.storage.clear(isAddressSelectionVisibleKey);
    this.storage.clear(isSelectedTariffSpot);

    if (!keepLastOrderId) {
      this.storage.clear(lastOrderIdKey);
    }
  }

  override getProductSelection(): IProduktSelektion {
    return this.tempProductSelectionForVoucher;
  }

  override getProductSelectionIds(): string[] {
    if (!isArrayWithMinOneItem(this.tempProductSelectionForVoucher?.anlagen)) {
      return [];
    }
    return this.tempProductSelectionForVoucher.anlagen.map(p => p.anlageId);
  }

  override hasAllProductsASelectedTariff(): boolean {
    if (!isArrayWithMinOneItem(this.tempProductSelectionForVoucher?.anlagen)) {
      return false;
    }

    return this.tempProductSelectionForVoucher.anlagen.length === this.productsForSelection.length;
  }

  // VoucherService methods end
}

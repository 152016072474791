import {
  IDefaultProdukt,
  IProduktAuswahlDetails,
  IProduktSelektion,
  PreisIndikation,
  Sparte,
  TariffOptionSap,
  TariffSelectionCardData,
} from '@mwe/models';
import { getCategorySequence, getUniqueValues, isArrayWithMinOneItem } from '@mwe/utils';
import { ServiceStateEnum } from '@mwe/constants';

function restoreSelectedTariffsFromSessionStorage(
  allProducts: IProduktAuswahlDetails[],
  allPriceIndications: PreisIndikation[],
  userSelectedItems: IProduktAuswahlDetails[],
  userSelectedPriceIndications: PreisIndikation[],
): TariffSelectionCardData[] {
  if (
    !isArrayWithMinOneItem(allProducts) ||
    !isArrayWithMinOneItem(allPriceIndications) ||
    !isArrayWithMinOneItem(userSelectedItems) ||
    !isArrayWithMinOneItem(userSelectedPriceIndications)
  ) {
    return [];
  }

  return userSelectedItems
    .map(sessionStorageItem => {
      if (!sessionStorageItem) {
        return null;
      }

      // when user goes back and deselects product, we have to ignore it
      const isNotAvailable = !allProducts.some(p => p.tarif.ISUTarifKey === sessionStorageItem.tarif.ISUTarifKey);
      if (isNotAvailable) {
        return null;
      }

      // userSelectedItems contains only selected tariff options
      // but for UI/dropdowns we need all tariff options,
      const tariffOptionsSap = [
        ...getTariffOptionsForSelection(allProducts, sessionStorageItem.tarif.ISUTarifKey, sessionStorageItem.anlageId),
      ];
      const userIndication = getPriceIndication(
        userSelectedPriceIndications,
        sessionStorageItem.tarif.ISUTarifKey,
        sessionStorageItem.anlageId,
      );
      const defaultIndication = getPriceIndication(allPriceIndications, sessionStorageItem.tarif.ISUTarifKey, sessionStorageItem.anlageId);
      const priceIndication = userIndication ? { ...userIndication } : { ...defaultIndication };

      return {
        category: sessionStorageItem.sparte.toLowerCase(),
        tariffKey: sessionStorageItem.tarif.ISUTarifKey,
        tariffOptionsSap,
        userSelection: [...sessionStorageItem.tarif.tarifOptionen],
        priceIndication,
        anlageId: sessionStorageItem.anlageId,
        state: ServiceStateEnum.SUCCESS,
      } as TariffSelectionCardData;
    })
    .filter(i => !!i);
}

function getMissingItemsAsDefault(
  allProducts: IProduktAuswahlDetails[],
  allPriceIndications: PreisIndikation[],
  userSelectedItems: IProduktAuswahlDetails[],
  defaultProducts: IDefaultProdukt,
): TariffSelectionCardData[] {
  if (!isArrayWithMinOneItem(allProducts) || !isArrayWithMinOneItem(allPriceIndications) || !defaultProducts) {
    return [];
  }

  if (!allProducts[0].anlageId) {
    return getMissingNewClientItems(allProducts, allPriceIndications, userSelectedItems, defaultProducts);
  }

  return getMissingTariffChangeItems(allProducts, allPriceIndications, userSelectedItems, defaultProducts);
}

function getMissingNewClientItems(
  allProducts: IProduktAuswahlDetails[],
  allPriceIndications: PreisIndikation[],
  userSelectedItems: IProduktAuswahlDetails[],
  defaultProducts: IDefaultProdukt,
): TariffSelectionCardData[] {
  const uniqueCategories = getUniqueValues(allProducts.map(p => p.sparte.toLowerCase()));

  return uniqueCategories
    .map(category => {
      // category exists already, no need to create default version
      if (userSelectedItems.some(i => i.sparte.toLowerCase() === category)) {
        return null;
      }

      const tariffKey = defaultProducts[category].tarifKey;
      const tariffOptionsSap = [...getTariffOptionsForSelection(allProducts, tariffKey)];
      const priceIndication = { ...getPriceIndication(allPriceIndications, tariffKey) } as PreisIndikation;
      const userSelection = [...tariffOptionsSap.filter(o => !!o.default)];

      if (!priceIndication?.tarif) {
        return null;
      }

      return {
        category,
        tariffKey,
        tariffOptionsSap,
        userSelection,
        priceIndication,
        state: ServiceStateEnum.SUCCESS,
      } as TariffSelectionCardData;
    })
    .filter(i => !!i);
}

function getMissingTariffChangeItems(
  allProducts: IProduktAuswahlDetails[],
  allPriceIndications: PreisIndikation[],
  userSelectedItems: IProduktAuswahlDetails[],
  defaultProducts: IDefaultProdukt,
): TariffSelectionCardData[] {
  const uniqueAnlagenIds = getUniqueValues(allProducts.map(p => p.anlageId));

  return uniqueAnlagenIds
    .map(anlageId => {
      // anlage exists already, no need to create default version
      if (userSelectedItems.some(item => item.anlageId === anlageId)) {
        return null;
      }

      const category = allProducts.find(p => p.anlageId === anlageId).sparte.toLowerCase();
      const tariffKey = defaultProducts[category].tarifKey;
      const tariffOptionsSap = [...getTariffOptionsForSelection(allProducts, tariffKey, anlageId)];
      // no anlageId -> we want default price indications
      const priceIndication = {
        ...getPriceIndication(allPriceIndications, tariffKey),
        anlageId,
      } as PreisIndikation;
      const userSelection = [...tariffOptionsSap.filter(o => !!o.default)];

      if (!priceIndication?.tarif) {
        return null;
      }

      return {
        category,
        tariffKey,
        tariffOptionsSap,
        userSelection,
        priceIndication,
        anlageId,
        state: ServiceStateEnum.SUCCESS,
      } as TariffSelectionCardData;
    })
    .filter(i => !!i);
}

function getPriceIndication(allPriceIndications: PreisIndikation[], tariffKey: string, anlageId?: string): PreisIndikation {
  // tariff change
  if (anlageId) {
    return allPriceIndications.find(p => p.anlageId === anlageId);
  }
  // new client
  return allPriceIndications.find(p => p.tarif.ISUTarifKey === tariffKey);
}

function getTariffOptionsForSelection(allProducts: IProduktAuswahlDetails[], tariffKey: string, anlageId?: string): TariffOptionSap[] {
  // tariff change
  if (anlageId) {
    return allProducts.find(p => p.tarif?.ISUTarifKey === tariffKey && p.anlageId === anlageId).tarif.tarifOptionen || [];
  }
  // new client
  return allProducts.find(p => p.tarif?.ISUTarifKey === tariffKey).tarif.tarifOptionen || [];
}

function sortTariffs(tariffs: TariffSelectionCardData[]): TariffSelectionCardData[] {
  if (!isArrayWithMinOneItem(tariffs)) {
    return [];
  }

  return [...tariffs].sort((t1, t2) => {
    const categorySort = getCategorySequence(t1.category) > getCategorySequence(t2.category);

    if (t1.anlageId && t1.category.toLowerCase() === t2.category.toLowerCase()) {
      return t1.anlageId > t2.anlageId ? 1 : -1;
    }

    return categorySort ? 1 : -1;
  });
}

function getProductSelection(allProducts: IProduktAuswahlDetails[], selectedTariffs: TariffSelectionCardData[]): IProduktSelektion {
  if (!isArrayWithMinOneItem(allProducts) || !isArrayWithMinOneItem(selectedTariffs)) {
    return {};
  }

  // tariff change
  if (selectedTariffs[0].anlageId) {
    return {
      anlagen: selectedTariffs.map(tariff => {
        return createNewProduct(allProducts, tariff);
      }),
    };
  }

  // new client
  const stromTariff = selectedTariffs.find(t => t.category.toLowerCase() === Sparte.Strom.toLowerCase());
  const gasTariff = selectedTariffs.find(t => t.category.toLowerCase() === Sparte.Gas.toLowerCase());
  return {
    strom: createNewProduct(allProducts, stromTariff),
    gas: createNewProduct(allProducts, gasTariff),
  };
}

function createNewProduct(allProducts: IProduktAuswahlDetails[], tariff: TariffSelectionCardData): IProduktAuswahlDetails {
  if (!isArrayWithMinOneItem(allProducts) || !tariff) {
    return undefined;
  }

  const product = tariff.anlageId
    ? allProducts.find(ap => ap.anlageId === tariff.anlageId && ap.tarif.ISUTarifKey === tariff.tariffKey)
    : allProducts.find(ap => ap.tarif.ISUTarifKey === tariff.tariffKey);
  return {
    ...product,
    tarif: {
      ...product.tarif,
      tarifOptionen: [...tariff.userSelection],
    },
  };
}

function updateTariffs(tariffs: TariffSelectionCardData[], update: TariffSelectionCardData): TariffSelectionCardData[] {
  const unaffectedTariffs = tariffs.filter(t => {
    // tariff change
    if (update.anlageId) {
      return t.anlageId !== update.anlageId;
    }
    // new client - compare after category, else tariff count will increase :D
    return t.tariffKey !== update.tariffKey && t.category.toLowerCase() !== update.category.toLowerCase();
  });

  const newList = [{ ...update }, ...unaffectedTariffs];
  return sortTariffs(newList);
}

function updatePriceIndication(allPriceIndications: PreisIndikation[], priceIndication: PreisIndikation): PreisIndikation[] {
  const unaffectedPriceIndications = allPriceIndications.filter(p => p.tarif.ISUTarifKey !== priceIndication.tarif.ISUTarifKey);
  return [{ ...priceIndication }, ...unaffectedPriceIndications];
}

export const tariffSelectionStoreUtils = {
  restoreSelectedTariffsFromSessionStorage,
  getMissingItemsAsDefault,
  sortTariffs,
  getProductSelection,
  createNewProduct,
  updateTariffs,
  updatePriceIndication,
};

<label class="card-selectable mb-0">
  <div class="row gx-2">
    <div class="col">
      <div class="row gx-3">
        @for (category of inputElem().content?.categories; track category) {
          <div class="col-auto text-center">
            <mwe-product-icon [category]="category" size="md" />
          </div>
        }
      </div>
      @if (inputElem().content?.after) {
        <div [translate]="inputElem().content?.after"></div>
      }
    </div>
    <div class="col-auto">
      <input
        [attr.data-test]="'form-input-' + inputElem().name"
        [attr.id]="inputElem().name"
        [name]="inputElem().name"
        [formControl]="parentForm().controls[inputElem().name]"
        [ngClass]="{
          'is-invalid': parentForm().controls[inputElem().name].errors && !parentForm().controls[inputElem().name].pristine,
          'form-check-readonly': inputElem().isReadonly,
        }"
        class="form-check-input"
        type="checkbox"
        [attr.aria-describedby]="ariaDescribedBy"
        [attr.aria-invalid]="!isValid"
        [required]="isRequired || null"
        [attr.disabled]="inputElem().isDisabled ? '' : null"
        mweClickPreventDefault
        [ignorePreventClickDefault]="!inputElem().isReadonly"
      />
    </div>
  </div>
</label>

import { ProductCategoryEnum } from '@mwe/constants';
import { Address, EnergyVoucherMapping, AddressProducts, IOrderDetailsInfo, IVertrag, Product, Sparte } from '@mwe/models';
import { getUniqueValues } from './mwe-util';
import config from '../config/product-company-correlation.json';
import {
  isProductCategory,
  isProductCategoryFernwaerme,
  isSameCategory,
  sortProductsByCategory,
  sortProductsEnumCategory,
} from '../products/product-category.utils';

export const getProductFromDetailsInfo = (detailsInfo: IOrderDetailsInfo): Product => {
  return {
    customerNumber: detailsInfo.customerNumber,
    accountNumber: detailsInfo.accountNumber,
    category: detailsInfo.category,
    tariffClasses: getTariffClassesForRelocationSummary(detailsInfo),
    address: detailsInfo.invoiceAddress as Address,
  } as Product;
};

const getTariffClassesForRelocationSummary = (detailsInfo: IOrderDetailsInfo): string[] => {
  if (
    isProductCategory(detailsInfo.category, ProductCategoryEnum.FERNWAERME) &&
    detailsInfo.availabilityDetailsFernwaerme &&
    detailsInfo.availabilityDetailsFernwaerme.length
  ) {
    return detailsInfo.availabilityDetailsFernwaerme;
  } else {
    return detailsInfo.tariff && detailsInfo.tariff.name && detailsInfo.tariff.name.length ? [detailsInfo.tariff.name] : undefined;
  }
};

export const getCommonMappingDataGroupedByAccountNumber = (products: Product[]): EnergyVoucherMapping[] => {
  const accountNumbers = getUniqueValues(products.map(p => p.accountNumber));

  return accountNumbers.map(accountNumber => {
    const filteredProducts = products.filter(p => p.accountNumber === accountNumber);
    sortProductsByCategory(filteredProducts);
    const customerNumber = filteredProducts[0].customerNumber;
    const businessPartnerNumber = filteredProducts[0].businessPartnerNumber;
    const categories = filteredProducts.map(fp => fp.category);
    const tariffKeys = filteredProducts.map(fp => fp.tarifKey);
    const tariffClasses = filteredProducts.map(fp => fp.tariffClasses);
    const anlageIds = filteredProducts.map(fp => fp.anlageId);
    const address = filteredProducts[0].address;

    return {
      accountNumber,
      customerNumber,
      businessPartnerNumber,
      categories,
      tariffKeys,
      tariffClasses,
      anlageIds,
      address,
    };
  });
};

export const getCompanyCorrelationFromCategory = (category: string): string => {
  return config.correlation[category.toLowerCase()]?.reference;
};

export const getCompanyNameFromCategory = (category: string): string => {
  return config.correlation[category.toLowerCase()]?.name;
};

export const getContract = (contracts: IVertrag[], category: string): IVertrag => {
  return contracts?.find(v => isSameCategory(v.sparte, category) && v.vertragsstatus === 'Aktiv');
};

export const getCategoriesFromAddressProductsFor = (addressProducts: AddressProducts, accountNumber: string): Sparte[] => {
  if (!addressProducts) {
    return [];
  }

  const categories = addressProducts.products.filter(p => p.accountNumber === accountNumber).map(p => p.category);
  sortProductsEnumCategory(categories);

  return categories as Sparte[];
};

export const getCategoriesFromAddressProducts = (addressProducts: AddressProducts): Sparte[] => {
  if (!addressProducts) {
    return [];
  }

  const categories = addressProducts.products.map(p => p.category);
  sortProductsEnumCategory(categories);
  return categories as Sparte[];
};

export const getCustomerNumberFrom = (category: Sparte | string, customerNumber: string): string => {
  if (!customerNumber) {
    return '';
  }

  if (isProductCategoryFernwaerme(category)) {
    return customerNumber.slice(2);
  }

  return customerNumber;
};

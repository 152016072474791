<div>
  <div class="text-center">
    <p [attr.data-test]="getTestId('arbeitspreis-label')" class="mb-0">
      {{ selectedTariff().priceIndication.arbeitspreisLabel }}
    </p>
    @if (isSpotTariff()) {
      <div class="d-flex justify-content-center">
        <mwe-info-text [infoTextCode]="infoText().infoTextCode" [customUrlOverride]="selectedTariff().priceIndication.produktUrl ?? ''">
          <a
            [attr.href]="selectedTariff().priceIndication.produktUrl"
            target="_blank"
            [attr.data-test]="getTestId('indexKey')"
            class="fs-5 fw-bold text-secondary-light mb-0 lh-1 underline"
          >
            {{ 'tariff.selectionCard.spot.indexKey' | translate }}
          </a>
        </mwe-info-text>
      </div>
      <p class="text-secondary fw-bold" [attr.data-test]="getTestId('arbeitspreis-unit')">
        {{ 'tariff.selectionCard.spot.today' | translate }}: {{ spotTariffPrice() }}
      </p>
    } @else {
      <p [attr.data-test]="getTestId('arbeitspreis-value')" class="fs-2 fw-bold text-secondary mb-0 lh-1">
        {{ selectedTariff().priceIndication.arbeitspreisZahlbar }}
      </p>
      @if (!selectedTariff().priceIndication.arbeitspreisSubtext) {
        <p [attr.data-test]="getTestId('arbeitspreis-unit')" class="fw-semibold text-secondary">
          {{ selectedTariff().priceIndication.arbeitspreisUnit }}
        </p>
      }
      @if (selectedTariff().priceIndication.arbeitspreisSubtext) {
        <p [attr.data-test]="getTestId('arbeitspreis-unit')">
          {{ selectedTariff().priceIndication.arbeitspreisSubtext }}
        </p>
      }
    }
  </div>
  <hr />

  <div class="mb-3" role="list">
    <mwe-tariff-selection-card-ui-row [attr.data-test]="getTestId('grundpreis')" [isReadOnly]="true">
      <ng-container slot="left">
        <p [attr.data-test]="getTestId('grundpreis-label')" class="fw-semibold text-secondary mb-0">
          {{ selectedTariff().priceIndication.grundpreisLabel }}:
        </p>
      </ng-container>
      <ng-container slot="right">
        <p
          [attr.data-test]="getTestId('grundpreis-value')"
          class="{{ selectedTariff().priceIndication.grundpreisSubtext ? 'fw-semibold text-secondary' : '' }} mb-0"
        >
          {{ selectedTariff().priceIndication.grundpreisZahlbar }} {{ selectedTariff().priceIndication.grundpreisUnit }}
        </p>
        @if (selectedTariff().priceIndication.grundpreisSubtext) {
          <p class="mb-0">{{ selectedTariff().priceIndication.grundpreisSubtext }}</p>
        }
      </ng-container>
    </mwe-tariff-selection-card-ui-row>

    @for (item of groups(); track $index) {
      <mwe-tariff-selection-card-ui-row [isReadOnly]="item.isReadOnly" [attr.data-test]="getTestId(item.id)">
        <ng-container slot="left">
          <mwe-info-text [infoTextCode]="item.infoTextCode">
            <p class="fw-semibold text-secondary mb-0" [attr.data-test]="getTestId(item.id + '-label')">{{ item.label }} :</p>
          </mwe-info-text>
        </ng-container>
        <ng-container slot="right">
          <mwe-tariff-option-dropdown
            [options]="item.options"
            [selectedTariffOptionId]="item.selectedTariffOptionId"
            [selectedTariffGroupId]="item.id"
            [isReadonly]="item.isReadOnly"
            (optionSelected)="onOptionSelected(item, $event)"
          ></mwe-tariff-option-dropdown>
        </ng-container>
      </mwe-tariff-selection-card-ui-row>
    }
  </div>
  <hr />
  <div class="mb-3" role="list">
    @for (label of advantages(); track $index) {
      <div class="mb-2 row gx-2" role="listitem" [attr.data-test]="getTestId('vorteile')">
        <div class="col-auto">
          <div class="green-check vertical-center">
            <i class="fas fa-check text-center fw-normal" aria-hidden="true"></i>
          </div>
        </div>
        <div class="col align-self-center">
          <p class="fw-semibold text-secondary mb-0">{{ label }}</p>
        </div>
      </div>
    }
  </div>

  @if (selectedTariff().priceIndication.aktionsHinweis) {
    <div class="mb-2 row gx-2" [attr.data-test]="getTestId('available-campaign-hint')">
      <div class="col-auto">
        <div
          class="d-flex align-items-center justify-content-center rounded-pill text-white text-center bg-warning"
          style="width: 1.5rem; height: 1.5rem; font-size: 0.75rem"
        >
          <i class="fa fa-gift text-center fw-normal" aria-hidden="true"></i>
        </div>
      </div>
      <div class="fw-semibold text-secondary col">
        {{ selectedTariff().priceIndication.aktionsHinweis }}
      </div>
    </div>
  }

  @if (selectedTariff().priceIndication.tarifInformationen?.tarifLabel) {
    <mwe-infobox type="info">
      <p class="fw-bold text-secondary mb-0" [translate]="selectedTariff().priceIndication.tarifInformationen.tarifLabel"></p>
    </mwe-infobox>
  }

  @for (item of activeCampaignTexts(); track $index) {
    <mwe-infobox
      [titleKey]="item.aktionsLabel"
      type="success"
      [attr.data-test]="getTestId('active-campaign')"
      iconClassOverwrite="fas fa-gift"
    >
      <p class="mb-0" [translate]="item.aktionsDetails"></p>
    </mwe-infobox>
  }
</div>
<div [attr.data-test]="getTestId('legal-text')" class="text-center text--legal mt-3">
  {{ selectedTariff().priceIndication.rechtsTextGueltig }}
</div>

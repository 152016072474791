<div>
  @if (error() && errorCode() === '0000') {
    <mwe-alert [messageKey]="alertMsgKey" [titleKey]="alertTitleKey" extended="false" showIcon="false" type="danger" />
  }
  <form #formComponent="ngForm" [formGroup]="form" class="form-horizontal col-12">
    <div class="mb-3">
      <div class="form-floating">
        <input
          (blur)="validate()"
          (input)="onInputIBAN($event.target.value)"
          [attr.aria-describedby]="ibanControlName + '-desc'"
          [attr.aria-invalid]="inputAriaValid === false || null"
          [attr.id]="ibanControlName"
          [attr.maxlength]="32"
          [attr.minlength]="6"
          [attr.name]="ibanControlName"
          [attr.type]="'text'"
          [formControlName]="ibanControlName"
          [ngClass]="{
            'is-invalid': !isValidating && (error() || (ibanControl.errors && !this.ibanControl.pristine)),
            'is-valid': !isValidating && inputAriaValid === true,
          }"
          class="form-control"
          data-test="iban-form-iban-input"
          placeholder="{{ config.ibanPlaceholderKey | translate }}"
          required
        />
        <label [attr.for]="ibanControlName" [translate]="config.ibanLabelKey" class="form-label"></label>
        @if (isValidating) {
          <div class="form-floating-icon">
            <div class="loading-spinner" style="--bs-loading-spinner-color: var(--bs-gray-light)"></div>
          </div>
        }
      </div>
      @if ((error() && errorCode() !== '0000') || (ibanControl.errors && !this.ibanControl.pristine)) {
        <div [attr.id]="ibanControlName + '-desc'" class="font-size-11 text-danger mt-1">
          <i aria-hidden="true" class="fa fa-exclamation-circle mr-1"></i>
          <span [innerHtml]="ibanErrorKey() | translate"></span>
        </div>
      }
    </div>
  </form>
</div>

@if (hideIbanLegalInfo) {
  <mwe-expander textKey="invoiceInformation.invoiceHow.info"></mwe-expander>
}

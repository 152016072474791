import { patchState, signalStore, SignalStoreFeature, withState } from '@ngrx/signals';
import { SignalPropertyDefinition, SYM_SIGNAL_STATE_PROPERTIES } from './signal-state-property.decorator';
import 'reflect-metadata';
import { ɵisInjectable, ɵgetInjectableDef, Signal } from '@angular/core';

export const SYM_SERVICE_STATE_SIGNAL_STORE = Symbol('ServiceStateSignalStore');

export type WithSignalSuffix<T, K extends keyof T = keyof T> = T & {
  // For all properties that are not functions, add a property with a Signal suffix
  [P in K as T[P] extends Function ? never : `${Extract<P, string>}Signal`]: Signal<T[P]>;
};

type Constructor<T> = new (...args: any[]) => T;
// This is a class decorator that should find all properties with SYM_SYM_SIGNAL_STATE_PROPERTIES. It then creates a SignalStore with the initial state based on the properties found.
// Need to disable no-explicit-any because of the TS Mixin requirement for the constructor to keep the signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SignalStateService<T extends Constructor<any>>(...features: SignalStoreFeature[]): (cls: T) => WithSignalSuffix<T> {
  return function (cls: T): WithSignalSuffix<T> {
    const signalProperties = cls.prototype.constructor[SYM_SIGNAL_STATE_PROPERTIES] as SignalPropertyDefinition[];
    if (!signalProperties) {
      // If no explicit properties are defined we expect all properties should be converted to be signals
      cls.prototype.constructor[SYM_SIGNAL_STATE_PROPERTIES] = Object.getOwnPropertyNames(cls.prototype)
        .map(propertyKey => propertyKey)
        .filter(propertyKey => {
          // non function properties
          const descriptor = Object.getOwnPropertyDescriptor(cls.prototype, propertyKey);
          return descriptor && typeof descriptor.value !== 'function';
        })
        .map(propertyKey => ({ propertyKey }));
    }
    // At runtime we need to check if the class is injectable
    if (!ɵisInjectable(cls)) {
      // If it is not we are in Test mode so we don't have the InjectableDef. Instead just apply the mixin
      return class extends cls {
        constructor(...args: any[]) {
          super(...args);
          Object.defineProperty(this, SYM_SERVICE_STATE_SIGNAL_STORE, {
            value: bootSignalStore(this as any, features),
            writable: false,
            enumerable: false,
          });
        }
      } as WithSignalSuffix<T>;
    }
    // We are in the normal runtime so we can get the InjectableDef and override the factory
    const injectableDef = ɵgetInjectableDef(cls);
    if (!injectableDef) {
      throw new Error(`InjectableDef not found for ${cls.name}`);
    }

    const originalFactory = injectableDef.factory;
    // Replace the factory at runtime
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    injectableDef.factory = (...args: any[]) => {
      const instance = originalFactory(...args) as any; // create the instance with the original factory
      // Here the magic happens. We add the signal store to the instance
      // bootSignalStore will build initialState and replace the properties with getters and setters
      Object.defineProperty(instance, SYM_SERVICE_STATE_SIGNAL_STORE, {
        value: bootSignalStore(instance, features),
        writable: false,
        enumerable: false,
      });
      return instance;
    };
    return cls as unknown as WithSignalSuffix<T>;
  };
}

// get the property descriptor by traversing the prototype chain (for inherited properties)
function getPropertyDescriptor<T>(c: T, propertyKey: string | symbol) {
  let descriptor;
  do {
    descriptor = Object.getOwnPropertyDescriptor(c, propertyKey);
    c = Object.getPrototypeOf(c);
  } while (c && !descriptor);
  return descriptor;
}

function bootSignalStore<T extends { new (...args: any[]): object }>(cls: T, features: SignalStoreFeature[]) {
  const signalProperties = (cls.constructor[SYM_SIGNAL_STATE_PROPERTIES] as SignalPropertyDefinition[]) ?? [];
  const initialState: object = {};
  signalProperties.forEach(({ propertyKey }) => {
    // get initial value from the property if we can
    const descriptor = getPropertyDescriptor(cls, propertyKey);
    if (!descriptor) {
      console.warn(`Property ${propertyKey.toString()} has no descriptor`);
      return;
    }
    let propertyValue = descriptor.value;
    // do we have a getter?
    if ('get' in descriptor && typeof descriptor.get === 'function') {
      // if we have a getter, we should use it to get the initial value.
      // This could be a @SessionStorage() or @LocalStorage() property so it would access the storage
      propertyValue = descriptor.get();
    }
    if (typeof propertyValue === 'function') {
      // if the property is a function, we assume it is a class member function. We will not touch it.
      return;
    }
    // set the initial value in the state for the signal store
    initialState[propertyKey] = propertyValue;
    // replace the property with a getter and setter that uses the signal store
    // keep in mind that we might have ngx-webstorage properties that define @SessionStorage() or @LocalStorage()
    // They need to work with the signal store as well
    const originalSetter = descriptor.set;
    descriptor.set = function (value: unknown) {
      if (originalSetter) {
        // pass the value to the original setter i.e. ngx-webstorage
        originalSetter.call(this, value);
      }
      // update the signal store with the new value as well so it is in sync
      patchState(this[SYM_SERVICE_STATE_SIGNAL_STORE], { [propertyKey]: value });
    };
    descriptor.get = function () {
      // get the value from the signal store.
      // This will also register the signal as dependendy in computed properties
      return this[SYM_SERVICE_STATE_SIGNAL_STORE][propertyKey]();
    };
    Object.defineProperty(cls, propertyKey, descriptor);
    // append the signal version of the property to the class
    Object.defineProperty(cls, `${String(propertyKey).toString()}Signal`, {
      get: function () {
        // return the signal reference instead of calling it.
        return this[SYM_SERVICE_STATE_SIGNAL_STORE][propertyKey];
      },
      enumerable: false,
    });
  }, {});
  // create the signal store with the initial state
  // we set protectedState to false so we can access the state directly in the getters.
  // otherwise we would need to define a method for each property mutation
  const store = signalStore.call(signalStore, { protectedState: false }, withState(initialState), ...features);
  return new store();
}

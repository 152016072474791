import { AddressGroup, createAddress, AddressProducts } from '@mwe/models';

export function checkAddressProductsForAddressGroupUpdates(addressProducts: AddressProducts[], updates: AddressGroup[]): void {
  const addresses = addressProducts.map(addressProduct => addressProduct.address);

  updates.forEach((update: AddressGroup) => {
    const addressProduct = addresses.find(elem => elem.addressGroup.addressCode === update.addressCode);

    if (addressProduct) {
      addressProduct.addressGroup = update;
      addressProduct.addressGroup.matchedGroup = true;
    }
  });
}

// todo remove this temp solution once every Address/Product-Class is refactored to interface
export function copyAddressGroupMapImmutable(addressProductsMap: Map<string, AddressProducts>): Map<string, AddressProducts> {
  const newAddressProductsMap = new Map<string, AddressProducts>();

  Array.from(addressProductsMap.values()).forEach(oldAp => {
    const addressProduct: AddressProducts = {
      address: createAddress(oldAp.address),
      products: oldAp.products.map(oldP => {
        return { ...oldP };
      }),
      areSomeProductsWhiteListed: oldAp.areSomeProductsWhiteListed,
    };
    newAddressProductsMap.set(addressProduct.address?.hashCode, addressProduct);
  });

  return newAddressProductsMap;
}

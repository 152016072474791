import { Component, Input, OnInit } from '@angular/core';
import { Address } from '@mwe/models';
import { getAddressPostCodeAndStreetFormatted } from '@mwe/utils';

@Component({
  selector: 'mwe-card-with-product-list',
  templateUrl: './card-with-product-list.component.html',
})
export class CardWithProductListComponent implements OnInit {
  @Input() categories: string[];
  @Input() address: Address;
  @Input() description: string;
  @Input() noBox = false;
  @Input() oneRow = true;

  postCodeAndStreet: string;

  constructor() {}

  ngOnInit(): void {
    this.postCodeAndStreet = getAddressPostCodeAndStreetFormatted(this.address);
  }
}

{
  "name": "ikp-frontends",
  "version": "0.1.0-SNAPSHOT",
  "engines": {
    "npm": ">=10.0.0",
    "node": ">=20.15.0"
  },
  "projects": {
    "ikp": "ikp",
    "ikp-berater": "ikp-berater"
  },
  "browser": {
    "http": false,
    "https": false,
    "url": false,
    "path": false,
    "fs": false,
    "util": false,
    "node:os": false,
    "node:util": false
  },
  "scripts": {
    "ng": "nx",
    "reset": "nx reset",
    "start": "nx serve",
    "start:expose-host": "nx serve --host 0.0.0.0",
    "ikp": "nx run ikp:serve:local",
    "berater": "nx run ikp-berater:serve:local",
    "messages": "nx run messages-control:serve:devtest",
    "devInt": "nx run meinewe:serve:devint",
    "build": "nx build",
    "test": "nx run-many --target=test --all",
    "analyze": "ng build --prod --stats-json && webpack-bundle-analyzer dist/ikp/stats.json",
    "full-test": "npm run test && nx run-many --target=e2e --all",
    "prepare": "husky",
    "newrelic:local": "SET NODE_ENV=development && node ",
    "newrelic:local:linux": "export NODE_ENV=development && node ",
    "storybook": "nx storybook storybook",
    "parse-version": "node parse-version.js"
  },
  "browserslist": [
    "last 2 Chrome versions",
    "last 2 Firefox versions",
    "last 2 Edge versions",
    "Safari >= 15",
    "last 2 iOS versions",
    "last 2 Android versions",
    "last 2 ChromeAndroid versions",
    "last 2 FirefoxAndroid versions"
  ],
  "private": true,
  "dependencies": {
    "@aarsteinmedia/dotlottie-player-light": "^4.0.9",
    "@angular/animations": "18.2.13",
    "@angular/cdk": "18.2.14",
    "@angular/common": "18.2.13",
    "@angular/compiler": "18.2.13",
    "@angular/core": "18.2.13",
    "@angular/forms": "18.2.13",
    "@angular/localize": "18.2.13",
    "@angular/platform-browser": "18.2.13",
    "@angular/platform-browser-dynamic": "18.2.13",
    "@angular/router": "18.2.13",
    "@azure/msal-angular": "^3.1.0",
    "@azure/msal-browser": "^3.27.0",
    "@fortawesome/angular-fontawesome": "0.15.0",
    "@editorjs/editorjs": "^2.30.7",
    "@editorjs/list": "^2.0.2",
    "@editorjs/paragraph": "^2.11.6",
    "@fortawesome/fontawesome-free": "6.7.1",
    "@fortawesome/fontawesome-pro": "6.7.1",
    "@fortawesome/fontawesome-svg-core": "6.7.1",
    "@fortawesome/free-solid-svg-icons": "6.7.1",
    "@ng-bootstrap/ng-bootstrap": "17.0.1",
    "@ngrx/signals": "18.1.1",
    "@ngx-translate/core": "16.0.3",
    "@ngx-translate/http-loader": "16.0.0",
    "@nx/angular": "^20.1.4",
    "@popperjs/core": "^2.11.8",
    "@storybook/addon-interactions": "^8.4.6",
    "@wienenergiegithub/modules-next": "4.41.3",
    "@wienenergiegithub/we-t1-bootstrap-theme": "2.5.2-prod3",
    "angular-oauth2-oidc": "^17.0.2",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.7",
    "dayjs": "^1.11.13",
    "editorjs-drag-drop": "^1.1.16",
    "editorjs-header-with-anchor": "^2.6.0",
    "editorjs-inline-image": "^2.2.2",
    "editorjs-link-button": "^1.1.0",
    "friendly-challenge": "^0.9.16",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^24.0.5",
    "merge-jsons-webpack-plugin": "^2.0.1",
    "ng2-charts": "^7.0.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-webstorage": "18.0.0",
    "qrious": "^4.0.2",
    "rxjs": "7.8.1",
    "storybook": "^8.4.6",
    "swiper": "^11.1.15",
    "tslib": "^2.7.0",
    "uuid": "^11.0.3",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/core": "18.2.12",
    "@angular-devkit/schematics": "18.2.12",
    "@angular-eslint/builder": "^18.4.3",
    "@angular-eslint/eslint-plugin": "18.4.3",
    "@angular-eslint/eslint-plugin-template": "18.4.3",
    "@angular-eslint/schematics": "18.4.3",
    "@angular-eslint/template-parser": "18.4.3",
    "@angular/cli": "~18.2.12",
    "@angular/compiler-cli": "18.2.13",
    "@angular/language-service": "18.2.13",
    "@chromatic-com/storybook": "^3.2.2",
    "@etchteam/storybook-addon-status": "^5.0.0",
    "@nx/angular": "20.1.4",
    "@nx/cypress": "20.1.4",
    "@nx/eslint": "20.1.4",
    "@nx/eslint-plugin": "20.1.4",
    "@nx/jest": "20.1.4",
    "@nx/js": "20.1.4",
    "@nx/plugin": "20.1.4",
    "@nx/storybook": "20.1.4",
    "@nx/web": "20.1.4",
    "@nx/workspace": "20.1.4",
    "@schematics/angular": "18.2.12",
    "@storybook/addon-a11y": "^8.4.6",
    "@storybook/addon-docs": "^8.4.6",
    "@storybook/addon-essentials": "^8.4.6",
    "@storybook/addon-mdx-gfm": "^8.4.6",
    "@storybook/angular": "^8.4.6",
    "@storybook/core-server": "^8.4.6",
    "@storybook/test-runner": "^0.20.1",
    "@swc-node/register": "1.10.9",
    "@swc/core": "1.10.0",
    "@swc/helpers": "^0.5.15",
    "@types/jest": "29.5.14",
    "@types/node": "22.10.1",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "7.18.0",
    "@typescript-eslint/parser": "7.18.0",
    "@typescript-eslint/utils": "7.18.0",
    "cypress": "13.16.0",
    "default-composer": "^0.6.0",
    "esbuild-plugin-json-merge": "^0.1.0",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-import": "2.x",
    "eslint-plugin-json": "^3.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-sonarjs": "^0.23.0",
    "eslint-plugin-storybook": "^0.8.0",
    "eslint-plugin-unused-imports": "^3.2.0",
    "husky": "^9.1.7",
    "jasmine-core": "5.5.0",
    "jasmine-spec-reporter": "7.0.0",
    "jest": "29.7.0",
    "jest-canvas-mock": "^2.5.2",
    "jest-environment-jsdom": "29.7.0",
    "jest-fail-on-console": "^3.3.1",
    "jest-jasmine2": "29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "14.4.2",
    "jest-sonar-reporter": "^2.0.0",
    "jsonc-eslint-parser": "^2.4.0",
    "lint-staged": "^15.2.10",
    "merge-jsons-webpack-plugin": "^2.0.1",
    "mockdate": "^3.0.5",
    "ng-mocks": "^14.13.1",
    "nx": "20.1.4",
    "object-code": "^1.3.3",
    "prettier": "^3.4.2",
    "selenium-webdriver": "^4.27.0",
    "sonar-scanner": "^3.1.0",
    "storybook-addon-designs": "^6.3.1",
    "storybook-addon-mock": "^5.0.0",
    "ts-jest": "29.2.5",
    "ts-node": "10.9.2",
    "typescript": "5.5.4"
  },
  "jestSonar": {
    "reportPath": "target/test-results/jest",
    "reportFile": "TESTS-results-sonar.xml"
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@mwe/models';
import { getAddressStreetFormatted } from '@mwe/utils';

@Pipe({
  name: 'getAddressStreet',
})
export class GetAddressStreetPipe implements PipeTransform {
  transform(address: Address): string {
    return getAddressStreetFormatted(address);
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ClearingAccount,
  GetAllUnpaidInvoicesResponse,
  GetRatenPlanResponse,
  AddressProducts,
  Stundung,
  UnpaidInvoices,
  VerrechnungskontoResponse,
} from '@mwe/models';
import { catchError, map, tap } from 'rxjs/operators';
import { ENVIRONMENT_SERVICE_TOKEN, FeatureToggleEnum } from '@mwe/constants';
import { lastValueFrom, of } from 'rxjs';
import { containsEmobilityOrInternet, getAllProductsFromAddressProducts } from '@mwe/utils';
import { hasDuePayments, filterDuePayments, getClearingAccountsFrom, getCategoriesForClearingAccount } from '@mwe/utils';
import { EnvironmentService } from '../../environment/environment.service';
import { ProfileService } from '../../profile/profile.service';

@Injectable({ providedIn: 'root' })
export class ClearingAccountService {
  private resourceUrl: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
    private profileService: ProfileService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/verrechnungskonten/';
  }

  getUnpaidInvoices(accountId: string, businessPartnerId: string, systemId: string): Promise<UnpaidInvoices> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.DUE_PAYMENT_NOTIFICATIONS_ENABLED)) {
      return Promise.resolve(null);
    }

    const url = this.resourceUrl + 'offeneZahlungen';
    const params = new HttpParams()
      .set('verrechnungskontoId', accountId)
      .set('geschaeftsPartnerId', businessPartnerId)
      .set('systemId', systemId);

    return lastValueFrom(
      this.httpClient.get<UnpaidInvoices>(url, { params }).pipe(
        tap(response => {
          if (!hasDuePayments(response)) {
            response.offeneZahlungen = [];
          } else {
            response.offeneZahlungen = filterDuePayments(response.offeneZahlungen);
          }
        }),
        // ignore error and display clearingAccount if there are no unpaid invoices
        catchError(() => of(null)),
      ),
    );
  }

  getAllUnpaidInvoices(addressProducts: AddressProducts[]): Promise<ClearingAccount[]> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.DUE_PAYMENT_NOTIFICATIONS_ENABLED)) {
      return Promise.resolve([]);
    }

    const body: GetAllUnpaidInvoicesResponse = {
      clearingAccounts: getClearingAccountsFrom(addressProducts),
    };
    return lastValueFrom(
      this.httpClient.post<GetAllUnpaidInvoicesResponse>(this.resourceUrl + 'offeneZahlungen', body).pipe(
        map(response => response.clearingAccounts),
        tap(accounts => {
          accounts.forEach(item => {
            if (!item?.unpaidInvoices) {
              return;
            }

            if (!hasDuePayments(item.unpaidInvoices)) {
              item.unpaidInvoices.offeneZahlungen = [];
              return;
            }

            item.unpaidInvoices.offeneZahlungen = filterDuePayments(item.unpaidInvoices.offeneZahlungen);

            // emobility + tk are not supported at the moment
            // can be removed once categories are supported
            const allProducts = getAllProductsFromAddressProducts(addressProducts);
            const categories = getCategoriesForClearingAccount(item, allProducts);
            if (containsEmobilityOrInternet(categories)) {
              item.unpaidInvoices = null;
            }
          });
        }),
        // ignore error and display clearingAccount if there are no unpaid invoices
        catchError(() => of([])),
      ),
    );
  }

  getPaymentExtension(accountId: string, businessPartnerId: string, systemId: string): Promise<Stundung> {
    const url = this.resourceUrl + 'paymentextension';
    const params = new HttpParams()
      .set('verrechnungskontoId', accountId)
      .set('geschaeftsPartnerId', businessPartnerId)
      .set('systemId', systemId);
    return lastValueFrom(this.httpClient.get<Stundung>(url, { params }));
  }

  getPaymentPlan(accountId: string, businessPartnerId: string, systemId: string): Promise<GetRatenPlanResponse> {
    const url = this.resourceUrl + 'paymentplan';
    const params = new HttpParams()
      .set('verrechnungskontoId', accountId)
      .set('geschaeftsPartnerId', businessPartnerId)
      .set('systemId', systemId);
    return lastValueFrom(this.httpClient.get<GetRatenPlanResponse>(url, { params }));
  }

  getPaymentPlans(accountId: string, businessPartnerId: string, systemId: string): Promise<GetRatenPlanResponse> {
    const url = this.resourceUrl + 'paymentplans';
    const params = new HttpParams()
      .set('verrechnungskontoId', accountId)
      .set('geschaeftsPartnerId', businessPartnerId)
      .set('systemId', systemId);
    return lastValueFrom(this.httpClient.get<GetRatenPlanResponse>(url, { params }));
  }

  getAccountInfo(accountId: string, systemId: string): Promise<VerrechnungskontoResponse> {
    const url = this.resourceUrl + 'accountInfo';
    const params = new HttpParams().set('verrechnungskontoId', accountId).set('systemId', systemId);
    return lastValueFrom(this.httpClient.get<VerrechnungskontoResponse>(url, { params }));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@mwe/models';
import { getAddressStreetAndPostCodeFormatted } from '@mwe/utils';

@Pipe({
  name: 'getAddressStreetAndPostCode',
})
export class GetAddressStreetAndPostCodePipe implements PipeTransform {
  transform(address: Address) {
    return getAddressStreetAndPostCodeFormatted(address);
  }
}

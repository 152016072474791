export enum FeatureToggleEnum {
  PROFILE_DSGVO_SETTINGS_ENABLED = 'profile-dsgvo-settings-enabled',
  RENAME_ADDRESS_GROUP_ENABLED = 'rename-address-group-enabled',
  RELOCATION_ENABLED = 'relocation-enabled',
  COST_OVERVIEW_ENABLED = 'cost-overview-enabled',
  DSGVO_CHECK_ENABLED = 'dsgvo-check-enabled',
  COOKIE_POPUP_ENABLED = 'cookie-popup-enabled',
  NEW_CLIENT_ENABLED = 'new-client-enabled',
  GOODIES_ENABLED = 'goodies-enabled',
  GOODIES_MGM_ENABLED = 'goodies-mgm-enabled',
  GOODIES_PROVISION_ENABLED = 'goodies-provision-enabled',
  TARIFF_CHANGE_ENABLED = 'tariff-change-enabled',
  SECURE_OFFER_ENABLED = 'tariff-change-secure-offer-enabled',
  TARIFF_CHANGE_CURRENT_TARIFFCARD_ENABLED = 'tariff-change-current-tariffcard-enabled',
  BANK_ACCOUNT_CHANGE_ENABLED = 'bank-account-change-enabled',
  PDF_INVOICE_ENABLED = 'pdf-invoice-enabled',
  BANNER_ENABLED = 'banner-enabled',
  CUSTOMER_DATA_CHANGE_ENABLED = 'customer-data-change-enabled',
  CONSUMPTION_ENABLED = 'consumption-history-enabled',
  MOVE_OUT_ENABLED = 'move-out-enabled',
  METER_READING_ENABLED = 'meter-reading-enabled',
  CAMPAIGN_ENABLED = 'campaign-enabled',
  BSKW_DASHBOARD_ENABLED = 'bskw-dashboard-enabled',
  VOUCHER_ENABLED = 'energy-voucher-enabled',
  INTERACTIVE_INVOICE_ENABLED = 'invoice-enabled',
  MAINTENANCE_ENABLED = 'maintenance-enabled',
  INTERACTIVE_INVOICE_DETAILS_ENABLED = 'invoice-details-enabled',
  INTERACTIVE_INVOICE_DETAILS_SG_ENABLED = 'invoice-details-sg-enabled',
  INTERACTIVE_INVOICE_DETAILS_WAERME_ENABLED = 'invoice-details-waerme-enabled',
  INVOICE_PARTIALPAYMENT_OVERVIEW_ENABLED = 'invoice-partialpayment-overview-enabled',
  INVOICE_PARTIALPAYMENT_CHANGE_SG_ENABLED = 'invoice-partialpayment-change-sg-enabled',
  INVOICE_PARTIALPAYMENT_CHANGE_WAERME_ENABLED = 'invoice-partialpayment-change-waerme-enabled',
  CHANGE_INVOICE_ADDRESS_ENABLED = 'change-invoice-address-enabled',
  ASSOCIATION_SUGGESTIONS_ENABLED = 'association-suggestions-enabled',
  INVITATION_SUGGESTIONS_ENABLED = 'invitation-suggestions-enabled',
  INVOICE_ALERT_ENABLED = 'invoice-alert-enabled',
  CHARGING_CARD_ORDER_ENABLED = 'charging-card-order-enabled',
  FIND_PAYMENT_SOLUTION_ENABLED = 'find-payment-solution-enabled',
  DUE_PAYMENT_NOTIFICATIONS_ENABLED = 'due-payment-notifications-enabled',
  FIND_PAYMENT_SOLUTION_CALLBACK_ENABLED = 'find-payment-solution-callback-enabled',
  FIND_PAYMENT_SOLUTION_LIVECHAT_ENABLED = 'find-payment-solution-livechat-enabled',
  NEW_INTERNET_CLIENT_ENABLED = 'new-internet-client-enabled',
  INVOICE_DEMO_ENABLED = 'invoice-demo-enabled',
  CHECK_PDF_INVOICE_ON_LOGIN_ENABLED = 'check-pdf-invoice-on-login-enabled',
  CHECK_SEPA_ON_LOGIN_ENABLED = 'check-sepa-on-login-enabled',
  INVOICE_EVIDENCE_EMAILV2_ENABLED = 'invoice-evidence-emailv2-enabled',
  SG_RATENPLAN_ENABLED = 'sg-ratenplan-enabled',
  CHANGE_PARTIAL_PAYMENTS_PREVENT_REDUCTION_ENABLED = 'change-partialpayments-prevent-reduction-enabled',
  OFFER_VKI_ENABLED = 'offer-vki-enabled',
  OFFER_VKI_CAPTCHA_ENABLED = 'offer-vki-captcha-enabled',
  ACTIVE_PAYMENT_PLAN_DATA_ENABLED = 'active-payment-plan-data-enabled',
  MAINTENANCE_ADMINTOOL_ENABLED = 'maintenance-admintool-enabled',
  SEND_EMAIL_NEW_ASSOCIATION_ENABLED = 'send-email-new-association-enabled',
  PROFILE_NEWSLETTER_ENABLED = 'profile-newsletter-settings-enabled',
  MAINTENANCE_MAC_ENABLED = 'maintenance-mac-enabled',
  MAC_PAYMENT_METHOD_MAINTENANCE_ENABLED = 'mac-menu-bezahlart-ksadmin-enabled',
  MAC_INVOICE_DISPATCH_MAINTENANCE_ENABLED = 'mac-menu-rechnungsversand-ksadmin-enabled',
  CLEARING_ACCOUNT_INFORMATION_REQUEST_ENABLED = 'kontoinformation-anfordern-enabled',
  RELOCATION_OSS_ENABLED = 'forwarding-address-enabled',
  OFFER_CONFIRMATION_ENABLED = 'offer-confirmation-enabled',
  MAC_WRITING_SERVICES_MAINTENANCE_ENABLED = 'mac-schreibende-services-ksadmin-enabled',
  MAC_WRITING_SERVICES_MAINTENANCE_SG_ENABLED = 'mac-schreibende-services-sg-ksadmin-enabled',
  MAC_WRITING_SERVICES_MAINTENANCE_WAERME_ENABLED = 'mac-schreibende-services-waerme-ksadmin-enabled',
  MAC_WRITING_SERVICES_MAINTENANCE_TANKE_ENABLED = 'mac-schreibende-services-tanke-ksadmin-enabled',
  MAC_WRITING_SERVICES_MAINTENANCE_SUPERSCHNELL_ENABLED = 'mac-schreibende-services-superschnell-ksadmin-enabled',
  MAC_PARTIAL_AMOUNTS_MENU_MAINTENANCE_ENABLED = 'mac-menu-teilbetraege-ksadmin-enabled',
  MAC_PAYMENT_PLAN_SG_MENU_MAINTENANCE_ENABLED = 'mac-menu-ratenplan-sg-ksadmin-enabled',
  MAC_PAYMENT_PLAN_WAERME_MENU_MAINTENANCE_ENABLED = 'mac-menu-ratenplan-waerme-ksadmin-enabled',
  MAC_CHANGE_PARTIAL_AMOUNTS_MAINTENANCE_SG_ENABLED = 'mac-teilbetraege-aendern-sg-ksadmin-enabled',
  MAC_CHANGE_PARTIAL_AMOUNTS_MAINTENANCE_WAERME_ENABLED = 'mac-teilbetraege-aendern-waerme-ksadmin-enabled',
  MAC_CREATE_CONTACT_MAINTENANCE_SG_TANKE_SUPERSCHNELL_ENABLED = 'mac-kontakte-schreiben-sg-tanke-superschnell-ksadmin-enabled',
  MAC_CREATE_STUNDUNG_MAINTENANCE_SG_ENABLED = 'mac-stundung-anlegen-sg-ksadmin-enabled',
  MAC_CREATE_STUNDUNG_MAINTENANCE_WAERME_ENABLED = 'mac-stundung-anlegen-waerme-ksadmin-enabled',
  MAC_CREATE_RATENPLAN_MAINTENANCE_SG_ENABLED = 'mac-ratenplan-anlegen-sg-ksadmin-enabled',
  MAC_CREATE_RATENPLAN_MAINTENANCE_WAERME_ENABLED = 'mac-ratenplan-anlegen-waerme-ksadmin-enabled',
  MAC_BECOME_CUSTOMER_MAINTENANCE_SG_ENABLED = 'mac-kunde-werden-sg-ksadmin-enabled',
  MESSAGES_ENABLED = 'menu-meine-nachrichten-enabled',
  MAC_MWE_ACCOUNT_MAINTENANCE_ENABLED = 'mac-mwe-account-ksadmin-enabled',
  MAC_EDIT_CUSTOMER_MAINTENANCE_SG_TANKE_SUPERSCHNELL_ENABLED = 'mac-geschaeftspartner-bearbeiten-sg-tanke-superschnell-ksadmin-enabled',
  MAC_EDIT_CUSTOMER_MAINTENANCE_WAERME_ENABLED = 'mac-kundendaten-bearbeiten-waerme-ksadmin-enabled',
  MAC_EDIT_CUSTOMER_WAERME_MENU_MAINTENANCE_ENABLED = 'mac-menu-kundendaten-waerme-ksadmin-enabled',
  MAC_EDIT_CONTACT_HISTORY_MAINTENANCE_SG_TANKE_SUPERSCHNELL_ENABLED = 'mac-kontakthistorie-sg-tanke-superschnell-ksadmin-enabled',
  MAC_EDIT_CONTACT_HISTORY_MAINTENANCE_WAERME_ENABLED = 'mac-kontakthistorie-waerme-ksadmin-enabled',
  MAC_CHANGE_TARIFF_MAINTENANCE_SG_ENABLED = 'mac-tarif-wechseln-sg-ksadmin-enabled',
  MAC_CREATE_CUSTOMER_MAINTENANCE_SG_TANKE_SUPERSCHNELL_ENABLED = 'mac-geschaeftspartner-anlegen-sg-tanke-superschnell-ksadmin-enabled',
  MAC_ACCOUNT_BALANCE_SG_MENU_MAINTENANCE_ENABLED = 'mac-menu-kontostand-sg-ksadmin-enabled',
  MAC_ACCOUNT_BALANCE_TANKE_MENU_MAINTENANCE_ENABLED = 'mac-menu-kontostand-tanke-ksadmin-enabled',
  MAC_ACCOUNT_BALANCE_SUPERSCHNELL_MENU_MAINTENANCE_ENABLED = 'mac-menu-kontostand-superschnell-ksadmin-enabled',
}

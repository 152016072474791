import {
  Address,
  IAdressDetails,
  IBusinessData,
  ICustomerNumberInfo,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
  IGeschaeftspartnerBeziehung,
  IOrganisation,
  IPersonalData,
} from '@mwe/models';
import { TranslateService } from '@ngx-translate/core';
import { getFormattedContactAddress } from './mwe-util';
import { getCategorySequenceDiff } from '../products/product-category.utils';

export const sortCustomerNumberInfos = (unsortedList: ICustomerNumberInfo[]): ICustomerNumberInfo[] => {
  return unsortedList.sort((a, b) => {
    if (a.lastName !== b.lastName) {
      return a.lastName.localeCompare(b.lastName);
    }

    if (a.firstName !== b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }

    const aCategory = a.rawProductCategories[0];
    const bCategory = b.rawProductCategories[0];

    if (aCategory !== bCategory) {
      return getCategorySequenceDiff(aCategory, bCategory);
    }

    return 0;
  });
};

export const getAdressDetailsFrom = (address: Address): IAdressDetails => {
  if (!address) {
    return null;
  }

  return {
    hausnummer: address.streetNumber,
    ort: address.city,
    plz: address.postcode,
    strasse: address.street,
    stiege: address.block,
    tuernummer: address.doorNumber,
  };
};

export const getPersonalDataFromPartnerRelation = (partner: IGeschaeftspartnerBeziehung, fallbackEmail?: string): IPersonalData => {
  if (!partner) {
    return null;
  }

  const person = partner.physischePerson;

  return {
    salutation: person.anrede,
    title: { text: person.titelVorgestellt || person.titelNachgestellt, value: '' },
    firstName: person.vorname,
    lastName: person.nachname,
    birthday: person.geburtsdatum,
    telefonNumber: partner.telefon?.nummer,
    telefonCountryCode: partner?.telefon?.land,
    email: partner?.email?.eMail || fallbackEmail,
  };
};

export const getDifferentInvoiceRecipientInfoMessage = (
  differentInvoiceRecipient: IDifferentInvoiceRecipient,
  existingERechnungInfo: IERechnungsInfo,
  selectedERechnungInfo: IERechnungsInfo,
  address: Address,
  translateService: TranslateService,
  label: string,
): string => {
  const isNewERechnungsEmpfeanger = !existingERechnungInfo?.isERechnungsEmpfaenger && selectedERechnungInfo?.isERechnungsEmpfaenger;
  if (!differentInvoiceRecipient || !isNewERechnungsEmpfeanger) {
    return '';
  }

  const contact = getFormattedContactAddress(differentInvoiceRecipient.physischePerson, address, differentInvoiceRecipient.organisation);
  return translateService.instant(label, { contact });
};

export const isEmailEmpty = (email: { eMail: string }): boolean => {
  return !email?.eMail?.trim().length;
};

export const getOrganisationFromBusinessData = (data: IBusinessData): IOrganisation => {
  if (!data) {
    return null;
  }

  return {
    organisationsname: data.companyName,
    firmenbuchnummer: data.corporateRegisterNumber,
    umsatzsteuerId: data.uidNumber,
  };
};

export const getBusinessDataFromOrganisation = (data: IOrganisation): IBusinessData => {
  if (!data) {
    return null;
  }

  return {
    companyName: data.organisationsname,
    corporateRegisterNumber: data.firmenbuchnummer,
    uidNumber: data.umsatzsteuerId,
  };
};

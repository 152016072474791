import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Address, IFormModel, createAddress } from '@mwe/models';
import { FormComponent } from '../form/form.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mwe-manual-address-input',
  templateUrl: './manual-address-input.component.html',
})
export class ManualAddressInputComponent implements OnInit, OnDestroy {
  @Input() initialData: Address;
  @Input() postCodeMinLength = 1;
  @Input() postCodeMaxLength = 10;
  @Output() formChanged = new EventEmitter<any>();
  formModel: IFormModel;
  @ViewChild(FormComponent) formRef: FormComponent;

  private subscriptions = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.createFormModel();
    this.addFormChangeListener();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAddressFromForm(): Address {
    return createAddress({
      id: null,
      street: this.formRef.form.value['manual-address-street'],
      streetNumber: this.formRef.form.value['manual-address-streetNumber'],
      block: this.formRef.form.value['manual-address-blockNumber'],
      doorNumber: this.formRef.form.value['manual-address-doorNumber'],
      hint: null, // there is no adresshinweis
      postcode: this.getPostCode(),
      city: this.formRef.form.value['manual-address-city'],
      country: null,
    });
  }

  validate(): boolean {
    this.formRef.fireValidation();

    return this.formRef.form.valid;
  }

  getPostCode(): string {
    return this.formRef.form.value['manual-address-post-code'];
  }

  private createFormModel(): void {
    this.formModel = {
      updateOn: 'blur',
      inputs: [
        {
          name: 'manual-address-post-code',
          initialValue: this.initialData?.postcode,
          validators: [Validators.required, Validators.minLength(this.postCodeMinLength), Validators.maxLength(this.postCodeMaxLength)],
          labelKey: 'shared.international-address-input.form.postCode.title',
          validationErrorLabelKey: 'shared.international-address-input.form.postCode.error',
          validate: true,
          dataType: 'text',
          directiveType: 'integer',
          maxLength: this.postCodeMaxLength,
          updateOn: 'change',
        },
        {
          name: 'manual-address-city',
          initialValue: this.initialData?.city,
          validators: [Validators.required, Validators.maxLength(30)],
          labelKey: 'shared.international-address-input.form.city.title',
          validationErrorLabelKey: 'shared.international-address-input.form.city.error',
          validate: true,
          dataType: 'text',
          maxLength: 30,
        },
        {
          name: 'manual-address-street',
          initialValue: this.initialData?.street,
          validators: [Validators.required, Validators.maxLength(30)],
          labelKey: 'shared.international-address-input.form.street.title',
          validationErrorLabelKey: 'shared.international-address-input.form.street.error',
          validate: true,
          dataType: 'text',
          maxLength: 30,
        },
        {
          name: 'manual-address-streetNumber',
          initialValue: this.initialData?.streetNumber,
          validators: [Validators.required, Validators.maxLength(10)],
          labelKey: 'shared.international-address-input.form.streetNumber.title',
          validationErrorLabelKey: 'shared.international-address-input.form.streetNumber.error',
          validate: true,
          dataType: 'text',
          maxLength: 10,
        },
        {
          name: 'manual-address-blockNumber',
          initialValue: this.initialData?.block,
          validators: [Validators.maxLength(10)],
          labelKey: 'shared.international-address-input.form.blockNumber.title',
          placeholder: 'shared.international-address-input.form.blockNumber.placeholder',
          validationErrorLabelKey: 'shared.international-address-input.form.blockNumber.error',
          validate: true,
          dataType: 'text',
          maxLength: 10,
        },
        {
          name: 'manual-address-doorNumber',
          initialValue: this.initialData?.doorNumber,
          validators: [Validators.maxLength(10)],
          labelKey: 'shared.international-address-input.form.doorNumber.title',
          placeholder: 'shared.international-address-input.form.doorNumber.placeholder',
          validationErrorLabelKey: 'shared.international-address-input.form.doorNumber.error',
          validate: true,
          dataType: 'text',
          maxLength: 10,
        },
      ],
    };
  }

  private addFormChangeListener() {
    setTimeout(() => {
      const subscription = this.formRef.form.valueChanges.subscribe(_ => this.formChanged.emit());
      this.subscriptions.add(subscription);
    }, 100);
  }
}

import {
  Address,
  CommonMappingData,
  DataChangeType,
  IMultiProductCard,
  IProduktVerfuegbarkeit,
  IVerknuepfungen,
  IVerknuepfungenProdukte,
  Product,
  RecentOrder,
  RecentOrderDTO,
  RecentOrderType,
  Sparte,
  createAddress,
} from '@mwe/models';
import dayjs from 'dayjs';
import { parseAddressCode } from '../address/address.utils';
import { formatOrderDate } from '../dates/date.utils';
import { containsFernwaerme, isProductCategoryFernwaerme, sortProductsEnumCategory } from './product-category.utils';
import { isArrayWithMinOneItem } from '../common/mwe-util';

export const parseVerknuepfungen = (associations: IVerknuepfungen[]): Product[] => {
  if (!isArrayWithMinOneItem(associations)) {
    return [];
  }

  return associations.flatMap(el => parseVerknuepfung(el)).filter(i => !!i);
};

export const parseVerknuepfung = (association: IVerknuepfungen, ignoreVerknuepfungsStatus = false): Product[] => {
  if (association.produkte && (association.verknuepfungsStatus !== null || ignoreVerknuepfungsStatus)) {
    return getProductFromAssociation(association.produkte, association.geschaeftsPartnerId, association.systemId);
  }
  return [];
};

export const getProductFromAssociation = (products: IVerknuepfungenProdukte[], customerNumber: string, systemId: string): Product[] => {
  if (!isArrayWithMinOneItem(products)) {
    return [];
  }

  return products
    .map(p => {
      const customerNumberPerCategory = isProductCategoryFernwaerme(p.sparte) ? p.verrechnungskontoId.slice(2) : customerNumber;
      const tariffClasses = getTariffClassesFromAssociation(p);
      const tarifKey = p.additionalData?.produktDetails?.[0]?.ISUTarifKey;
      const anlageId = p.additionalData?.produktDetails?.[0]?.anlageId;

      return {
        id: null,
        customerNumber: customerNumberPerCategory,
        accountNumber: p.verrechnungskontoId,
        businessPartnerNumber: p.geschaeftsPartnerId,
        category: p.sparte,
        tariffClasses,
        address: parseAdresse(p.additionalData?.adressDetails),
        vertragsStatus: p.vertragsStatus,
        anlageId,
        tarifKey,
        systemId,
        associationDetails: p.additionalData?.produktDetails,
      } as Product;
    })
    .filter(p => !!p);
};

const getTariffClassesFromAssociation = (product: IVerknuepfungenProdukte): string[] => {
  if (!isArrayWithMinOneItem(product?.additionalData?.produktDetails)) {
    return [];
  }

  const tariffClasses = [];
  product.additionalData.produktDetails.forEach(detail => {
    if (detail?.tarifName) {
      if (detail.tarifName.toLowerCase() === 'heizung_warmwasser') {
        tariffClasses.push(parseTariff('heizung'));
        tariffClasses.push(parseTariff('warmwasser'));
      } else {
        tariffClasses.push(parseTariff(detail.tarifName));
      }
    }
  });

  return tariffClasses;
};

export const parseAdresse = (addressDetails: any): Address => {
  if (!addressDetails) {
    return null;
  }

  return createAddress({
    id: null,
    street: addressDetails.strasse,
    streetNumber: addressDetails.hausnummer,
    block: addressDetails.stiege,
    doorNumber: addressDetails.tuernummer,
    hint: null,
    postcode: addressDetails.plz,
    city: addressDetails.ort,
    country: addressDetails.land,
  });
};

export const parseTariff = (tariff: string) => {
  return tariff ? tariff.replace(/^Strom |^Gas |^Erdgas |^NachtStrom |^Stromrueck /, '') : '';
};

export const getProductsFromCommonMapping = (items: CommonMappingData[]): Product[] => {
  if (!isArrayWithMinOneItem(items)) {
    return [];
  }

  return items.flatMap(i => {
    return i.categories.map(c => {
      return {
        category: c,
        accountNumber: i.accountNumber,
        customerNumber: i.customerNumber,
      } as Product;
    });
  });
};

export const createMultiProductCardsFromProducts = (products: Product[]): IMultiProductCard[] => {
  let accountNumbers = products.map(product => product.accountNumber);
  accountNumbers = Array.from(new Set(accountNumbers));

  return accountNumbers.map(accountNumber => {
    const filteredProducts = products.filter(product => product.accountNumber === accountNumber);
    const productCategories = filteredProducts.map(product => product.category);
    sortProductsEnumCategory(productCategories);
    const customerNumber = filteredProducts[0].customerNumber;
    const isWaerme = containsFernwaerme(productCategories);
    const displayAccountNumber = isWaerme ? accountNumber.slice(2) : accountNumber;
    return {
      id: accountNumber,
      categories: productCategories,
      accountNumber,
      customerNumber,
      displayAccountNumber,
      displayAccountNumberLabel: isWaerme ? 'shared.customerNumberLabel' : 'shared.accountNumberLabel',
    };
  });
};

export const parseRecentOrdersDTO = (recentOrdersApi: RecentOrderDTO[]): RecentOrder[] => {
  return recentOrdersApi.map(item => {
    const type = item.type as RecentOrderType;
    const dataType = item.dataType as DataChangeType;
    let msgKey = '';

    switch (type) {
      case RecentOrderType.NewClient:
        msgKey = 'products.recentOrders.nc_workInProgress';
        break;
      case RecentOrderType.TariffChange:
        msgKey = getTariffChangeMessage(dataType);
        break;
      case RecentOrderType.InvoiceDataChange:
        msgKey = getInvoiceDataChangeMessage(dataType);
        break;
      case RecentOrderType.VoucherRedeem:
        msgKey = 'products.recentOrders.vr_workInProgress';
        break;
      case RecentOrderType.ChargingCardOrder:
        msgKey = 'products.recentOrders.cco_workInProgress';
        break;
      case RecentOrderType.PaymentAgreement:
        msgKey = getPaymentAgreementMessage(dataType);
        break;
      case RecentOrderType.PartialAmountChange:
        msgKey = 'products.recentOrders.pac_workInProgress';
        break;
      case RecentOrderType.CAInformationRequest:
        msgKey = 'products.recentOrders.cair_workInProgress';
        break;
    }

    const _address = item.address ? createAddress(item.address) : item.addressCode ? parseAddressCode(item.addressCode) : null;
    const customerData = item.customerData;

    // parse companyName for recent orders, to be compatible with CustomerNumberInfoTooltipCardComponent
    if (item.businessData) {
      customerData.geschaeftsPartner = {
        ...customerData?.geschaeftsPartner,
        organisation: {
          ...customerData?.geschaeftsPartner?.organisation,
          organisationsname: item.businessData.companyName,
        },
      };
    }

    return {
      id: item.id,
      orderedAt: formatOrderDate(item.orderedAt),
      type,
      dataType,
      address: _address,
      customerData,
      msgKey,
      addtlData: item.addtlData,
    };
  });
};

export const getTariffChangeMessage = (dataType: string): string => {
  if (dataType === DataChangeType.SecureOffer) {
    return 'products.recentOrders.tco_workInProgress';
  }

  return 'products.recentOrders.tc_workInProgress';
};

export const getPaymentAgreementMessage = (dataType: string): string => {
  if (dataType === 'PAYMENT_CALLBACK') {
    return 'products.recentOrders.cba_workInProgress';
  }

  return 'products.recentOrders.pa_workInProgress';
};

export const getInvoiceDataChangeMessage = (dataType: DataChangeType): string => {
  switch (dataType) {
    case DataChangeType.BankAccountChangeOnly:
      return 'products.recentOrders.idcb_workInProgress';
    case DataChangeType.PdfInvoiceChangeOnly:
      return 'products.recentOrders.idce_workInProgress';
    case DataChangeType.InvoiceAddressChangeOnly:
      return 'products.recentOrders.idcr_workInProgress';
    default:
      return 'products.recentOrders.idca_workInProgress';
  }
};

export const getInvoiceDataProductHeaderMessage = (dataType: DataChangeType): string => {
  switch (dataType) {
    case DataChangeType.BankAccountChangeOnly:
      return 'invoiceData.change.status.dtb_processTitle';
    case DataChangeType.PdfInvoiceChangeOnly:
      return 'invoiceData.change.status.dte_processTitle';
    case DataChangeType.InvoiceAddressChangeOnly:
      return 'invoiceData.change.status.dtr_processTitle';
    default:
      return 'invoiceData.change.status.dta_processTitle';
  }
};

export const sortRecentOrdersDTO = (recentOrdersApi: RecentOrderDTO[]): RecentOrderDTO[] => {
  return recentOrdersApi.sort((a, b) => (dayjs(a.orderedAt).isAfter(dayjs(b.orderedAt)) ? -1 : 1));
};

// helper method to get simple product for ui-stuff, do not use it for other purposes
export const getWaermeProductFromVerfuegbarkeit = (v: IProduktVerfuegbarkeit): Product => {
  if (!v) {
    return null;
  }
  const tariffClasses = v?.details?.map(d => d.lieferkomponente) || [];
  return {
    category: Sparte.Fernwaerme,
    tariffClasses,
  } as Product;
};

<div (mweClickOutside)="hideDropDown()" class="position-relative">
  <mwe-address
    (click)="toggleDropDown()"
    [address]="address()"
    [isAddressDetailsBodyVisible]="false"
    [ngClass]="{ pointer: addressOptions()?.length > 1 }"
    dropdownType="custom"
  >
    @if (addressOptions()?.length > 1) {
      <ng-container slot="icon-right">
        <mwe-button [isIconBtn]="true" size="md" category="tertiary" color="white">
          <i class="fas fa-chevron-down transition-all" aria-hidden="true" [ngClass]="{ 'rotate-x-180': !isCollapsed() }"></i>
        </mwe-button>
      </ng-container>
    }
  </mwe-address>
  <div class="address-selection-dropdown-menu">
    <div class="dropdown-menu top-border-0 rounded-3 menu-full-width d-block">
      <div [(ngbCollapse)]="isCollapsed" class="list-group-flush rounded-3 pl-0 pr-0 address-menu" data-test="address-selection-dropdown">
        @for (option of addressOptions(); track option) {
          @if (option?.address) {
            <a class="list-group-item" (click)="onAddressSelected(option)" [ngClass]="{ selected: isAddressSelected(option.address) }">
              @if (option.address.addressGroup?.groupName) {
                <span class="d-block">{{ option.address.addressGroup.groupName }}</span>
              }
              {{ option.address | getAddressPostCodeAndStreet }}
            </a>
          }
        }
      </div>
    </div>
  </div>
</div>

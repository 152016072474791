import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TimelineItemComponent } from './timeline-item/timeline-item.component';

@Component({
  selector: 'mwe-timeline',
  templateUrl: './timeline.component.html',
})
export class TimelineComponent implements AfterContentInit {
  @Input() showLine = true;
  @ContentChildren(TimelineItemComponent) timelineItems!: QueryList<TimelineItemComponent>;

  ngAfterContentInit(): void {
    this.updateItemIndexes();
    this.timelineItems.changes.subscribe(() => this.updateItemIndexes());
  }

  private updateItemIndexes(): void {
    this.timelineItems.forEach((item, index) => {
      item.index = index + 1;
    });
  }
}

import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { InfoStep } from '../backend-process/backend-process-presentation/backend-process-presentation.component';

@Component({
  selector: 'mwe-next-steps',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './next-steps.component.html',
})
export class NextStepsComponent {
  asCard = input<boolean>(false);
  infoSteps = input<InfoStep[]>([]);
  title = input<string>('summary.whatNext');

  formattedSteps = computed(() => {
    return this.infoSteps().map(step => {
      return {
        ...step,
        label: Array.isArray(step.label) ? step.label : [step.label],
      };
    });
  });
}

<div
  [ngClass]="{ 'no-box-shadow': noBoxShadow() }"
  class="card-basic rounded-13 flex-grow-1 inline-size {{ theme().cardClass }}"
  data-test="tariff-card"
  style="transition: background-color 0.2s ease-out"
>
  <div class="card-basic-body position-relative p-3 p-lg-4 z-0 flex-grow-0 d-flex flex-column">
    @if (hasDiscount() || isDiscountKnoedlVisible()) {
      <div
        data-test="tariff-card-knoedl"
        class="d-flex flex-column justify-content-center align-items-center position-absolute z-1 {{
          state() === 'loading' ? theme().cardClass : 'bg-warning'
        }} text-white rounded-pill"
        style="
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 4rem;
          height: 4rem;
          box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
        "
      >
        <i class="fas fa-gift font-size-20"></i>
        <div class="font-size-14 fw-semibold lh-1 mt-1">AKTION</div>
      </div>
    }
    <div class="bg-ellipse rounded-13 rounded-bottom-0"></div>
    <div class="row align-items-center position-relative">
      <div class="col">
        <p class="text--big mb-0 text-white fw-semibold">&nbsp;</p>
        <mwe-headline
          [headlineLevelIncrement]="0"
          [text]="title()"
          cssClass="h5 mb-0 text-white fw-semibold"
          data-test="tariff-card-title"
        />
        @if (subTitle() && !isSpot()) {
          <p class="text--big mb-0 text-white" data-test="tariff-card-subtitle">{{ subTitle() }}</p>
        }
        @if (isSpot()) {
          <p class="text--big mb-0 text-white" data-test="tariff-card-subtitle">
            {{ 'products.details.tariffCard.spot.subline' | translate }}
          </p>
        }
      </div>
      <div class="col-auto">
        <img [src]="iconName() | addStaticImagePath" style="max-width: 4rem" class="img-fluid w-100" />
      </div>
    </div>
  </div>
  @if (hasContent()) {
    <div
      [ngClass]="{ 'rounded-13': !hasActions(), 'rounded-top-13 border-bottom-0': hasActions() }"
      class="card-basic-body rounded-13 bg-white p-3 p-lg-4 d-flex flex-column"
      style="border: 4px solid transparent; background-clip: padding-box"
    >
      <ng-content></ng-content>
    </div>
  }
  @if (hasActions()) {
    <div
      class="card-basic-body rounded-bottom-13 bg-gray--300 border-top-0 p-3 p-lg-4 flex-grow-0"
      style="margin-top: -1px; border: 4px solid transparent; background-clip: padding-box"
    >
      <ng-content select="[slot='actions']"></ng-content>
    </div>
  }
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../config/products.config.json';
import {
  HTTPError,
  HTTPResponse,
  IOlavEntry,
  IOrderDetailsInfo,
  Product,
  IProduktVerfuegbarkeit,
  IRelocationDataDTO,
  newRelocationDataDTO,
  newRelocationDetailsInfo,
  parseILCustomerAddress,
  parseRelocationDataDTO,
} from '@mwe/models';
import {
  getAccountOwner,
  getInvoiceAddress,
  getInvoiceEmail,
  isProductCategory,
  isProductCategoryFernwaerme,
  isProductCategoryStromOrGas,
  parseInvoiceAccount,
  parseIPartnerFromClearingAccount,
  parseIRechnungsempfaengerFromClearingAccount,
  parseIVertragskontoFromClearingAccount,
  setBezahlartZahlschein,
} from '@mwe/utils';
import { ProductCategoryEnum } from '@mwe/constants';
import { RelocationStateService } from './relocation-state.service';
import { OrderService } from '../order/order.service';
import { EnvironmentService } from '../environment/environment.service';
import { ClearingAccountLogic } from '../invoices/clearing-account/clearing-account.logic';
import { lastValueFrom } from 'rxjs';
import { EnvironmentCustomerType } from '@mwe/environment';

@Injectable({
  providedIn: 'root',
})
export class RelocationService {
  public resourceUrl: string;
  private config: any;
  private inkaTitleMap = new Map<string, string>();

  constructor(
    private http: HttpClient,
    private relocationStateService: RelocationStateService,
    private clearingAccountLogic: ClearingAccountLogic,
    private orderService: OrderService,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/relocation/';
    this.config = config;
    this.inkaTitleMap.set('0001', 'Frau');
    this.inkaTitleMap.set('0002', 'Herr');
    this.inkaTitleMap.set('0003', 'Firma');
    this.inkaTitleMap.set('0004', '');
    this.inkaTitleMap.set('0005', 'Frau/Herr');
    this.inkaTitleMap.set('0006', 'Rechtsanwälte');
  }

  getAvailability(selectedOlav: IOlavEntry): Promise<IProduktVerfuegbarkeit[]> {
    return this.orderService.getAvailability(selectedOlav);
  }

  setDeliveryOfContractData(sendContractDetailsToNewAddress: boolean): void {
    const currentRelocationDetails = this.relocationStateService.relocationDetails;
    if (currentRelocationDetails) {
      currentRelocationDetails.forEach(productDetails => {
        if (isProductCategory(productDetails.category, ProductCategoryEnum.FERNWAERME) && productDetails.status === 'Anmeldung') {
          productDetails.zustelladresse = sendContractDetailsToNewAddress ? 'to' : 'from';
        }
      });
      this.relocationStateService.relocationDetails = currentRelocationDetails;
    }
  }

  isDeliveryOfContractDataSetNewAddress(): boolean {
    const fwDewtails = this.relocationStateService.relocationDetails.find(
      productDetails => isProductCategory(productDetails.category, ProductCategoryEnum.FERNWAERME) && productDetails.status === 'Anmeldung',
    );
    return fwDewtails ? fwDewtails.zustelladresse === 'to' : false;
  }

  hasFernwaermeInNewAddressProducts(): boolean {
    return this.getNewAddressProductCategories().some(elem => isProductCategory(ProductCategoryEnum[elem], ProductCategoryEnum.FERNWAERME));
  }

  hasFernwaermeInOldAddressProducts(): boolean {
    return [...this.getNewAddressProductCategories(), ...this.getOldAddressProductCategories()].some(elem =>
      isProductCategory(ProductCategoryEnum[elem], ProductCategoryEnum.FERNWAERME),
    );
  }

  getNewAddressProductCategories(): ProductCategoryEnum[] {
    return this.relocationStateService.relocationDetails
      .filter(elem => elem.status === 'Anmeldung')
      .map(elem => ProductCategoryEnum[elem.category.toUpperCase()]);
  }

  getOldAddressProductCategories(): ProductCategoryEnum[] {
    return this.relocationStateService.relocationDetails
      .filter(elem => elem.status === 'Abmeldung')
      .map(elem => ProductCategoryEnum[elem.category.toUpperCase()]);
  }

  hasValidRelocationProduct(): boolean {
    if (this.relocationStateService.addressDetailsLinkType) {
      return false;
    }

    return !!this.relocationStateService.relocationFromProducts.find(
      p => isProductCategoryStromOrGas(p.category) || isProductCategoryFernwaerme(p.category),
    );
  }

  async loadDetailsData(
    products: Product[],
    orderStatus: 'Abmeldung' | 'Anmeldung',
    zustelladresse: 'from' | 'to' | '',
  ): Promise<IOrderDetailsInfo[]> {
    const newDetails: IOrderDetailsInfo[] = [];
    if (products) {
      for (const element of products) {
        const product = element;
        const customerType = this.environmentService.getPortalCustomerType();
        const orderDetail = this.relocationStateService.relocationDetails;
        if (!orderDetail) {
          const tariffObj = this.updateTariffObj(orderStatus, product, customerType);

          const orderDetailData = this.getOrderDetailData(orderStatus, zustelladresse, product, tariffObj);

          if (this.isAccountNumberEmpty(product)) {
            newDetails.push(orderDetailData);
            continue;
          }
          const alreadyLoadedDetails = this.getAlreadyLoadedDetails(newDetails, product);

          try {
            orderDetailData.verrechnungskonto = await this.getVerrechnungskonto(alreadyLoadedDetails, product);
          } catch (e) {
            throw new Error('general_error');
          }
          const accountCustomer = getAccountOwner(orderDetailData.verrechnungskonto.geschaeftspartnerBeziehungen);
          orderDetailData.physischePerson = accountCustomer.physischePerson;
          orderDetailData.invoiceAddress = parseILCustomerAddress(
            getInvoiceAddress(orderDetailData.verrechnungskonto.geschaeftspartnerBeziehungen),
          );
          const invoiceEmail = getInvoiceEmail(orderDetailData.verrechnungskonto);
          orderDetailData.eRechnungInfo = {
            isERechnungsEmpfaenger: !!invoiceEmail,
            rechnungEmail: invoiceEmail,
          };
          parseInvoiceAccount(orderDetailData);

          const rechnungsempfaenger = parseIRechnungsempfaengerFromClearingAccount(orderDetailData.verrechnungskonto);
          orderDetailData.produktDetails = {
            partner: parseIPartnerFromClearingAccount(orderDetailData.verrechnungskonto),
            vertragskonto: parseIVertragskontoFromClearingAccount(orderDetailData.verrechnungskonto),
            anschlussobjekt: null,
            rechnungsempfaenger,
            kontostand: null,
            ablesung: null,
            verbraeuche: null,
            teilbetragsplaene: null,
            rechnungen: null,
          };
          newDetails.push(orderDetailData);
        } else {
          const copy = JSON.parse(JSON.stringify(orderDetail));
          copy.status = orderStatus;
          copy.zustelladresse = zustelladresse;
          newDetails.push(copy);
        }
      }
      this.updateIfStatusIsNewOrder(orderStatus, newDetails);
    }
    return newDetails;
  }

  private updateIfStatusIsNewOrder(orderStatus: 'Abmeldung' | 'Anmeldung', newDetails: IOrderDetailsInfo[]) {
    if (orderStatus === 'Anmeldung') {
      this.overrideBezahlartForFernwaermeRegistration(newDetails);
    }
  }

  private isAccountNumberEmpty(product: Product) {
    return !product.accountNumber || product.accountNumber === '';
  }

  private updateTariffObj(orderStatus: 'Abmeldung' | 'Anmeldung', product: Product, customerType: EnvironmentCustomerType) {
    let tariffObj: { name: string; shortcut: string; key: string };
    switch (orderStatus) {
      case 'Abmeldung':
        tariffObj = { name: product.tariffClasses[0], shortcut: '', key: '' };
        break;
      case 'Anmeldung':
        tariffObj = this.config.tariffs[product.category.toLowerCase()][customerType];
        break;
    }
    return tariffObj;
  }

  private async getVerrechnungskonto(alreadyLoadedDetails: IOrderDetailsInfo, product: Product) {
    return alreadyLoadedDetails && alreadyLoadedDetails.verrechnungskonto
      ? alreadyLoadedDetails.verrechnungskonto
      : (await this.clearingAccountLogic.getAccountInfo(product.accountNumber, product.businessPartnerNumber, product.systemId))
          .verrechnungskonto;
  }

  private getOrderDetailData(
    orderStatus: 'Abmeldung' | 'Anmeldung',
    zustelladresse: 'from' | 'to' | '',
    product: Product,
    tariffObj: {
      name: string;
      shortcut: string;
      key: string;
    },
  ) {
    return newRelocationDetailsInfo(
      orderStatus,
      zustelladresse,
      product.category,
      product.customerNumber,
      product.accountNumber,
      tariffObj,
      isProductCategory(product.category, ProductCategoryEnum.FERNWAERME) ? product.tariffClasses : [],
    );
  }

  private getAlreadyLoadedDetails(newDetails: IOrderDetailsInfo[], product: Product) {
    return newDetails.find(
      item =>
        item.customerNumber === product.customerNumber &&
        item.accountNumber === product.accountNumber &&
        item.category === product.category,
    );
  }

  overrideBezahlartForFernwaermeRegistration(relocationDetails: IOrderDetailsInfo[]): void {
    relocationDetails
      .filter(relocationDetail => isProductCategory(relocationDetail.category, ProductCategoryEnum.FERNWAERME))
      .forEach(fernwaermeDetail => {
        setBezahlartZahlschein(fernwaermeDetail);
      });
  }

  showUseFernwaermeDetails(): boolean {
    return (
      this.relocationStateService.relocationFromProducts.length === 1 &&
      isProductCategory(this.relocationStateService.relocationFromProducts[0].category, ProductCategoryEnum.FERNWAERME)
    );
  }

  async confirmRelocation(): Promise<HTTPResponse<string>> {
    try {
      const res = await lastValueFrom(
        this.http.post(this.resourceUrl + 'confirm', newRelocationDataDTO(this.relocationStateService.getRelocationData()), {
          observe: 'response',
        }),
      );
      return new HTTPResponse<string>(res.status + '', 'IL', 'Ok');
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  getRelocationSummaryDetails(): Promise<IRelocationDataDTO> {
    const id = this.relocationStateService.relocationFromAddress?.addressGroup
      ? this.relocationStateService.relocationFromAddress.addressGroup.id
      : this.relocationStateService.lastOrderId;
    return this.getRelocationSummaryDetailsFor(id);
  }

  getRelocationSummaryDetailsFor(addressGroupId: string): Promise<IRelocationDataDTO> {
    return lastValueFrom(this.http.get(this.resourceUrl + 'relocationSummaryDetails?addressGroupId=' + addressGroupId)).then(res => {
      return parseRelocationDataDTO(res);
    });
  }

  getConfigPossibleCategories(): any {
    return this.config.possibleCategories;
  }

  getConfigAvailabilityForCategory(category: string): any {
    return this.config.availability[category];
  }

  getConfigTariffs(): any {
    return this.config.tariffs;
  }

  getConfigTariffsForCategory(category: string): any {
    return this.config.tariffs[category];
  }
}

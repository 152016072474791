<mwe-product-tariff-card-template
  [product]="product()"
  [headlineReference]="headlineService.getHeadline(0)"
  data-test="tariff-card-waerme"
  [hasContent]="hasTariffClasses() || !hideNoTariffsMessage()"
>
  @if (hasTariffClasses()) {
    <mwe-headline [headlineLevelIncrement]="1" cssClass="h5 mb-3" text="products.details.tariffList" />
    <ul class="list-unstyled" data-test="waerme-tariff-card-class-list">
      @for (item of tariffClasses() | async; track $index; let last = $last) {
        <li class="row gx-2" [ngClass]="{ 'mb-2': !last }">
          <div class="col-auto">
            <i class="d-block fas fa-circle-plus text-success" style="font-size: 1.5rem"></i>
          </div>
          <div class="col align-self-center">
            <span class="text-big fw-semibold text-secondary">{{ item }}</span>
          </div>
        </li>
      }
    </ul>
  } @else if (!hideNoTariffsMessage()) {
    <mwe-info-text-card text="products.details.noTariffList" />
  }
</mwe-product-tariff-card-template>

import { Address } from '../address/address.model';
import { ICustomerNumberInfo } from '../customer/customer.model';
import { IVerknuepfungenAdditionalDataProduktDetails } from '../integrationlayer/api.model';
import { DataChangeType } from '../invoices/invoice-data-change.models';
import { IBusinessData } from '../order/order.model';

export enum ContractStatusEnum {
  Aktiv = 'AKTIV',
  Beendet = 'BEENDET',
  Geplant = 'GEPLANT',
}

export interface Product {
  id?: any;
  customerNumber: string; // kundennummer
  accountNumber: string; // vertragsKontoNummer
  category: string; // sparte
  tariffClasses: string[]; // tarif bzw. Kartennummer (Emobility), Subkategorie (bei Wärme)
  address: Address;
  vertragsStatus?: string;
  tarifKey?: string;
  anlageId?: string;
  systemId?: string;
  isAddressDetailsLinkOnlineNotPossible?: boolean;
  businessPartnerNumber: string; // geschaeftsPartnerId
  associationDetails?: IVerknuepfungenAdditionalDataProduktDetails[];
}

export class CardProduct {
  constructor(
    public product: Product,
    public status: 'adopted' | 'notAdopted' | 'notAvailable' | 'inProgress',
    public productTraiffKey?: string,
    public cardMessageKey?: string,
  ) {
    this.product = product;
    this.status = status;
    this.productTraiffKey = productTraiffKey;
    this.cardMessageKey = cardMessageKey;
  }

  // TODO re-factoring candidate
  isSelectable(): boolean {
    return this.status.indexOf('dopted') > -1;
  }
}

export interface IMultiProductCard {
  categories?: string[];
  id?: string;
  isChecked?: boolean;
  accountNumber?: string;
  customerNumber?: string;
  displayAccountNumber?: string; // for display in the UI handles the case of Fernwärme
  displayAccountNumberLabel?: string; // for display in the UI handles the case of Fernwärme Vertragskonto > Kundennummer
}

export enum RecentOrderType {
  NewClient = 'NC',
  TariffChange = 'TC',
  InvoiceDataChange = 'IDC',
  CustomerChange = 'CC',
  VoucherRedeem = 'VR',
  ChargingCardOrder = 'CCO',
  PaymentAgreement = 'PA',
  PartialAmountChange = 'PAC',
  MemberGetsMember = 'MGM',
  CAInformationRequest = 'CAIR',
  Relocation = 'UZ',
  MoveOut = 'AZ',
}

export enum NavigateToType {
  recentOrderStatus = 'ROS',
}

export interface RecentOrderDTO {
  id: number;
  orderedAt: string;
  type: string;
  dataType?: string;
  address?: Address;
  addressCode?: string;
  businessData?: IBusinessData;
  customerData?: ICustomerNumberInfo;
  addtlData?: string;
}

export interface RecentOrder {
  id: number;
  orderedAt: string;
  type: RecentOrderType;
  dataType?: DataChangeType;
  address: Address;
  customerData?: ICustomerNumberInfo;
  msgKey: string;
  addtlData?: string;
}

export interface IConsumptionHistory {
  verbrauchshistorie: IConsumption[];
}

export interface IConsumption {
  sparte: string;
  accountNumber?: string;
  verbrauch: IConsumptionDetail[];
}

export interface IConsumptionDetail {
  belegNummer: string;
  lieferKomponente?: string;
  datumVon: string;
  datumBis: string;
  tage: number;
  verbrauch: number;
  verbrauchseinheit: string;
  mittelwert: number;
  betragNetto: number;
}

export interface IChartData {
  name?: string;
  value?: number;
  extra?: { datumVon?: string; datumBis?: string; verbrauchseinheit?: string };
}

export enum ChartCategoryColor {
  STROM = '#F7AD3F',
  NACHTSTROM = '#2D94BC',
  STROMRUECK = '#269782',
  GAS = '#1375A6',
  FERNWAERME = '#E61E5B',
}

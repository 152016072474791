import { Component, Input, OnInit } from '@angular/core';
import { AdditionalProductsSummaryPoint, Address, IERechnungsInfo } from '@mwe/models';
import { getAddressGroupFormatted } from '@mwe/utils';

@Component({
  selector: 'mwe-summary-invoice',
  templateUrl: './summary-invoice.component.html',
})
export class SummaryInvoiceComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() paymentIcon: string;
  @Input() paymentText: string;
  @Input() eRechnungInfo: IERechnungsInfo;
  @Input() invoiceAddress: Address;
  @Input() invoiceInfoAlertMessage?: string;
  @Input() areBankAccountInfoTextsVisible = false;
  @Input() showBankAccountInfoTextAsAlert = false;
  @Input() productCategories?: string[];
  @Input() infoTextCardLabel?: string;
  @Input() additionalBulletPoints?: AdditionalProductsSummaryPoint[];

  @Input() emailMessageInfoKey?: string;
  @Input() email?: string;

  formattedInvoiceAddress: string;

  ngOnInit(): void {
    this.formattedInvoiceAddress = getAddressGroupFormatted(this.invoiceAddress, true);
  }
}

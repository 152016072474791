import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { FeatureToggleEnum, NEW_CLIENT_ROUTE, ProductCategoryEnum, ServiceStateEnum, SUPERSCHNELL_ROUTE } from '@mwe/constants';
import {
  InternetProducts,
  IOlavEntry,
  IProduktAuswahlDetails,
  IProduktAuswahlParams,
  IProduktSelektion,
  IProduktVerfuegbarkeit,
  parseILAddress,
  TarifOption,
} from '@mwe/models';
import {
  getSingleValueFromParams,
  isArrayWithMinOneItem,
  isProductCategory,
  isProductCategoryFernwaerme,
  isProductCategoryInternet,
  mapTariffQueryParameters,
  specialHackToCheckIfGasIsAvailable,
  getTariffOptionsIdList,
} from '@mwe/utils';
import { CookieService } from 'ngx-cookie-service';
import { NewClientStateService } from './new-client-state.service';
import { NewClientService } from './new-client.service';
import { TariffSelectionLogic } from '../tariff/selection/tariff-selection.logic';
import { LoggingService } from '../logging/logging.service';

import { CsvDataService } from '../address/csv-data.service';
import { StaticContentService } from '../static-content/static-content.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root',
})
export class NewClientLogic {
  private readonly tariffKeyFernwaerme = 'waerme';
  private internetProducts: InternetProducts;

  // remove this object once we get the data from the corresponding IL endpoints this
  mockCategories = ['Internet', 'Iptv', 'Voip'];

  constructor(
    private newClientStateService: NewClientStateService,
    private newClientService: NewClientService,
    private loggingService: LoggingService,
    private tariffSelectionLogic: TariffSelectionLogic,
    private cookieService: CookieService,
    private csvDataService: CsvDataService,
    private staticContentService: StaticContentService,
    private profileService: ProfileService,
  ) {}

  async checkPreDefinedProductsAndCookies(queryParams: Params): Promise<void> {
    this.checkPredefinedVoucherCode(queryParams);
    this.checkPredefinedOlavKey(queryParams);
    this.checkTkConnId(queryParams);
    this.checkForMgmCode();
    await this.checkPredefinedTariffKeys(queryParams);
  }

  getMultiProductPrimaryCategory(productCategory: string): string {
    const multiProductCardCategories = this.newClientService.getMultiProductCardCategories();
    return multiProductCardCategories[productCategory];
  }

  getMultiProductCardOptionalCategories(primaryProductCategory: string): string[] {
    const multiProductCardCategories = this.newClientService.getMultiProductCardCategories();
    const optionalProducts = [];
    for (const prop in multiProductCardCategories) {
      if (Object.prototype.hasOwnProperty.call(multiProductCardCategories, prop)) {
        if (prop !== primaryProductCategory && multiProductCardCategories[prop] === primaryProductCategory) {
          optionalProducts.push(prop);
        }
      }
    }
    return optionalProducts;
  }

  getTariffRestrictions(primaryProductCategory: string, productCategory: string): string[] {
    if (
      this.newClientService.getMultiProductTariffRestrictions() &&
      this.newClientService.getMultiProductTariffRestrictions()[productCategory]
    ) {
      return this.newClientService.getMultiProductTariffRestrictions()[productCategory][primaryProductCategory] || [];
    }
    return [];
  }

  populateIdCardForInvoiceEvidenceEmailV2() {
    this.newClientService.getIpAddress().then(response => {
      this.newClientStateService.idCard = { art: 'Email V2', ip: response.remoteAddress };
    });
  }

  reduceTariffByRestictions(
    productCategory: string,
    productsToCheck: IProduktAuswahlDetails[],
    selectedPrimaryProductTariff: string,
  ): IProduktAuswahlDetails[] {
    const primaryProductCategory = this.getMultiProductPrimaryCategory(productCategory);

    if (primaryProductCategory && primaryProductCategory !== productCategory) {
      const tariffRestrictions = this.getTariffRestrictions(primaryProductCategory, productCategory);
      if (tariffRestrictions[selectedPrimaryProductTariff]) {
        return productsToCheck.filter(p => tariffRestrictions[selectedPrimaryProductTariff].includes(p.tarif.ISUTarifKey));
      }
    }
    return productsToCheck;
  }

  getBaseUrl(): string {
    return this.isNewClientProccess() ? `${NEW_CLIENT_ROUTE}` : `${SUPERSCHNELL_ROUTE}`;
  }

  isNewClientProccess(url?: string): boolean {
    if (this.newClientStateService.newClientType) {
      return this.newClientStateService.newClientType === 'kunde-werden';
    } else if (url) {
      return url.indexOf('kunde-werden') > -1;
    }
    return false;
  }

  isSuperschnellProcess(url?: string): boolean {
    if (this.newClientStateService.newClientType) {
      return this.newClientStateService.newClientType === 'superschnell-bestellen';
    } else if (url) {
      return url.indexOf('superschnell-bestellen') > -1;
    }
    return false;
  }

  isNoSuperschnellProductAvailable(): boolean {
    if (this.isSuperschnellProcess()) {
      const internetAvailability = this.newClientStateService.newProductAvailabilities?.find(
        a => a.sparte === 'Internet' && a.verfuegbar === 'JA',
      );
      if (internetAvailability?.details) {
        this.newClientStateService.tkConnId = internetAvailability.details[0].tkconnid;
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getPossibleCategories(): any {
    if (this.isNewClientProccess()) {
      return this.newClientService.getConfigPossibleCategories().filter(c => !isProductCategoryInternet(c));
    } else {
      return this.newClientService.getConfigPossibleCategories().filter(c => isProductCategoryInternet(c));
    }
  }

  async getProductsAvailability(): Promise<void> {
    try {
      const response = await this.newClientService.getAvailability(this.newClientStateService.newClientAddressOlav);
      if (response) {
        // MWE-3728 temp hack merge hack with relocation solution
        // real solution IL should return filtered list
        this.mapMultiProductCategories(response);

        await specialHackToCheckIfGasIsAvailable(
          this.newClientStateService.newClientAddressOlav,
          this.newClientStateService.newProductAvailabilities,
          () => this.csvDataService.getCoverageArea('assets/data/gas_versorgungsgebiet.csv'),
        );

        this.newClientStateService.newProductAvailabilitiesState = ServiceStateEnum.SUCCESS;
      } else {
        this.newClientStateService.newProductAvailabilitiesState = ServiceStateEnum.ERROR;
      }
    } catch (error) {
      this.newClientStateService.newProductAvailabilitiesState = ServiceStateEnum.ERROR;
      this.loggingService.logError(error, `can't load/render availabilities`);
    }
  }

  private mapMultiProductCategories(response: IProduktVerfuegbarkeit[]) {
    const validCategories = this.getPossibleCategories();
    const multiProductCardCategories = this.newClientService.getMultiProductCardCategories();
    this.newClientStateService.newProductAvailabilities = response.filter(p => {
      p.multiProductCardCategory = multiProductCardCategories[p.sparte];
      return validCategories.includes(p.sparte);
    });
  }

  private checkPredefinedOlavKey(queryParams: Params): void {
    const olavKey = getSingleValueFromParams(queryParams, 'newAddress');

    if (!olavKey) {
      return;
    }

    this.newClientStateService.predefinedOlavKey = olavKey;
  }

  private async checkTkConnId(queryParams: Params): Promise<void> {
    const tkconnid = getSingleValueFromParams(queryParams, 'tkconnid');

    if (!tkconnid) {
      return;
    }

    const gaa = getSingleValueFromParams(queryParams, 'gaa');

    const availabilities = await this.newClientService.getInternetAvailability(gaa, tkconnid);
    const internetAvailability = availabilities.find(a => a.sparte === 'Internet' && a.verfuegbar === 'JA');

    if (internetAvailability) {
      this.newClientStateService.tkConnId = tkconnid;
      if (!internetAvailability.details[0].adresse.land) {
        internetAvailability.details[0].adresse.land = 'AT';
      }
      this.newClientStateService.newClientAddress = parseILAddress(internetAvailability.details[0].adresse);
      this.setOlavAddress(internetAvailability);
      this.newClientStateService.newProductAvailabilities = availabilities;
      this.newClientStateService.newProductAvailabilitiesState = ServiceStateEnum.SUCCESS;
      this.mapMultiProductCategories(availabilities);
      this.newClientStateService.skipPreselectedAddress = true;
    } else {
      this.newClientStateService.newProductAvailabilitiesState = ServiceStateEnum.ERROR;
    }
  }

  private setOlavAddress(internetAvailability: IProduktVerfuegbarkeit) {
    this.newClientStateService.newClientAddressOlav = {} as IOlavEntry;
    this.newClientStateService.newClientAddressOlav.staat = internetAvailability.details[0].adresse.land;
    this.newClientStateService.newClientAddressOlav.plz = internetAvailability.details[0].adresse.plz;
    this.newClientStateService.newClientAddressOlav.gemeinde = internetAvailability.details[0].adresse.ort;
    this.newClientStateService.newClientAddressOlav.strasse = internetAvailability.details[0].adresse.strasse;
    this.newClientStateService.newClientAddressOlav.gebadr1 = internetAvailability.details[0].adresse.hausnummer;
    if (internetAvailability.details[0].adresse.stiege) {
      this.newClientStateService.newClientAddressOlav.gebadr1 += '/' + internetAvailability.details[0].adresse.stiege;
    }
    this.newClientStateService.newClientAddressOlav.tuerNr = internetAvailability.details[0].adresse.tuernummer;
    this.newClientStateService.newClientAddressOlav.gebaeudeKey = internetAvailability.details[0].adresse.olavGaa;
    this.newClientStateService.newClientAddressOlav.anlageAdressKey = internetAvailability.details[0].adresse.olavAaa;
  }

  private checkPredefinedVoucherCode(queryParams: Params): void {
    const voucherCode = getSingleValueFromParams(queryParams, 'voucher');

    if (!voucherCode) {
      return;
    }

    this.newClientStateService.predefinedVoucherCode = voucherCode;
    this.newClientStateService.trackingData.voucher = voucherCode;
  }

  private async checkPredefinedTariffKeys(queryParams: Params): Promise<void> {
    const tariffs = mapTariffQueryParameters(queryParams);

    if (this.hasFernwaerme(tariffs)) {
      this.newClientStateService.preselectProductFernwaerme = true;
    }

    if (this.canSkipPredefinedProductsCall(tariffs)) {
      return;
    }

    await this.getPredefinedProducts(tariffs);
  }

  checkForMgmCode(): void {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.GOODIES_MGM_ENABLED)) {
      this.newClientStateService.mgmCode = undefined;
      return;
    }
    const mgmCode = this.cookieService.get('mgm_vorteil');
    if (!mgmCode) {
      return;
    }

    this.newClientStateService.mgmCode = mgmCode;
  }

  private hasFernwaerme(tariffKeys: Map<string, string>[]): boolean {
    return !!tariffKeys.find(item => item.get('key') === this.tariffKeyFernwaerme);
  }

  private canSkipPredefinedProductsCall(tariffKeys: Map<string, string>[]): boolean {
    const hasOnlyFernwaerme = tariffKeys.length === 1 && this.hasFernwaerme(tariffKeys);
    const hasNoKeys = tariffKeys.length === 0;

    return hasOnlyFernwaerme || hasNoKeys;
  }

  private async getPredefinedProducts(tariffKeys: Map<string, string>[]): Promise<void> {
    const tariffKeysWithoutFernwaerme = tariffKeys.filter(item => item.get('key') !== this.tariffKeyFernwaerme);

    let itemKeys = tariffKeysWithoutFernwaerme.map(item => item.get('key'));

    try {
      const resp = await this.newClientService.getProductsForSelection({ kanalCode: 'ON', prozessCode: 'KW' });
      let productsForSelection = resp?.payload?.produkte;

      if (!productsForSelection) {
        return;
      }

      if (this.isSuperschnellProcess()) {
        await this.loadStaticInternetProducts();
        productsForSelection = [...resp.payload.produkte, ...this.internetProducts.produkte];
      }

      const additionalInternetProductKeys: Map<string, string>[] = [];
      this.newClientStateService.predefinedProductsForSelection = {
        produkte: productsForSelection.filter(p => {
          if (!itemKeys.includes(p.tarif.ISUTarifKey)) {
            return false;
          }
          if (p.tarif.tarifOptionen) {
            const predefinedKeys = tariffKeys.find(tk => tk.get('key') === p.tarif.ISUTarifKey);
            this.mapAdditionalInternetProducts(p, predefinedKeys, additionalInternetProductKeys);
            this.mapProductSelectionOptions(predefinedKeys, p);
          }
          return true;
        }),
      };

      if (additionalInternetProductKeys.length > 0) {
        itemKeys = additionalInternetProductKeys.map(item => item.get('key'));
        const additionalInternetProductSelection = productsForSelection.filter(p => {
          if (!itemKeys.includes(p.tarif.ISUTarifKey)) {
            return false;
          }
          if (p.tarif.tarifOptionen) {
            const predefinedKeys = additionalInternetProductKeys.find(tk => tk.get('key') === p.tarif.ISUTarifKey);
            this.mapProductSelectionOptions(predefinedKeys, p);
          }
          return true;
        });
        this.newClientStateService.predefinedProductsForSelection.produkte = [
          ...this.newClientStateService.predefinedProductsForSelection.produkte,
          ...additionalInternetProductSelection,
        ];
      }
    } catch (e) {
      this.loggingService.logError(e, `can't load predefined products`);
    }
  }

  private async loadStaticInternetProducts() {
    if (!this.internetProducts) {
      this.internetProducts = await this.staticContentService.loadInternetProductsConfiguration();
    }
  }

  private mapProductSelectionOptions(predefinedKeys: Map<string, string>, p: IProduktAuswahlDetails) {
    if (isArrayWithMinOneItem(p?.tarif.tarifOptionen)) {
      const predefinedOptions = predefinedKeys.get('optionen')?.split(',');
      const tariffOptions: TarifOption[] = p.tarif.tarifOptionen.filter(to => predefinedOptions?.includes(to.option));
      const mappedArt = tariffOptions.map(to => to.art);
      const remainingDefaultOptions = p.tarif.tarifOptionen.filter(to => !mappedArt.includes(to.art) && to.default === true);
      p.tarif.tarifOptionen = [...tariffOptions, ...remainingDefaultOptions];
    } else {
      p.tarif.tarifOptionen = [];
    }
  }

  private mapAdditionalInternetProducts(
    p: IProduktAuswahlDetails,
    predefinedKeys: Map<string, string>,
    additionalInternetProductKeys: Map<string, string>[],
  ) {
    if (isProductCategory(p.sparte, ProductCategoryEnum.INTERNET) && predefinedKeys.has('optionen')) {
      let options = predefinedKeys.get('optionen');

      const otherInternetProduktKeys = ['WEBIPTVPREMIUM', 'WEBIPTVLIGHT', 'WEBTEL\u00d6500', 'WEBTELEU500'];
      const predefinedOptions = predefinedKeys.get('optionen').split(',');
      const tvPremiumMatched = options.indexOf(otherInternetProduktKeys[0]) > -1;
      otherInternetProduktKeys.forEach(ok => {
        if (predefinedOptions.includes(ok)) {
          options = options.replace(ok, '');
          const kvpMap = new Map<string, string>();
          kvpMap.set('key', ok);
          additionalInternetProductKeys.push(kvpMap);
        }
      });

      if (tvPremiumMatched && options.trim().length > 0) {
        const kvpMap = additionalInternetProductKeys.find(map => map.get('key') === otherInternetProduktKeys[0]);
        kvpMap.set('optionen', options);
      }
    }
  }

  getProductAvailability(sparte: string): IProduktVerfuegbarkeit {
    return this.newClientStateService.newProductAvailabilities?.find(category => category.sparte.toLowerCase() === sparte.toLowerCase());
  }

  async loadDataForProductsSelection(postCode: string): Promise<void> {
    await this.loadStaticInternetProducts();
    const categories = this.newClientStateService.orderDetails.map(filtereddetail => filtereddetail.category);

    const mockedDetails = this.newClientStateService.orderDetails.filter(details => this.mockCategories.includes(details.category));

    await this.getProductsForSelection(categories.filter(c => !this.mockCategories.includes(c)));
    let indicationCategories = categories;
    let indicationPostCode = postCode;
    if (mockedDetails?.length > 0) {
      indicationCategories = this.mockCategories;
      indicationPostCode = '';
    }

    if (mockedDetails) {
      const productsForSelection = this.newClientStateService.productsForSelection;
      mockedDetails.forEach(md => {
        const pfSelectionCategories = this.newClientStateService.productsForSelection.produkte.map(ps => ps.sparte);
        if (!pfSelectionCategories.includes(md.category)) {
          productsForSelection.produkte = this.newClientStateService.productsForSelection.produkte.concat(
            this.getMockedProducts(md.category),
          );
        }
      });
      this.newClientStateService.productsForSelection = productsForSelection;
    }

    await this.tariffSelectionLogic.getPriceIndications(indicationPostCode, indicationCategories);
    await this.handlePredefinedProductsForTariffSelection(indicationPostCode);
  }

  private async getProductsForSelection(categories: string[]): Promise<void> {
    if (this.canWeReuseProductsForSelectionSessionStorage()) {
      return;
    }

    // category Fernwaerme currently not supported by the endpoint
    const filteredCategories = categories.filter(category => category !== 'Fernwaerme');

    if (filteredCategories?.length === 0) {
      this.newClientStateService.productsForSelection = { produkte: [] };
      this.newClientStateService.productsForSelection.fernwaerme = this.getProductAvailability('Fernwaerme');
      return;
    }

    const params: IProduktAuswahlParams = {
      sparte: filteredCategories.toString(),
      kanalCode: 'ON',
      prozessCode: 'KW',
    };

    const response = await this.newClientService.getProductsForSelection(params);
    if (categories.includes('Fernwaerme')) {
      response.payload.fernwaerme = this.getProductAvailability('Fernwaerme');
    }

    this.newClientStateService.productsForSelection = response.payload;
  }

  private canWeReuseProductsForSelectionSessionStorage(): boolean {
    if (!this.newClientStateService.productsForSelection) {
      return false;
    }

    // orderDetails can be changed in NewClientProductsComponent, so we have to check if products for categories are already loaded
    const areProductsForCategoriesAlreadyLoaded = this.newClientStateService.orderDetails.every(od => {
      if (isProductCategoryFernwaerme(od.category)) {
        return !!this.newClientStateService.productsForSelection.fernwaerme;
      }
      return this.newClientStateService.productsForSelection.produkte.some(p => od.category.toLowerCase() === p.sparte.toLowerCase());
    });

    // if already loaded, filter loaded product selection for selected product categories
    if (areProductsForCategoriesAlreadyLoaded) {
      const currentlySelectedCategories = this.newClientStateService.orderDetails.map(orderDetail => orderDetail.category.toLowerCase());
      const filteredProductsForSelection = this.newClientStateService.productsForSelection.produkte.filter(product =>
        currentlySelectedCategories.includes(product.sparte.toLowerCase()),
      );
      this.newClientStateService.productsForSelection.produkte = filteredProductsForSelection;

      if (!currentlySelectedCategories.includes('fernwaerme')) {
        this.newClientStateService.productsForSelection.fernwaerme = undefined;
      }
    }

    return areProductsForCategoriesAlreadyLoaded;
  }

  private async handlePredefinedProductsForTariffSelection(postCode: string): Promise<void> {
    // when there are some preselected tariff-options we have to update them to get valid prices
    if (!isArrayWithMinOneItem(this.newClientStateService.predefinedProductsForSelection?.produkte)) {
      return;
    }

    const preselectedProductsWithTariffOptions = this.newClientStateService.predefinedProductsForSelection.produkte.filter(p => {
      const notAlreadySelected = !this.newClientStateService.productSelection?.[p.sparte.toLowerCase()];
      return notAlreadySelected && isArrayWithMinOneItem(p.tarif?.tarifOptionen);
    });

    if (!isArrayWithMinOneItem(preselectedProductsWithTariffOptions)) {
      return;
    }

    const prodSelection = {} as IProduktSelektion;
    const requests = preselectedProductsWithTariffOptions.map(preselectedProduct => {
      prodSelection[preselectedProduct.sparte.toLowerCase()] = preselectedProduct;

      const tariff = preselectedProduct.tarif;
      const options = getTariffOptionsIdList(tariff.tarifOptionen);
      return this.tariffSelectionLogic.getUpdatedPriceIndications(postCode, preselectedProduct.sparte, tariff.ISUTarifKey, options);
    });

    this.newClientStateService.selectedPriceIndications = await Promise.all(requests);
    this.newClientStateService.productSelection = prodSelection;
    this.newClientStateService.predefinedProductsForSelection = null;
  }

  // remove this method, variables and config files once we get the data from the corresponding IL endpoints this
  private getMockedProducts(category: string): IProduktAuswahlDetails[] {
    return this.internetProducts.produkte.filter(p => p.sparte === category);
  }
}

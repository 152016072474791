import { Component, computed, inject, input, OnInit } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { Product, TariffSelectionCardData, TextNodeType } from '@mwe/models';
import { HeadlineService } from '../../headline/headline.service';

interface TariffPriceCardTheme {
  cardClass: string;
}

interface TariffPriceCardThemes {
  loading: TariffPriceCardTheme;
  strom: TariffPriceCardTheme;
  gas: TariffPriceCardTheme;
  emobility: TariffPriceCardTheme;
  internet: TariffPriceCardTheme;
  iptv: TariffPriceCardTheme;
  voip: TariffPriceCardTheme;
  nachtstrom: TariffPriceCardTheme;
  stromrueck: TariffPriceCardTheme;
  discount: TariffPriceCardTheme;
  fernwaerme: TariffPriceCardTheme;
}

const cardThemes: TariffPriceCardThemes = {
  loading: {
    cardClass: 'bg-gray',
  },
  strom: {
    cardClass: 'bg-brand-green',
  },
  gas: {
    cardClass: 'bg-secondary-light',
  },
  emobility: {
    cardClass: 'bg-secondary-light',
  },
  internet: {
    cardClass: 'bg-primary',
  },
  iptv: {
    cardClass: 'bg-primary',
  },
  voip: {
    cardClass: 'bg-primary',
  },
  nachtstrom: {
    cardClass: 'bg-secondary-light',
  },
  stromrueck: {
    cardClass: 'bg-brand-green',
  },
  discount: {
    cardClass: 'bg-warning',
  },
  fernwaerme: {
    cardClass: 'bg-primary',
  },
};

const IconMap = {
  // Strom
  SOPTB_0001: '', //OPTIMA Enstpannt
  SOPTA_0001: '', //OPTIMA Aktiv
  SOPTS_0001: '', //OPTIMA Voll Aktiv
  SMEGE_0001: '', //MEGA
  SMEGA_0001: '', //MEGA Aktiv
  SMEGS_0001: '', //MEGA Voll Aktiv
  // Gas
  GEOPTB__01: '', //OPTIMA Enstpannt
  GEOPTA__01: '', //OPTIMA Aktiv
  GEMEGE__01: '', //MEGA
  GEMEGA__01: '', //MEGA Aktiv
};

@Component({
  selector: 'mwe-tariff-card-template',
  templateUrl: './tariff-card-template.component.html',
  providers: [HeadlineService],
})
export class TariffCardTemplateComponent implements OnInit {
  category = input<string>();
  title = input<string>();
  subTitle = input<string>();
  hasDiscount = input<boolean>();
  isDiscountKnoedlVisible = input<false>();
  hasActions = input<boolean>();
  hasContent = input<boolean>(true);
  noBoxShadow = input<boolean>();
  state = input<ServiceStateEnum>();
  headlineReference = input<TextNodeType>('p');
  isSpot = input(false);
  spotUrl = input('');

  iconName = computed<string>(() => {
    if (!this.product()) {
      return `productDetails/${this.category().toLowerCase()}.svg`;
    }

    const tariffKey = (this.product() as Product).tarifKey
      ? (this.product() as Product).tarifKey
      : (this.product() as TariffSelectionCardData).tariffKey;

    switch (tariffKey) {
      case 'SOPTB_0001':
      case 'SMEGE_0001': {
        //OPTIMA Entspannt & MEGA
        return `productDetails/specific/Optima-STROM-Entspannt-white.svg`;
      }
      case 'SOPTA_0001':
      case 'SMEGA_0001': {
        //OPTIMA Aktiv & MEGA Aktiv
        return `productDetails/specific/Optima-STROM-Aktiv-white.svg`;
      }
      case 'SOPTS_0001':
      case 'SMEGS_0001': {
        //OPTIMA Voll Aktiv & MEGA Voll Aktiv
        return `productDetails/specific/Optima-STROM-VollAktiv-white.svg`;
      }
      case 'GEOPTB__01':
      case 'GEMEGE__01': {
        //OPTIMA Gas Entspannt & MEGA Gas
        return `productDetails/specific/Optima-GAS-Entspannt-white.svg`;
      }
      case 'GEOPTA__01':
      case 'GEMEGA__01': {
        //OPTIMA Gas Aktiv & MEGA Gas Aktiv
        return `productDetails/specific/Optima-GAS-Aktiv-white.svg`;
      }
      default: {
        return `productDetails/${this.category().toLowerCase()}.svg`;
      }
    }
  });
  theme = computed<TariffPriceCardTheme>(() => {
    if (this.state() === ServiceStateEnum.LOADING) {
      return cardThemes.loading;
    }
    if (this.hasDiscount()) {
      return cardThemes.discount;
    }

    return cardThemes[this.category()?.toLowerCase()] || cardThemes.loading;
  });

  product = input<Product | TariffSelectionCardData>();

  headlineService = inject(HeadlineService);

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference());
  }
}

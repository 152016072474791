{
  "correlation": {
    "strom": {
      "name": "Wien Energie Vertrieb GmbH & Co KG",
      "shortName": "WEV",
      "reference": "Ein Produkt der Wien Energie Vertrieb GmbH & Co KG"
    },
    "gas": {
      "name": "Wien Energie Vertrieb GmbH & Co KG",
      "shortName": "WEV",
      "reference": "Ein Produkt der Wien Energie Vertrieb GmbH & Co KG"
    },
    "fernwaerme": {
      "name": "Wien Energie GmbH",
      "shortName": "WE",
      "reference": "Ein Produkt der Wien Energie GmbH"
    },
    "nachtstrom": {
      "name": "Wien Energie Vertrieb GmbH & Co KG",
      "shortName": "WEV",
      "reference": "Ein Produkt der Wien Energie Vertrieb GmbH & Co KG"
    },
    "stromrueck": {
      "name": "Wien Energie Vertrieb GmbH & Co KG",
      "shortName": "WEV",
      "reference": "Ein Produkt der Wien Energie Vertrieb GmbH & Co KG"
    },
    "emobility": {
      "name": "Wien Energie GmbH",
      "shortName": "WE",
      "reference": "Ein Produkt der Wien Energie GmbH"
    },
    "internet": {
      "name": "Wien Energie GmbH",
      "shortName": "WE",
      "reference": "Ein Produkt der Wien Energie GmbH"
    },
    "iptv": {
      "name": "Wien Energie GmbH",
      "shortName": "WE",
      "reference": "Ein Produkt der Wien Energie GmbH"
    },
    "voip": {
      "name": "Wien Energie GmbH",
      "shortName": "WE",
      "reference": "Ein Produkt der Wien Energie GmbH"
    }
  }
}

import { ChangeDetectionStrategy, Component, computed, effect, Input, signal, Type } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { BackendProcessStore, StaticContentService, StepInterface, StepStateInterface } from '@mwe/services';

type StepRepresentation = { step: StepInterface; state?: StepStateInterface };
export type InfoStep = Pick<StepInterface, 'title' | 'label'> & Partial<Pick<StepInterface, 'translationParams'>>;

@Component({
  selector: 'mwe-backend-process-presentation-component',
  templateUrl: './backend-process-presentation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackendProcessPresentationComponent {
  private _infoSteps = signal<InfoStep[]>([]);
  @Input() set infoSteps(steps: InfoStep[]) {
    this._infoSteps.set(steps);
  }
  get infoSteps() {
    return this._infoSteps();
  }
  @Input() workInProgressMsgKey = 'process.default.loading.title';
  @Input() workInProgressSubtextKey = 'process.default.loading.message';
  @Input() successMsgKey = 'process.default.success.title';
  @Input() successSubtextKey = 'process.default.success.message';
  @Input() minLoadingSpinnerDisplayTime = 2000;
  /**
   * Optional component to render the steps
   * Will get step: StepInterface and state: StepStateInterface as inputs
   */
  @Input() stepRenderer?: Type<unknown>;

  /** @TODO with Angular 19 refactor this to be signalInputs */
  private _infoTitleKey = signal<string>('summary.whatNext');
  @Input() set infoTitleKey(key: string) {
    this._infoTitleKey.set(key);
  }
  get infoTitleKey() {
    return this._infoTitleKey();
  }
  private _errorTitleKey = signal<string>('process.default.error.title');
  @Input() set errorTitleKey(key: string) {
    this._errorTitleKey.set(key);
  }
  get errorTitleKey() {
    return this._errorTitleKey();
  }

  private _errorMsgKey = signal<string>('process.default.error.message');
  @Input() set errorMsgKey(key: string) {
    this._errorMsgKey.set(key);
  }
  get errorMsgKey() {
    return this._errorMsgKey();
  }

  private _errorMsgRetryKey = signal<string>('process.default.error.message_retry');
  @Input() set errorMsgRetryKey(key: string) {
    this._errorMsgRetryKey.set(key);
  }
  get errorMsgRetryKey() {
    return this._errorMsgRetryKey();
  }

  @Input() alertPlaceholders?: { [key: string]: string };

  minLoadingSpinnerDisplayTimePassed = signal(false);
  minLoadingSpinnerDisplayTimeout: unknown;

  constructor(
    public process: BackendProcessStore,
    private staticContentService: StaticContentService,
  ) {
    effect(() => {
      const state = this.process.processState();
      const isFinished = state === ServiceStateEnum.SUCCESS || state === ServiceStateEnum.FAILED || state === ServiceStateEnum.ERROR;
      const isLoading = state === ServiceStateEnum.LOADING;
      if ((isFinished || isLoading) && !this.minLoadingSpinnerDisplayTimeout) {
        // Show loading spinner for at least 2 seconds
        this.minLoadingSpinnerDisplayTimeout = setTimeout(() => {
          this.minLoadingSpinnerDisplayTimePassed.set(true);
        }, this.minLoadingSpinnerDisplayTime);
      }
    });
  }

  steps = computed<StepRepresentation[]>(() => {
    const steps = Array.from(this.process.steps().entries());
    return steps.map(([step, state]) => ({ step, state }));
  });

  formattedInfoSteps = computed(() => {
    return this.infoSteps.map(step => {
      return {
        ...step,
        label: Array.isArray(step.label) ? step.label : [step.label],
      };
    });
  });

  isFirstStepError = computed(() => {
    const steps = this.steps();
    if (steps.length === 0) {
      return false;
    }
    const firstStepState = steps[0].state;
    if (!firstStepState) {
      return false;
    }
    return firstStepState.state === ServiceStateEnum.ERROR || firstStepState.state === ServiceStateEnum.FAILED;
  });

  showLoadingState = computed(() => {
    const processState = this.process.processState();
    const processInALoadingState = processState === ServiceStateEnum.INIT || processState === ServiceStateEnum.LOADING;
    return processInALoadingState || !this.minLoadingSpinnerDisplayTimePassed();
  });

  showErrorState = computed(
    () =>
      !this.showLoadingState() &&
      (this.process.processState() === ServiceStateEnum.ERROR || this.process.processState() === ServiceStateEnum.FAILED),
  );

  showSuccessState = computed(() => !this.showLoadingState() && this.process.processState() === ServiceStateEnum.SUCCESS);

  retryAllowed = computed(() => this.process.retryAllowed());

  getIconClassForStep(item: StepRepresentation): string | undefined {
    const { step, state } = item;
    const icon = step.iconMap ? step.iconMap.get(state.state) : undefined;
    const cls = step.classMap ? step.classMap.get(state.state) : undefined;
    return icon ? `fas ${icon} ${cls ?? ''}` : undefined;
  }

  getTitleForStep(item: StepRepresentation): string {
    const { step, state } = item;
    const defaultTitle = step.title;
    const stateTitle = step.textMap.get(state.state)?.title;
    return stateTitle ?? defaultTitle;
  }

  getLabelsForStep(item: StepRepresentation): string[] {
    const { step, state } = item;
    const defaultLabel = step?.label ? (Array.isArray(step.label) ? step.label : [step.label]) : undefined;
    let stateLabel = step.textMap.get(state.state)?.label;
    stateLabel = stateLabel ? (Array.isArray(stateLabel) ? stateLabel : [stateLabel]) : undefined;
    return stateLabel ?? defaultLabel;
  }

  getImagePath() {
    return this.staticContentService.getImagePath();
  }
}

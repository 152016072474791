import { Directive, ElementRef, HostListener, inject, input, OnDestroy, output } from '@angular/core';
import { catchError, of, Subscription, tap } from 'rxjs';
import { ServiceStateEnum } from '@mwe/constants';
import { copyToClipboard } from '@mwe/utils';

@Directive({
  selector: '[mweCopyToClipboard]',
})
export class CopyToClipboardDirective<T> implements OnDestroy {
  mweCopyToClipboard = input<T>();

  readonly copyError = output<ServiceStateEnum.ERROR>();
  readonly copySuccess = output<ServiceStateEnum.SUCCESS>();

  private subscription: Subscription | undefined;
  private elementRef: ElementRef<T> = inject(ElementRef);

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  @HostListener('click')
  @HostListener('touchstart')
  copy() {
    const textToCopy = this.mweCopyToClipboard() ?? (this.elementRef.nativeElement as HTMLElement).innerText;
    if (!textToCopy) {
      this.copyError.emit(ServiceStateEnum.ERROR);
      return;
    }
    this.subscription = copyToClipboard(textToCopy)
      .pipe(
        tap(() => {
          this.copySuccess.emit(ServiceStateEnum.SUCCESS);
        }),
        catchError(_ => {
          this.copyError.emit(ServiceStateEnum.ERROR);
          return of(null);
        }),
      )
      .subscribe();
  }
}

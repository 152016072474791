import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDataChangeStateService } from '../customer/change/customer-data-change-state.service';
import { InvoiceDataChangeStateService } from '../invoices/invoice-data/change/invoice-data-change-state.service';
import { OrderService } from '../order/order.service';
import { TariffChangeService } from '../tariff/tariff-change.service';
import { VoucherRedeemStateService } from '../voucher/redeem/voucher-redeem-state.service';
import {
  SEPA_INVOICE_CHANGE_ROUTE,
  CHARGING_CARD_ORDER_ROUTE,
  CHARGING_CARD_ORDER_STATUS_ROUTE,
  CUSTOMER_DATA_CHANGE_ROUTE,
  CUSTOMER_DATA_CHANGE_STATUS_ROUTE,
  INVOICE_ADDRESS_CHANGE_ROUTE,
  INVOICE_CHANGE_STATUS_ROUTE,
  NEW_CLIENT_ROUTE,
  NEW_CLIENT_STATUS_ROUTE,
  PDF_INVOICE_CHANGE_ROUTE,
  TARIFF_CHANGE_ROUTE,
  TARIFF_CHANGE_STATUS_ROUTE,
  VOUCHER_REDEEM_ROUTE,
  VOUCHER_REDEEM_STATUS_ROUTE,
  FIND_PAYMENT_SOLUTION_ROUTE,
  FIND_PAYMENT_SOLUTION_STATUS_ROUTE,
  SUPERSCHNELL_ROUTE,
  INVOICE_PARTIALPAYMENT_CHANGE_STATUS_ROUTE,
  INVOICE_PARTIALPAYMENT_CHANGE_RATE_ROUTE,
  CLEARING_ACCOUNT_INFORMATION_REQUEST_ROUTE,
  CLEARING_ACCOUNT_INFORMATION_REQUEST_STATUS_ROUTE,
  MOVE_OUT_ROUTE,
  MOVE_OUT_STATUS_ROUTE,
  RELOCATION_ROUTE,
  RELOCATION_STATUS_ROUTE,
  SECURE_OFFER_ROUTE,
  SECURE_OFFER_STATUS_ROUTE,
  SEPA_INVOICE_CHANGE_STATUS_ROUTE,
  PDF_INVOICE_CHANGE_STATUS_ROUTE,
} from '@mwe/constants';
import { DataChangeType, RecentOrder, RecentOrderType } from '@mwe/models';
import { FindPaymentSolutionStateService } from '../invoices/find-payment-solution/find-payment-solution-state.service';
import { ChargingCardOrderStateService } from '../charging-card/order/charging-card-order-state.service';
import { PartialPaymentStateService } from '../partial-payment/partial-payment-state.service';
import { CAInformationRequestStateService } from '../invoices/clearing-account/ca-information-request-state.service';
import { MoveOutStateService } from '../move-out/move-out-state.service';
import { RelocationStateService } from '../relocation/relocation-state.service';

@Injectable({
  providedIn: 'root',
})
export class RecentOrderLogic {
  constructor(
    private router: Router,
    private invoiceDataChangeStateService: InvoiceDataChangeStateService,
    private tariffChangeService: TariffChangeService,
    private orderService: OrderService,
    private customerDataChangeStateService: CustomerDataChangeStateService,
    private voucherRedeemStateService: VoucherRedeemStateService,
    private chargingCardOrderStateService: ChargingCardOrderStateService,
    private findPaymentSolutionStateService: FindPaymentSolutionStateService,
    private partialPaymentStateService: PartialPaymentStateService,
    private caInformationRequestStateService: CAInformationRequestStateService,
    private relocationStateService: RelocationStateService,
    private moveOutStateService: MoveOutStateService,
  ) {}

  navigateToOrderDetails(order: RecentOrder): void {
    const idStr = order.id + '';

    switch (order.type) {
      case RecentOrderType.InvoiceDataChange:
        this.invoiceDataChangeStateService.lastOrderId = idStr;
        this.navigateToInvoiceDataChangeStatus(order.dataType);
        break;
      case RecentOrderType.TariffChange:
        this.tariffChangeService.setLastOrderId(idStr);

        if (order.dataType === DataChangeType.SecureOffer) {
          this.router.navigate([`/${SECURE_OFFER_ROUTE}/${SECURE_OFFER_STATUS_ROUTE}`], { state: { orderId: idStr } });
        } else {
          this.router.navigate([`/${TARIFF_CHANGE_ROUTE}/${TARIFF_CHANGE_STATUS_ROUTE}`], { state: { orderId: idStr } });
        }
        break;
      case RecentOrderType.NewClient:
        this.orderService.setLastOrderId(idStr);
        if (order.dataType === DataChangeType.NewClientSS) {
          this.orderService.setNewClientType('superschnell-bestellen');
          this.router.navigate([`/${SUPERSCHNELL_ROUTE}/${NEW_CLIENT_STATUS_ROUTE}`]);
        } else {
          this.orderService.setNewClientType('kunde-werden');
          this.router.navigate([`/${NEW_CLIENT_ROUTE}/${NEW_CLIENT_STATUS_ROUTE}`]);
        }
        break;
      case RecentOrderType.CustomerChange:
        this.customerDataChangeStateService.lastOrderId = idStr;
        this.router.navigate([`/${CUSTOMER_DATA_CHANGE_ROUTE}/${CUSTOMER_DATA_CHANGE_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.VoucherRedeem:
        this.voucherRedeemStateService.lastOrderId = idStr;
        this.router.navigate([`/${VOUCHER_REDEEM_ROUTE}/${VOUCHER_REDEEM_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.ChargingCardOrder:
        this.chargingCardOrderStateService.lastOrderId = idStr;
        this.router.navigate([`/${CHARGING_CARD_ORDER_ROUTE}/${CHARGING_CARD_ORDER_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.PaymentAgreement:
        this.findPaymentSolutionStateService.lastOrderId = idStr;
        this.router.navigate([`/${FIND_PAYMENT_SOLUTION_ROUTE}/${FIND_PAYMENT_SOLUTION_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.PartialAmountChange:
        this.partialPaymentStateService.lastOrderId = idStr;
        this.router.navigate([`/${INVOICE_PARTIALPAYMENT_CHANGE_RATE_ROUTE}/${INVOICE_PARTIALPAYMENT_CHANGE_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.CAInformationRequest:
        this.caInformationRequestStateService.lastOrderId = idStr;
        this.router.navigate([`/${CLEARING_ACCOUNT_INFORMATION_REQUEST_ROUTE}/${CLEARING_ACCOUNT_INFORMATION_REQUEST_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.Relocation:
        this.relocationStateService.lastOrderId = idStr;
        this.router.navigate([`/${RELOCATION_ROUTE}/${RELOCATION_STATUS_ROUTE}`]);
        break;
      case RecentOrderType.MoveOut:
        this.moveOutStateService.lastOrderId = idStr;
        this.router.navigate([`/${MOVE_OUT_ROUTE}/${MOVE_OUT_STATUS_ROUTE}`]);
        break;
    }
  }

  navigateToInvoiceDataChangeStatus(dataType: DataChangeType): void {
    switch (dataType) {
      case DataChangeType.BankAccountChangeOnly:
        this.router.navigate([`/${SEPA_INVOICE_CHANGE_ROUTE}/${SEPA_INVOICE_CHANGE_STATUS_ROUTE}`]);
        break;
      case DataChangeType.PdfInvoiceChangeOnly:
        this.router.navigate([`/${PDF_INVOICE_CHANGE_ROUTE}/${PDF_INVOICE_CHANGE_STATUS_ROUTE}`]);
        break;
      case DataChangeType.InvoiceAddressChangeOnly:
        this.router.navigate([`/${INVOICE_ADDRESS_CHANGE_ROUTE}/${INVOICE_CHANGE_STATUS_ROUTE}`]);
        break;
      default:
        this.router.navigate([`/${SEPA_INVOICE_CHANGE_ROUTE}/${INVOICE_CHANGE_STATUS_ROUTE}`]);
        break;
    }
  }
}

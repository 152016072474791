import { Component, computed, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IBankDetails, IIBANFormConfig } from '@mwe/models';
import { anyTrue, forCustomerType, removeAllSpaces } from '@mwe/utils';
import { InvoiceDataService, LoggingService } from '@mwe/services';
import { EnvironmentCustomerType } from '@mwe/environment';

@Component({
  selector: 'mwe-iban-form',
  templateUrl: './iban-form.component.html',
})
export class IBANFormComponent implements OnInit {
  @Input() config: IIBANFormConfig;
  @Input() hideIbanLegalInfo = false; // can be removed once VKI is no longer needed
  @Output() formValidating = new EventEmitter<boolean>();
  @Output() formSubmitted = new EventEmitter<IBankDetails>();
  @Output() validationErrors = new EventEmitter<boolean>();
  form: UntypedFormGroup;
  ibanControl: UntypedFormControl;
  ibanControlName = 'mwe-iban-form-iban';
  iban: string;
  isValid = false;
  inputAriaValid: boolean | undefined = undefined;
  isValidating = false;
  error = signal<boolean>(false);
  errorCode = signal<string>(undefined);
  httpPromise: Promise<IBankDetails>;
  errorDescription: Map<string, string> = new Map();
  alertTitleKey = 'error.service.default.title';
  alertMsgKey = 'error.service.default.message';

  ibanErrorKey = computed(() => {
    const code = this.errorCode();
    // if the config has an ibanErrorKey, use it, otherwise compute the key (but register errorCode as dependency first)
    if (this.config.ibanErrorKey) {
      return this.config.ibanErrorKey;
    }
    return code ? this.errorDescription.get(code) : 'ibanForm.iban.error';
  });
  protected iban70XXErrorKey = forCustomerType(c =>
    c === EnvironmentCustomerType.BUSINESS ? 'ibanForm.errors.7001_7019.message_business' : 'ibanForm.errors.7001_7019.message',
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bankAccountService: InvoiceDataService,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    let errorCodes = ['0000', '1004', '1010', '1011', '1012', '1013'];
    errorCodes.forEach(code => {
      this.errorDescription.set(code, `ibanForm.errors.${code}.message`);
    });
    errorCodes = [];
    for (let i = 1; i < 20; i++) {
      errorCodes.push('' + (7000 + i));
    }
    errorCodes.forEach(code => {
      this.errorDescription.set(code, this.iban70XXErrorKey);
    });
    this.setDefaultLabels();
    this.initFormModel();
  }

  async validateForm(): Promise<void> {
    if (!this.isValidating) {
      Object.keys(this.form.controls).forEach(controlName => this.form.controls[controlName].markAsDirty());
      return anyTrue(this.error(), !!this.ibanControl.errors) ? Promise.reject() : Promise.resolve();
    }
    await Promise.all([this.httpPromise])
      .then(() => {
        // sometime on click weiter we need some timeout
        // else it's not moving to next page
        setTimeout(() => {}, 10);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async validate(): Promise<void> {
    const value = this.iban;

    if (anyTrue(this.isValid, this.isValidating)) {
      return;
    }

    if (value?.length > 12) {
      this.emitValidating(true);

      const ibanStr = removeAllSpaces(this.iban);
      this.httpPromise = this.bankAccountService.checkBankDetails(ibanStr, this.config.processId);
      await this.httpPromise
        .then(payload => {
          this.inputAriaValid = true;
          this.resetErrors();
          if (payload) {
            this.validateBankDetails(payload);
          }
        })
        .catch(ex => {
          this.inputAriaValid = false;
          this.resetErrors();
          this.loggingService.logError(ex, `validation failed`);
          if (ex.system === 'IL') {
            if (ex.error?.code) {
              if (this.errorDescription.has(ex.error.code)) {
                this.error.set(true);
                this.errorCode.set(ex.error.code);
                this.emitErrorValues();
                const codeValue = Number(ex.error.code);
                if (codeValue > 7000 && codeValue < 7020) {
                  this.error.set(true);
                }
              } else {
                this.emitErrorValues();
              }
            } else {
              this.emitErrorValues();
            }
          } else {
            this.error.set(true);
            this.errorCode.set('0000');
            this.emitValidating(false);
          }
        });
    } else if (value?.length > 1) {
      this.emitErrorValues();
    }
  }

  onInputIBAN(newValue: string): void {
    this.isValid = false;
    this.iban = newValue;
  }

  validateBankDetails(bankDetails: IBankDetails): void {
    const bankdaten = bankDetails.bankdaten;
    if (bankdaten) {
      bankDetails.iban = removeAllSpaces(this.iban);
      if (bankdaten.bic === '-') {
        bankdaten.bic = '';
      }
      this.isValid = true;
      this.formSubmitted.emit(bankDetails);
      this.emitValidating(false);
    } else {
      this.emitErrorValues();
    }
  }

  private initFormModel(): void {
    this.iban = this.config.iban;
    const group = {};
    this.ibanControl = new UntypedFormControl(this.iban || '', {
      validators: [Validators.required, Validators.minLength(2)],
      updateOn: 'blur',
    });
    group[this.ibanControlName] = this.ibanControl;
    this.form = this.formBuilder.group(group);
  }

  private setDefaultLabels(): void {
    if (!this.config) {
      this.config = {};
    }
    if (!this.config.iban) {
      this.config.iban = '';
    }
    if (!this.config.bic) {
      this.config.bic = '';
    }
    if (!this.config.ibanLabelKey) {
      this.config.ibanLabelKey = 'ibanForm.iban.label';
    }
    if (!this.config.ibanPlaceholderKey) {
      this.config.ibanPlaceholderKey = 'ibanForm.iban.placeholder';
    }
  }

  private emitErrorValues(): void {
    this.error.set(true);
    this.validationErrors.emit(true);
    this.emitValidating(false);
  }

  private emitValidating(validating: boolean): void {
    this.isValidating = validating;
    this.formValidating.emit(this.isValidating);
  }

  private resetErrors(): void {
    this.error.set(false);
    this.errorCode.set(undefined);
    this.isValid = false;
  }
}

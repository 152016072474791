import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DetailsLinkTypeEnum, MOVE_OUT_ASK_IF_RELOCATION_ROUTE, MOVE_OUT_ROUTE, MOVE_OUT_STATUS_ROUTE } from '@mwe/constants';
import { Address, IBankDetails, IERechnungsInfo, ILSystem, MoveOutMapping, Product, Verrechnungskonto, createAddress } from '@mwe/models';
import {
  getAccountCustomerId,
  getAccountOwner,
  getBusinessDataFromPartnerDetail,
  getCategorySequenceDiff,
  getInvoiceAddress,
  getInvoiceEmail,
  getInvoiceRecipient,
  getPersonalDataFromPartnerDetail,
  hasClearingAccountSepaMandant,
  isArrayWithMinOneItem,
  isProductCategoryEmobility,
  isProductCategoryInternet,
  sortProductsEnumCategory,
} from '@mwe/utils';
import { AccountLogic } from '../account/account.logic';
import { AddressGroupService } from '../address/address-group.service';
import { MoveOutStateService } from './move-out-state.service';

@Injectable({
  providedIn: 'root',
})
export class MoveOutLogic {
  stepOrder = ['erechnung', 'sepa', 'moveout'];

  constructor(
    private moveOutStateService: MoveOutStateService,
    private router: Router,
    private addressGroupService: AddressGroupService,
    private accountLogic: AccountLogic,
  ) {}

  get products(): Product[] {
    return this.moveOutStateService.products;
  }

  async startProcessWithoutAddressSelection(products: Product[], address: Address): Promise<void> {
    this.moveOutStateService.products = products;
    this.moveOutStateService.address = address;
    this.moveOutStateService.isAddressSelectionVisible = false;
    await this.addressGroupService.loadAndMapGroupNames([address]);
    this.moveOutStateService.addressDetailsLinkType = address.addressGroup?.detailsLinkType;
    this.router.navigateByUrl(`/${MOVE_OUT_ROUTE}/${MOVE_OUT_ASK_IF_RELOCATION_ROUTE}`);
  }

  startProcessWithAddressSelection(): void {
    this.moveOutStateService.isAddressSelectionVisible = true;

    this.router.navigateByUrl(`/${MOVE_OUT_ROUTE}`);
  }

  canCategoryBeMovedOut(category: string): boolean {
    return !isProductCategoryInternet(category) && !isProductCategoryEmobility(category);
  }

  canProcessNotBeDone(): boolean {
    return (
      this.canAllCategoriesNotBeMovedOut() ||
      (!!this.moveOutStateService.addressDetailsLinkType &&
        this.moveOutStateService.addressDetailsLinkType !== DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR)
    );
  }

  canAllCategoriesNotBeMovedOut(): boolean {
    if (!isArrayWithMinOneItem(this.products)) {
      return true;
    }

    const categories = this.products.map(p => p.category);
    return categories.every(cat => !this.canCategoryBeMovedOut(cat));
  }

  getAllProductsWhichCanBeMovedOut(): Product[] {
    return this.products.filter(p => this.canCategoryBeMovedOut(p.category));
  }

  getAllProductsWhichCanNotBeMovedOut(): Product[] {
    return this.products.filter(p => !this.canCategoryBeMovedOut(p.category));
  }

  createMoveOutMapping(details: Verrechnungskonto[]): MoveOutMapping[] {
    return details.map(detail => {
      const accountNumber = detail.verrechnungskontoId;
      let businessPartner = getAccountOwner(detail.geschaeftspartnerBeziehungen);

      if (detail.systemId === ILSystem.ISX100) {
        const invoiceRecipient = getInvoiceRecipient(detail.geschaeftspartnerBeziehungen);
        if (businessPartner.geschaeftsPartnerId != invoiceRecipient.geschaeftsPartnerId) {
          businessPartner = invoiceRecipient;
        }
      }
      const businessPartnerNumber = businessPartner.geschaeftsPartnerId;
      const products = this.getAllProductsWhichCanBeMovedOut().filter(p => p.accountNumber === accountNumber);
      const customerNumber = products[0].customerNumber;
      const categories = products.map(p => p.category);
      const tariffClasses = products.map(p => p.tariffClasses);
      const fallBackMail = getInvoiceEmail(detail) || this.accountLogic.getAccountEmail();
      const personalData = getPersonalDataFromPartnerDetail(businessPartner, fallBackMail);
      const businessData = getBusinessDataFromPartnerDetail(businessPartner, fallBackMail);
      const invoiceAddress = getInvoiceAddress(detail.geschaeftspartnerBeziehungen);

      return {
        customerNumber,
        accountNumber,
        businessPartnerNumber,
        categories,
        tariffClasses,
        personalData,
        businessData,
        invoiceAddress,
      };
    });
  }

  getMoveOutMappingDetails(details: Verrechnungskonto[], moveOutMapping: MoveOutMapping): Verrechnungskonto {
    return details.find(
      d =>
        getAccountCustomerId(d.geschaeftspartnerBeziehungen) === moveOutMapping.businessPartnerNumber &&
        d.verrechnungskontoId === moveOutMapping.accountNumber,
    );
  }

  wasSepaChanged(bankDetails: IBankDetails, moveOutDetails: Verrechnungskonto): boolean {
    if (bankDetails?.iban) {
      if (hasClearingAccountSepaMandant(moveOutDetails)) {
        return bankDetails.iban !== moveOutDetails.eingangskonto?.iban;
      }
      return true;
    }
    return false;
  }

  wasPDFEmailChanged(eRechnungsInfo: IERechnungsInfo, moveOutDetails: Verrechnungskonto): boolean {
    if (eRechnungsInfo?.isERechnungsEmpfaenger) {
      const email = getInvoiceEmail(moveOutDetails);
      return eRechnungsInfo.rechnungEmail !== email;
    }
    return false;
  }

  sortMoveOutMappingData(moveOutMappingData: MoveOutMapping[]): MoveOutMapping[] {
    moveOutMappingData.forEach(item => sortProductsEnumCategory(item.categories));
    moveOutMappingData.sort((a, b) => getCategorySequenceDiff(a.categories[0], b.categories[0]));
    moveOutMappingData.forEach((item, ix) => (item.orderIdx = ix));
    return moveOutMappingData;
  }

  initMoveOutStatusProcess(address: Address): void {
    this.router.navigate([`/${MOVE_OUT_ROUTE}/${MOVE_OUT_STATUS_ROUTE}`]).then(() => {
      // to prevent state-clear from wizard/ngOnDestroy
      this.moveOutStateService.address = createAddress(address);
    });
  }

  hasFirstBulletError(processStatus: string, mappingData: MoveOutMapping[]): boolean {
    if (!this.hasProcessStatusError(processStatus)) {
      return false;
    }
    const bulletIds = this.getSortedBulletIndices(mappingData);
    const bulletIdIdx = bulletIds.indexOf(this.getBulletIdForProcessingStatus(processStatus));
    return bulletIdIdx === 0;
  }

  hasProcessStatusError(processStatus: string): boolean {
    return processStatus.indexOf('error') > -1;
  }

  getBulletIdForProcessingStatus(processStatus: string): string {
    return processStatus.replace('processing_', '').replace('_error', '');
  }

  getSortedBulletIndices(mappingData: MoveOutMapping[]): string[] {
    const bulletIds: string[] = [];
    let moveOutIdx = 0;
    mappingData.forEach(md => {
      const categories = md.categories;

      if (md.changeERechnung) {
        bulletIds.push('erechnung_' + md.orderIdx);
      }

      if (md.changeSepaMandat) {
        bulletIds.push('sepa_' + md.orderIdx);
      }

      categories.forEach(() => {
        bulletIds.push('moveout_' + moveOutIdx++);
      });
    });
    return this.sortBulletIds(bulletIds);
  }

  sortBulletIds(bulletIds: string[]): string[] {
    return bulletIds.sort((a, b) => {
      const stepA = a.split('_');
      const stepB = b.split('_');
      const sortIxDiff = this.stepOrder.indexOf(stepA[0]) - this.stepOrder.indexOf(stepB[0]);
      if (sortIxDiff === 0) {
        return stepA[1] > stepB[1] ? 1 : -1;
      }
      return sortIxDiff > 0 ? 1 : -1;
    });
  }
}

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Address, IInvoiceDataChangeResponse, InvoiceDataChangeDTO, Product } from '@mwe/models';
import {
  SEPA_INVOICE_CHANGE_ROUTE,
  INVOICE_ADDRESS_CHANGE_ROUTE,
  INVOICE_CHANGE_STATUS_ROUTE,
  ServiceStateEnum,
  SEPA_INVOICE_CHANGE_STATUS_ROUTE,
} from '@mwe/constants';
import { anyTrue, everyTrue, getExtendedHashCode, getRechnungsAdresse, sortAccountCategoriesMapping } from '@mwe/utils';
import {
  AccountLogic,
  ClearingAccountLogic,
  InvoiceDataChangeLogic,
  InvoiceDataChangeService,
  InvoiceDataChangeStateService,
  LoggingService,
} from '@mwe/services';
import { AbstractBulletConfirmationComponent } from '../../confirmation/abstract-bullet-confirmation.component';

@Component({
  selector: 'mwe-invoice-data-change-confirmation',
  templateUrl: './invoice-data-change-confirmation.component.html',
  styles: [],
})
export class InvoiceDataChangeConfirmationComponent
  extends AbstractBulletConfirmationComponent<IInvoiceDataChangeResponse, InvoiceDataChangeDTO>
  implements OnDestroy
{
  @Input() infoMode: true;
  @Output() pushPdfBtnClicked = new EventEmitter<void>();
  successMsgKey = 'invoiceData.change.confirmation.success';
  workInProgressMsgKey = 'invoiceData.change.confirmation.inProgress';
  override defaultErrorMsgKey = 'invoiceData.change.confirmation.error';
  override retryErrorMsgKey = 'invoiceData.change.confirmation.error_retry';

  get products(): Product[] {
    return this.invoiceDataChangeStateService.products;
  }

  get address(): Address {
    return this.invoiceDataChangeStateService.address;
  }

  constructor(
    private invoiceDataChangeStateService: InvoiceDataChangeStateService,
    private invoiceDataChangeService: InvoiceDataChangeService,
    private invoiceDataChangeLogic: InvoiceDataChangeLogic,
    protected override translateService: TranslateService,
    protected override accountLogic: AccountLogic,
    protected override loggingService: LoggingService,
    private router: Router,
    private clearingAccountLogic: ClearingAccountLogic,
  ) {
    super(accountLogic, loggingService, translateService);
  }

  ngOnDestroy(): void {
    if (this.confirmationWasSuccessfully) {
      this.clearingAccountLogic.clearProductDetails();
    }
  }

  override hasAllNeededData(): boolean {
    return everyTrue(
      !!this.address,
      !!this.invoiceDataChangeStateService.accountCategoriesMapping,
      anyTrue(
        !!this.invoiceDataChangeStateService.bankAccountData,
        !!this.invoiceDataChangeStateService.eRechnungsInfo,
        !!this.invoiceDataChangeStateService.invoiceAddress,
      ),
    );
  }

  override setLabelState(label: string, state: string): string {
    let labelState = state;
    if (everyTrue(this.infoMode, state === ServiceStateEnum.SUCCESS)) {
      labelState += '_infoMode';
    }
    return super.setLabelState(label, labelState);
  }

  getConfirmStatus(): Promise<IInvoiceDataChangeResponse> {
    return this.invoiceDataChangeService.confirmStatus(this.submitId);
  }

  updateStateRetriedOnError(retried: boolean): void {
    this.invoiceDataChangeStateService.retriedOnError = retried;
  }

  sendConfirm(): void {
    this.invoiceDataChangeService
      .confirm(this.dataDTO)
      .then(res => {
        this.invoiceDataChangeStateService.submitId = this.submitId = res.submitId;
        this.checkConfirmationStatus();
      })
      .catch(() => {
        this.setError(null);
      });
  }

  override setError(bubbleId: string): void {
    if (this.errorCode !== 7011) {
      super.setError(bubbleId);
    }
  }

  override updateBulletLabelOverwriteMap(responseData?: string): void {
    if (responseData) {
      const responseDataObject = JSON.parse(responseData);
      const emailAlreadySet: string[] = responseDataObject.emailAlreadySet;
      this.dataDTO.mappingData.forEach(md => {
        if (emailAlreadySet && emailAlreadySet.indexOf(md.accountNumber) > -1) {
          this.bulletLabelOverwriteMap.set('erechnung_' + md.orderIdx, {
            state: ServiceStateEnum.SUCCESS,
            label: 'invoiceData.change.confirmation.stepERechnungChange.success_7011',
          });
        }
      });
    }
  }

  async handleLink(targetId: string): Promise<void> {
    const id = (this.invoiceDataChangeStateService.lastOrderId = '' + this.invoiceDataChangeStateService.submitId);

    if (targetId === 'mwe-invoice-data-change-retry') {
      try {
        this.invoiceDataChangeStateService.retriedOnError = true;
        this.retryAllowed = false;
        this.state = ServiceStateEnum.LOADING;
        await this.invoiceDataChangeService.retry(this.submitId, this.dataDTO).then(res => {
          this.checkConfirmationStatus();
        });
      } catch (err) {
        this.setError(null);
      }
    } else if (everyTrue(!this.infoMode, targetId === 'mwe-invoice-data-change-status-sepa')) {
      this.router.navigate([`/${SEPA_INVOICE_CHANGE_ROUTE}/${SEPA_INVOICE_CHANGE_STATUS_ROUTE}`]).then(() => {
        this.invoiceDataChangeStateService.lastOrderId = id;
      });
    } else if (everyTrue(!this.infoMode, targetId === 'mwe-invoice-data-change-status-address')) {
      this.router.navigate([`/${INVOICE_ADDRESS_CHANGE_ROUTE}/${INVOICE_CHANGE_STATUS_ROUTE}`]).then(() => {
        this.invoiceDataChangeStateService.lastOrderId = id;
      });
    }
  }

  override submit(): Promise<void> {
    this.invoiceDataChangeStateService.submitId = null;
    this.router.navigateByUrl('');
    return Promise.reject();
  }

  async initializeDefaultState(): Promise<void> {
    this.setInitialStateValues(this.invoiceDataChangeStateService.submitId, this.invoiceDataChangeStateService.retriedOnError);
    this.dataDTO = this.getDataChangeDTO();
    const changeTypeI18nKey = this.invoiceDataChangeLogic.getI18NChangeType(this.dataDTO);
    await this.loadTranslations(changeTypeI18nKey);
    this.dataDTO.mappingData.forEach(md => {
      const oIx = md.orderIdx;
      const translateValues = {};
      translateValues['categories'] = this.getTranslatedCategories(md.categories);

      if (this.dataDTO.eRechnungsInfo) {
        this.populateMaps(
          md.categories,
          'erechnung_',
          oIx,
          'invoiceData.change.confirmation.stepERechnungChange.title',
          'invoiceData.change.confirmation.stepERechnungChange.inProgress',
        );
      }

      if (this.dataDTO.sepaMandat) {
        this.populateMaps(
          md.categories,
          'sepa_',
          oIx,
          'invoiceData.change.confirmation.stepSepaMandatChange.title',
          'invoiceData.change.confirmation.stepSepaMandatChange.inProgress',
        );
      }

      if (this.dataDTO.rechnungsAdresse) {
        this.populateMaps(
          md.categories,
          'invoiceaddr_',
          oIx,
          'invoiceData.change.confirmation.stepInvoiceAddressChange.title',
          'invoiceData.change.confirmation.stepInvoiceAddressChange.inProgress',
        );

        if (!this.dataDTO.rechnungsAdresse.olavAaa) {
          this.bulletLabelOverwriteMap.set('invoiceaddr_' + oIx, {
            state: ServiceStateEnum.SUCCESS,
            label: 'invoiceData.change.confirmation.stepInvoiceAddressChange.successWithoutOlav',
          });
        }
      }

      this.bulletIds = Array.from(this.bulletStateMap.keys());
      this.currentBulletId = this.bulletIds[0];
    });
  }

  getDataChangeDTO(): InvoiceDataChangeDTO {
    this.invoiceDataChangeStateService.accountCategoriesMapping = sortAccountCategoriesMapping(
      this.invoiceDataChangeStateService.accountCategoriesMapping,
    );
    this.invoiceDataChangeStateService.accountCategoriesMapping.forEach(md => {
      md.changeERechnung = !!this.invoiceDataChangeStateService.eRechnungsInfo;
      md.changeSepaMandat = !!this.invoiceDataChangeStateService.bankAccountData;
      md.changeRechnungsAdresse = !!this.invoiceDataChangeStateService.invoiceAddress;
    });
    return {
      mappingData: this.invoiceDataChangeStateService.accountCategoriesMapping,
      addressCode: getExtendedHashCode(this.address),
      sepaMandat: this.invoiceDataChangeStateService.bankAccountData,
      eRechnungsInfo: this.invoiceDataChangeStateService.eRechnungsInfo,
      rechnungsAdresse: getRechnungsAdresse(
        this.invoiceDataChangeStateService.invoiceAddress,
        this.invoiceDataChangeStateService.invoiceOlavEntry,
      ),
      differentInvoiceRecipient: this.invoiceDataChangeStateService.differentInvoiceRecipient,
      unsupportedCategories: this.invoiceDataChangeStateService.unsupportedCategories,
    };
  }
}

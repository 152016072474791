<label class="card-selectable mb-0" [attr.for]="inputID">
  <div class="row gx-2">
    <div class="col">
      <div class="mb-3">
        <i class="fas fa-leaf text-secondary" style="font-size: 2.5rem"></i>
      </div>
      <p class="text--extra-large mb-1 text-secondary fw-bold">Label</p>
      <div [innerHTML]="inputElem.placeholder | translate"></div>
    </div>
    <div class="col-auto">
      <input
        [attr.id]="inputID"
        [name]="inputElem.name"
        [attr.name]="inputElem.name"
        [formControl]="parentForm.controls[inputElem.name]"
        [ngClass]="{
          'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
          'form-check-readonly': inputElem.isReadonly,
        }"
        class="form-check-input"
        type="radio"
        [value]="inputElem.radioValue"
        [attr.aria-describedby]="ariaDescribedBy"
        [attr.aria-invalid]="!isValid"
        [required]="isRequired || null"
        mweClickPreventDefault
        [ignorePreventClickDefault]="!inputElem.isReadonly"
      />
    </div>
  </div>
</label>

import { Component, input, OnDestroy } from '@angular/core';
import { copyToClipboard } from '@mwe/utils';
import { catchError, of, Subscription, tap } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'mwe-clipboard-copy-button',
  templateUrl: './clipboard-copy-button.component.html',
})
export class ClipboardCopyButtonComponent implements OnDestroy {
  copyData = input<string>();
  copySuccessFull = false;
  private subscription: Subscription;

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  copyText(): void {
    this.subscription = copyToClipboard(this.copyData())
      .pipe(
        tap(() => (this.copySuccessFull = true)),
        delay(2000),
        tap(() => (this.copySuccessFull = false)),
        catchError(() => {
          this.copySuccessFull = false;
          return of(null);
        }),
      )
      .subscribe();
  }
}

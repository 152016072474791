import { ChangeDetectionStrategy, Component, computed, ElementRef, Input, signal } from '@angular/core';

@Component({
  selector: 'mwe-timeline-item',
  templateUrl: './timeline-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineItemComponent {
  private _bubbleIcon = signal<string>('');
  private _bubbleClass = signal<string>('');
  private _index = signal<number>(0);
  private _textIndex = signal<boolean>(true); // instead of using an icon to index if no icon class is set force use text

  useTextIndex = computed(() => this.textIndex || !this.bubbleIcon);

  @Input()
  set bubbleIcon(value: string) {
    this._bubbleIcon.set(value);
  }

  get bubbleIcon(): string {
    return this._bubbleIcon();
  }

  @Input()
  set bubbleClass(value: string) {
    this._bubbleClass.set(value);
  }

  get bubbleClass(): string {
    return this._bubbleClass();
  }

  @Input()
  set index(value: number) {
    this._index.set(value);
  }

  get index(): number {
    return this._index();
  }

  @Input()
  set textIndex(value: boolean) {
    this._textIndex.set(value);
  }

  get textIndex(): boolean {
    return this._textIndex();
  }

  constructor(protected self: ElementRef) {}

  get bubbleIconClass(): string {
    return this.bubbleIcon ? this.bubbleIcon : `fa-${this.index}`; // fallback to index if no icon is set
  }

  get bubbleClassList(): string {
    return this.bubbleClass ? this.bubbleClass : 'bg-secondary-light white'; // fallback to white on blue if no class
  }
}

import { Injectable } from '@angular/core';
import { ProductCategoryEnum } from '@mwe/constants';
import { getProductCategoryEnum, isString } from '@mwe/utils';
import { AddressProducts, Product } from '@mwe/models';

@Injectable({ providedIn: 'root' })
export class ProductsWhiteListLogic {
  private productsCategoryWhiteList: ProductCategoryEnum[] = [];

  constructor() {
    // at the moment we have to use a static whitelist
    // when admintool-story (set whitelist dynamically) is finished, remove this initialization-code
    this.setWhiteList([
      ProductCategoryEnum.STROM,
      ProductCategoryEnum.NACHTSTROM,
      ProductCategoryEnum.STROMRUECK,
      ProductCategoryEnum.GAS,
      ProductCategoryEnum.FERNWAERME,
      ProductCategoryEnum.EMOBILITY,
      ProductCategoryEnum.INTERNET,
      ProductCategoryEnum.IPTV,
      ProductCategoryEnum.VOIP,
    ]);
  }

  setWhiteList(whiteList: ProductCategoryEnum[]): void {
    this.productsCategoryWhiteList = whiteList;
  }

  isInWhiteList(category: string | ProductCategoryEnum): boolean {
    let categoryEnum: ProductCategoryEnum;

    categoryEnum = isString(category) ? getProductCategoryEnum(category as string) : (category as ProductCategoryEnum);

    return this.productsCategoryWhiteList.some(productCategory => productCategory === categoryEnum);
  }

  areSomeInWhiteList(products: Product[]): boolean {
    return products.some(product => {
      return this.isInWhiteList(product.category);
    });
  }

  areAllNotInWhiteList(products: Product[]): boolean {
    return !this.areSomeInWhiteList(products);
  }

  checkIfProductsAreWhiteListed(map: Map<string, AddressProducts>): void {
    map.forEach((value, key) => {
      value.areSomeProductsWhiteListed = this.areSomeInWhiteList(value.products);
    });
  }

  removeNotWhiteListedProducts(products: Product[]): Product[] {
    if (!Array.isArray(products)) {
      return [];
    }

    return products.filter(product => this.isInWhiteList(product.category));
  }
}

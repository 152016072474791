<ng-template #steps>
  <div *ngIf="title()" class="d-flex flex-column justify-center text-center mb-3">
    <ng-content></ng-content>
    <h1 class="h4" [translate]="'summary.whatNext'"></h1>
  </div>
  <mwe-timeline [showLine]="true">
    @for (item of formattedSteps(); track $index; let last = $last) {
      <mwe-timeline-item>
        <h1 class="h5" [translate]="item.title" [translateParams]="item.translationParams"></h1>
        <p class="text-break" *ngFor="let label of item.label" [translate]="label" [translateParams]="item.translationParams"></p>
      </mwe-timeline-item>
    }
  </mwe-timeline>
</ng-template>
@if (asCard()) {
  <mwe-basic-card class="d-block mb-4" *ngIf="formattedSteps()?.length">
    <ng-container *ngTemplateOutlet="steps"></ng-container>
  </mwe-basic-card>
} @else {
  <ng-container *ngTemplateOutlet="steps"></ng-container>
}

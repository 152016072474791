import { Component, input, output } from '@angular/core';

@Component({
  selector: 'mwe-basic-card-clickable',
  templateUrl: './basic-card-clickable.component.html',
})
export class BasicCardClickableComponent {
  cardClicked = output();

  onCardClick() {
    this.cardClicked.emit();
  }
}

import { ChangeDetectionStrategy, Component, input, QueryList, signal, ViewChildren } from '@angular/core';
import { ApprovalFormItem } from '@mwe/models';
import { ApprovalFormItemComponent } from './approval-form-item.component';

@Component({
  selector: 'mwe-approval-form',
  templateUrl: './approval-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalFormComponent {
  // todo use new viewChildren signal when ng-mock is updated and viewChildren is compatibel with MockComponent
  @ViewChildren(ApprovalFormItemComponent) itemRefs: QueryList<ApprovalFormItemComponent>;

  items = input.required<ApprovalFormItem[]>();
  isDirty = signal<boolean>(false);

  markDirty(): void {
    this.isDirty.set(true);
  }

  isFormItemChecked(id: string): boolean {
    return this.getFormItemRef(id)?.isChecked();
  }

  isValid(): boolean {
    const hasSomeInvalidFormItems = this.itemRefs?.some(ref => !ref.isValid());
    return !hasSomeInvalidFormItems;
  }

  private getFormItemRef(id: string): ApprovalFormItemComponent {
    return this.itemRefs.find(ref => ref.item().id === id);
  }
}

<ng-template #listing>
  <div class="row w-100">
    <div class="col-12">
      @for (item of items; track $index; let isLastBlock = $last) {
        <p class="text--big mb-2" data-test="sepa-mandat-products-list-header">
          @if (item?.categories?.length > 0) {
            <b class="text-blue">
              @for (c of item.categories; track $index; let isLastCategory = $last) {
                {{ c | translate }}{{ isLastCategory ? '' : '/' }}
              }
            </b>
          }
        </p>
        <div data-test="sepa-mandat-products-list-body" [ngClass]="{ 'mb-4': !isLastBlock }">
          @for (part of getPartsToShow(item?.isFernwaerme); track $index) {
            <p class="mb-2"><b [translate]="'sepaInvoiceChange.status.sepaInfo.' + part"></b>: {{ item[part] | translate }}</p>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
@if (asCard) {
  <mwe-basic-card [hasBackground]="true" cardTitle="sepaInvoiceChange.status.sepaInfo.title" iconClass="fas fa-credit-card">
    <ng-container *ngTemplateOutlet="listing"></ng-container>
  </mwe-basic-card>
} @else {
  <h6 translate="sepaInvoiceChange.status.sepaInfo.title"></h6>
  <ng-container *ngTemplateOutlet="listing"></ng-container>
}

import { Directive, HostListener, input } from '@angular/core';

@Directive({
  selector: '[mweClickPreventDefault]',
})
export class ClickPreventDefaultDirective {
  ignorePreventClickDefault = input<boolean>(false);

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.ignorePreventClickDefault()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Address, IFormModel, IFormSelect, IOlavEntry } from '@mwe/models';
import { getFromOlavKey } from '@mwe/utils';
import { FormComponent } from '../form/form.component';
import { AddressInputComponent } from './address-input.component';
import { ManualAddressInputComponent } from './manual-address-input.component';
import { CountryLogic } from '@mwe/utils';

@Component({
  selector: 'mwe-international-address-input',
  templateUrl: './international-address-input.component.html',
})
export class InternationalAddressInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(FormComponent) countryFormRef: FormComponent;
  @ViewChild(AddressInputComponent) addressInputRef: AddressInputComponent;
  @ViewChild(ManualAddressInputComponent) manualAddressInputRef: ManualAddressInputComponent;
  @Output() selectionFinishedEvent = new EventEmitter<boolean>();

  @Input() initialOlavEntry: IOlavEntry;
  @Input() initialDataForManualForm: Address;

  useManualAddressForm = false;
  countryFormModel: IFormModel;

  private subscription = new Subscription();
  private countrySelector = 'manual-address-country';

  constructor(private countryLogic: CountryLogic) {}

  ngOnInit(): void {
    this.createFormModel();
    this.checkInitialData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.watchSelectedCountry();
  }

  validate(): boolean {
    this.countryFormRef.fireValidation();

    if (this.countryFormRef.form.invalid) {
      return false;
    }

    if (this.useManualAddressForm) {
      return this.manualAddressInputRef.validate();
    } else {
      return this.addressInputRef.validate();
    }
  }

  getAddress(): Address {
    const address = this.useManualAddressForm
      ? this.manualAddressInputRef.getAddressFromForm()
      : this.getFromOlavKey(this.addressInputRef.selectedOlav);

    if (!address) {
      return null;
    }

    address.country = this.getCountryFormControlValue().value;
    return address;
  }

  getOlavEntry(): IOlavEntry {
    if (this.useManualAddressForm) {
      return undefined;
    }

    return this.addressInputRef.selectedOlav;
  }

  changeToManualAddressForm(): void {
    this.initialDataForManualForm = this.getFromOlavKey(this.addressInputRef.getSelectedOlavWithLatestUserInputForManualAddressInput());
    this.useManualAddressForm = true;
  }

  getCountryFormRef(): FormComponent {
    return this.countryFormRef;
  }

  receiveSelectionFinished($event: boolean): void {
    this.selectionFinishedEvent.emit($event);
  }

  private createFormModel(): void {
    const initialCountryCode = this.initialDataForManualForm?.country || 'AT';

    this.countryFormModel = {
      updateOn: 'blur',
      inputs: [
        {
          name: this.countrySelector,
          initialValue: initialCountryCode,
          validators: [Validators.required],
          labelKey: 'shared.international-address-input.form.country.title',
          validationErrorLabelKey: 'shared.international-address-input.form.country.error',
          validate: true,
          componentType: 'typeahead',
          options: this.countryLogic.getCountryCodeValues(),
          typeaheadOptions: {
            initialSuggestions: this.countryLogic.getCountryCodeInitialSuggestions(),
          },
          updateOn: 'blur',
        },
      ],
    };
  }

  private checkInitialData(): void {
    this.useManualAddressForm = !this.initialOlavEntry && !!this.initialDataForManualForm;
  }

  private watchSelectedCountry(): void {
    const sub = this.getCountryFormRef().form.valueChanges.subscribe(() => {
      const value = this.getCountryFormControlValue()?.value;
      this.useManualAddressForm = value !== 'AT' && !!value;
    });
    this.subscription.add(sub);
  }

  private getCountryFormControlValue(): IFormSelect {
    return this.countryFormRef.form.get(this.countrySelector).value as IFormSelect;
  }

  private getFromOlavKey(olav: IOlavEntry): Address {
    return getFromOlavKey(olav);
  }
}

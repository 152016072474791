import { Component, Input, OnInit } from '@angular/core';
import { InvoiceDataChangeDTO } from '@mwe/models';
import { getProductCategoryLabelKey, isProductCategoryFernwaerme } from '@mwe/utils';

export interface SepaMandatProductsListItem {
  categories: string[];
  payee: string;
  creditorId: string;
  customerNumber: string;
  accountNumber: string;
  mandateReference?: string;
  isFernwaerme: boolean;
  fernwaermePayee: string;
  fernwaermeCreditorId: string;
}

@Component({
  selector: 'mwe-sepa-mandat-listing',
  templateUrl: './sepa-mandat-listing.component.html',
})
export class SepaMandatListingComponent implements OnInit {
  @Input() changeData: InvoiceDataChangeDTO;
  @Input() asCard = false;
  items: SepaMandatProductsListItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.items = this.changeData.mappingData.map(data => {
      return {
        categories: data.categories.map(c => getProductCategoryLabelKey(c)),
        payee: 'sepaInvoiceChange.status.sepaInfo.company.name',
        creditorId: 'sepaInvoiceChange.status.sepaInfo.company.creditorId',
        customerNumber: data.customerNumber,
        accountNumber: data.accountNumber,
        mandateReference: this.getMandateRefernce(data.accountNumber),
        isFernwaerme: !!data.categories.find(c => isProductCategoryFernwaerme(c)),
        fernwaermePayee: 'sepaInvoiceChange.status.sepaInfo.fernwaermeCompany.name',
        fernwaermeCreditorId: 'sepaInvoiceChange.status.sepaInfo.fernwaermeCompany.creditorId',
      } as SepaMandatProductsListItem;
    });
  }

  getPartsToShow(isFernwaerme: boolean) {
    return isFernwaerme
      ? ['fernwaermePayee', 'fernwaermeCreditorId', 'customerNumber', 'mandateReference']
      : ['payee', 'creditorId', 'customerNumber', 'accountNumber', 'mandateReference'];
  }

  private getMandateRefernce(accountNumber: string): string {
    const item = this.changeData.mandatsReferenzMapping.find(i => {
      return i.vertragskontoNummer === accountNumber;
    });

    return item?.mandatsreferenz;
  }
}

@if (!copySuccessFull) {
  <button class="flex-shrink-0 flex-grow-0 btn btn-clean btn-link text-primary" (click)="copyText()">
    <i class="fa fa-copy mr-1" style="left: 0"></i>
    {{ 'shared.clipboardCopy.copy' | translate }}
  </button>
}

@if (copySuccessFull) {
  <button class="flex-shrink-1 flex-grow-0 btn btn-clean btn-link text-success">
    <i class="fa fa-check-circle mr-1" style="left: 0"></i>
    {{ 'shared.clipboardCopy.copySuccess' | translate }}
  </button>
}

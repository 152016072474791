import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import {
  buildStepFromDefinition,
  IdentifyableAsStep,
  StepDefinition,
  StepIdentifier,
  StepInterface,
  StepStateInterface,
  StepText,
  SubmitIdentifier,
} from './backend-process-step.model';
import { ServiceStateEnum } from '@mwe/constants';
import { computed } from '@angular/core';

const initialProcessState: BackendProcessState = {
  steps: new Map<StepInterface, StepStateInterface | undefined>(),
  submitId: undefined,
  _errorCodes: undefined,
  _retryAllowed: undefined,
  _processState: undefined,
};

// The state of a process with its steps
export interface BackendProcessState {
  submitId?: SubmitIdentifier;
  steps: Map<StepInterface, StepStateInterface>; // Steps that are part of the process
  _errorCodes?: Map<StepInterface, number>;
  _retryAllowed?: boolean;
  _processState: ServiceStateEnum;
}

export const BackendProcessStore = signalStore(
  withState(initialProcessState),
  withMethods(store => {
    // Internal Helper methods
    function _internalPatchSteps(
      state,
      stepIds: StepIdentifier[],
      step: Partial<StepInterface> | ((step: StepInterface) => StepInterface),
    ) {
      const steps = Array.from(store.steps().entries());
      stepIds.forEach(stepId => {
        const stepToUpdate = steps.find(([step]) => step.stepId === stepId);
        if (stepToUpdate) {
          // we now need to replace the step with the new one but keep the order of the map
          const index = steps.indexOf(stepToUpdate);
          if (typeof step === 'function') {
            step = step(stepToUpdate[0]);
          }
          steps[index] = [{ ...stepToUpdate[0], ...step }, stepToUpdate[1]];
        }
      });
      return {
        ...state,
        steps: new Map(steps),
      };
    }
    function _internalPatchStates(
      state,
      stepIds: StepIdentifier[],
      stepState: Partial<StepStateInterface> | ((step: StepStateInterface) => StepStateInterface),
    ) {
      const steps = Array.from(store.steps().entries());
      stepIds.forEach(stepId => {
        const stepToUpdate = steps.find(([step]) => step.stepId === stepId);
        if (stepToUpdate) {
          // we now need to replace the step with the new one but keep the order of the map
          const index = steps.indexOf(stepToUpdate);
          if (typeof stepState === 'function') {
            stepState = stepState(stepToUpdate[1]);
          }
          steps[index] = [stepToUpdate[0], { ...stepToUpdate[1], ...stepState }];
        }
      });
      return {
        ...state,
        steps: new Map(steps),
      };
    }
    function _normalizeIdentifier(identifier: IdentifyableAsStep): StepIdentifier[] {
      if (Array.isArray(identifier)) {
        return identifier.map(i => (typeof i === 'string' ? i : i.stepId));
      }
      return [typeof identifier === 'string' ? identifier : identifier.stepId];
    }

    // Step handling methods
    /**
     * Appends a step to the process. Optionally with a state
     * @param step Step to append (required: stepId, title)
     * @param withState Optional - Initial state of the step in form of { state: ServiceStateEnum, errorCode?: number, retryAllowed?: boolean }.
     * Default is { state: ServiceStateEnum.INIT }
     */
    function appendStep(step: StepDefinition, withState: StepStateInterface | ServiceStateEnum = { state: ServiceStateEnum.INIT }) {
      const stepInstance = buildStepFromDefinition(step);
      patchState(store, state => {
        const newSteps = new Map(state.steps);
        if (typeof withState === 'string') {
          withState = { state: withState };
        }
        if (Array.from(state.steps.keys()).find(s => s.stepId === stepInstance.stepId)) {
          throw new Error(`Step with id ${stepInstance.stepId} already exists`);
        }
        newSteps.set(stepInstance, withState); // add a step. Optionally with a state
        // for info steps we use a numerical icon so we index them
        stepInstance.iconMap.set(ServiceStateEnum.INFO, `fa-${store.steps().size + 1}`);
        return {
          ...state,
          steps: newSteps,
        };
      });
      return null;
    }
    /**
     * Removes a step from the process. Will reindex the ServiceStateEnum.INFO icons per default
     * @param step Step to remove (stepId or StepInterface)
     * @param reindexIcons Optional - Reindex the ServiceStateEnum.INFO icons after removing the step. Default is true
     */
    function removeStep(step: StepIdentifier, reindexIcons = true) {
      step = _normalizeIdentifier(step)[0];
      patchState(store, state => {
        const existingStep = Array.from(store.steps().keys()).find(s => s.stepId === step);
        if (!existingStep) {
          return state;
        }
        state.steps.delete(existingStep);
        if (reindexIcons) {
          // reindex the steps
          let index = 0;
          for (const [s] of store.steps()) {
            s.iconMap.set(ServiceStateEnum.INFO, `fa-${index + 1}`);
            index++;
          }
        }
        return {
          ...state,
          steps: new Map(state.steps),
        };
      });
    }
    function patchStep(stepId: IdentifyableAsStep, step: Partial<StepInterface>) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => {
        return _internalPatchSteps(state, Array.isArray(stepId) ? stepId : [stepId], step);
      });
    }
    function setStepState(stepId: IdentifyableAsStep, stepState: StepStateInterface) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => {
        return _internalPatchStates(state, Array.isArray(stepId) ? stepId : [stepId], stepState);
      });
    }
    function setStepTitle(stepId: IdentifyableAsStep, title: string) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => _internalPatchSteps(state, stepId, { title }));
    }
    function setStepLabel(stepId: IdentifyableAsStep, label: string | string[]) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => _internalPatchSteps(state, stepId, { label }));
    }
    function setStepTranslationParams(stepId: IdentifyableAsStep, translationParams: object) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => _internalPatchSteps(state, stepId, { translationParams }));
    }
    function patchStepIconMap(stepId: IdentifyableAsStep, iconMap: Map<ServiceStateEnum, string>) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => {
        return _internalPatchSteps(state, Array.isArray(stepId) ? stepId : [stepId], step => {
          // merge the iconMap with the existing one prefering the new one
          return { ...step, iconMap: new Map([...step.iconMap, ...iconMap]) };
        });
      });
    }
    function patchStepClassMap(stepId: IdentifyableAsStep, classMap: Map<ServiceStateEnum, string>) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => {
        return _internalPatchSteps(state, Array.isArray(stepId) ? stepId : [stepId], step => {
          // merge the classMap with the existing one prefering the new one
          return { ...step, classMap: new Map([...step.classMap, ...classMap]) };
        });
      });
    }
    function patchStepTextMap(stepId: IdentifyableAsStep, textMap: Map<ServiceStateEnum, Partial<StepText>>) {
      stepId = _normalizeIdentifier(stepId);
      patchState(store, state => {
        return _internalPatchSteps(state, Array.isArray(stepId) ? stepId : [stepId], step => {
          // merge the textMap with the existing one prefering the new one
          return { ...step, textMap: new Map([...step.textMap, ...textMap]) };
        });
      });
    }
    function getStepById(stepId: IdentifyableAsStep): StepInterface | undefined {
      stepId = _normalizeIdentifier(stepId)[0];
      return Array.from(store.steps().keys()).find(s => s.stepId === stepId);
    }
    function getStepsBefore(stepId: IdentifyableAsStep, include = false): StepInterface[] {
      stepId = _normalizeIdentifier(stepId)[0];
      const steps = Array.from(store.steps().keys());
      const index = steps.findIndex(s => s.stepId === stepId);
      return steps.slice(0, include ? index + 1 : index);
    }
    function getStepsAfter(stepId: IdentifyableAsStep, include = false): StepInterface[] {
      stepId = _normalizeIdentifier(stepId)[0];
      const steps = Array.from(store.steps().keys());
      const index = steps.findIndex(s => s.stepId === stepId);
      return steps.slice(include ? index : index + 1);
    }
    function getSteps(): Array<StepInterface> {
      return Array.from(store.steps().keys());
    }
    // Process handling methods
    function setSubmitId(submitId: SubmitIdentifier) {
      patchState(store, state => ({
        ...state,
        submitId,
      }));
    }
    // Override methods
    function overrideProcessState(processState: ServiceStateEnum) {
      patchState(store, state => ({
        ...state,
        _processState: processState,
      }));
    }
    function overrideRetryAllowed(retryAllowed: boolean | undefined) {
      patchState(store, state => ({
        ...state,
        _retryAllowed: retryAllowed,
      }));
    }
    function overrideErrorCodes(errorCodes: Map<StepInterface, number> | undefined) {
      patchState(store, state => ({
        ...state,
        _errorCodes: errorCodes,
      }));
    }
    function initializeWithState(processState: BackendProcessState) {
      patchState(store, state => ({
        ...state,
        ...processState,
      }));
    }
    return {
      appendStep,
      removeStep,
      patchStep,
      setStepState,
      setStepTitle,
      setStepLabel,
      setStepTranslationParams,
      patchStepIconMap,
      patchStepClassMap,
      patchStepTextMap,
      getStepById,
      getStepsBefore,
      getStepsAfter,
      getSteps,
      setSubmitId,
      overrideProcessState,
      overrideRetryAllowed,
      overrideErrorCodes,
      initializeWithState,
    };
  }),
  withComputed(store => ({
    retryAllowed: computed(() => {
      // check all steps states if retry is allowed
      return store._retryAllowed() !== undefined
        ? store._retryAllowed()
        : Array.from(store.steps().entries()).some(([, stepState]) => stepState.retryAllowed);
    }),
    errorCodes: computed<Map<StepInterface, number>>(() => {
      const errorCodes = new Map<StepInterface, number>();
      if (store._errorCodes() !== undefined) {
        return errorCodes;
      }
      store.steps().forEach((stepState, step) => {
        if (stepState?.errorCode) {
          errorCodes.set(step, stepState.errorCode);
        }
      });
      return errorCodes;
    }),
    processState: computed<ServiceStateEnum>(() => {
      const steps = store.steps(); // trigger the steps signal so that the computed value is recalculated on change
      if (store._processState() !== undefined) {
        return store._processState(); // override the process state if set
      }
      const stepStates = Array.from(steps.values())
        .map(stepState => stepState?.state)
        .filter(s => s);
      // if no step has a state (or no steps are defined), the process is in state INIT
      if (stepStates.length === 0 || stepStates.every(s => s === ServiceStateEnum.INIT)) {
        return ServiceStateEnum.INIT;
      }
      // if at least one step is in state ERROR or FAILED, the process is in state ERROR
      if (stepStates.includes(ServiceStateEnum.ERROR) || stepStates.includes(ServiceStateEnum.FAILED)) {
        return ServiceStateEnum.ERROR;
      }
      // if at least one step is in state LOADING, the process is in state LOADING or INIT
      if (stepStates.includes(ServiceStateEnum.LOADING) || stepStates.includes(ServiceStateEnum.INIT)) {
        return ServiceStateEnum.LOADING;
      }
      return ServiceStateEnum.SUCCESS;
    }),
  })),
);
export type BackendProcessStore = InstanceType<typeof BackendProcessStore>;

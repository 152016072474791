import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Address, Product, createAddress } from '@mwe/models';

const productKey = 'productDetails_product';
const groupProductsKey = 'productDetails_groupProducts';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsStateService {
  keepStorageOnce = false;

  constructor(private storage: SessionStorageService) {}

  @SessionStorage(productKey)
  private _product: Product;

  get product(): Product {
    return this._product;
  }

  set product(product: Product) {
    this._product = product;
  }

  @SessionStorage(groupProductsKey)
  private _groupProducts: Product[];

  get groupProducts(): Product[] {
    return this._groupProducts;
  }

  set groupProducts(groupProducts: Product[]) {
    this._groupProducts = groupProducts;
  }

  get address(): Address {
    return createAddress(this.groupProducts[0].address);
  }

  clearData(): void {
    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(productKey);
    this.storage.clear(groupProductsKey);
  }
}

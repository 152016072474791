import {
  CommonMappingData,
  IOrderDetailsInfo,
  IProduktAuswahlDetails,
  isProductCategory as modelUtils_isProductCategory,
  Product,
  Rechnung,
  RechnungDetails,
  Sparte,
} from '@mwe/models';
import { ProductCategoryEnum } from '@mwe/constants';
import { TranslateService } from '@ngx-translate/core';
import { isArrayWithMinOneItem } from '../common/mwe-util';
import { lastValueFrom } from 'rxjs';

export const CATEGORY_SORT_SEQUENCE = {
  [ProductCategoryEnum.STROM]: 1,
  [ProductCategoryEnum.NACHTSTROM]: 2,
  [ProductCategoryEnum.STROMRUECK]: 3,
  [ProductCategoryEnum.GAS]: 4,
  [ProductCategoryEnum.FERNWAERME]: 5,
  [ProductCategoryEnum.EMOBILITY]: 6,
  [ProductCategoryEnum.INTERNET]: 7,
  [ProductCategoryEnum.IPTV]: 8,
  [ProductCategoryEnum.VOIP]: 9,
};
export const getProductCategoryLabelKey = (category: string): string => {
  return 'products.category.' + category.toLowerCase();
};
export const getProductCategoryEnum = (value: string): ProductCategoryEnum => {
  return ProductCategoryEnum[value?.toUpperCase()];
};
export const isSameCategory = (cat1: string, cat2: string): boolean => {
  return cat1?.toLowerCase() === cat2?.toLowerCase();
};
export const containsProductCategory = (value: string, categoryEnums: ProductCategoryEnum[]): boolean => {
  return categoryEnums?.some(category => modelUtils_isProductCategory(value, category));
};
export const containsSparte = (value: string, sparte: Sparte[]): boolean => {
  return sparte.some(s => s.toLowerCase() === value.toLowerCase());
};
export const isProductCategory = (value: string, categoryEnum: ProductCategoryEnum): boolean => {
  return modelUtils_isProductCategory(value, categoryEnum);
};
export const isProductCategoryStromOrGas = (value: string): boolean => {
  return [ProductCategoryEnum.STROM, ProductCategoryEnum.GAS, ProductCategoryEnum.NACHTSTROM, ProductCategoryEnum.STROMRUECK].some(
    category => modelUtils_isProductCategory(value, category),
  );
};
export const isProductStrom = (product: Product): boolean => {
  return isProductCategoryStrom(product?.category);
};
export const isProductCategoryStrom = (value: string): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.STROM);
};
export const isProductGas = (product: Product): boolean => {
  return isProductCategoryGas(product?.category);
};
export const isProductCategoryGas = (value: string): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.GAS);
};
export const isProductStromOrGasOnly = (product: Product): boolean => {
  return isProductStrom(product) || isProductGas(product);
};
export const isProductFernwaerme = (product: Product): boolean => {
  return isProductCategoryFernwaerme(product?.category);
};
export const isProductCategoryFernwaerme = (value: string): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.FERNWAERME);
};
export const isProductEmobility = (product: Product): boolean => {
  return isProductCategoryEmobility(product?.category);
};
export const isProductCategoryEmobility = (value: string | Sparte): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.EMOBILITY);
};
export const isProductNachtStrom = (product: Product): boolean => {
  return isProductCategoryNachtstrom(product.category);
};
export const isProductCategoryNachtstrom = (value: string): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.NACHTSTROM);
};
export const getProductCategories = (products: Product[]): string[] => {
  return products.map(product => product.category);
};
export const isProductStromRueck = (product: Product): boolean => {
  return isProductCategoryStromRueck(product.category);
};
export const isProductCategoryStromRueck = (value: string): boolean => {
  return modelUtils_isProductCategory(value, ProductCategoryEnum.STROMRUECK);
};
export const isProductInternet = (product: Product): boolean => {
  return isProductCategoryInternet(product.category);
};
export const isProductCategoryInternet = (value: string): boolean => {
  return [ProductCategoryEnum.INTERNET, ProductCategoryEnum.IPTV, ProductCategoryEnum.VOIP].some(category =>
    modelUtils_isProductCategory(value, category),
  );
};
export const containsFernwaerme = (categories: string[]): boolean => {
  if (!isArrayWithMinOneItem(categories)) {
    return false;
  }

  return categories.some(category => isProductCategoryFernwaerme(category));
};
export const containsFernwaermeProducts = (products: Product[]): boolean => {
  return containsFernwaerme(products?.map(p => p.category));
};
export const filterStromGasWaermeProducts = (products: Product[]): Product[] => {
  if (!isArrayWithMinOneItem(products)) {
    return [];
  }

  return products.filter(p => isProductCategoryStromOrGas(p.category) || isProductCategoryFernwaerme(p.category));
};
export const containsStromGasOrWaermeProducts = (products: Product[]): boolean => {
  return containsStromOrGasProducts(products) || containsFernwaermeProducts(products);
};
export const containsStromOrGasProducts = (products: Product[]): boolean => {
  return containsStromOrGasCategory(products?.map(p => p.category));
};
export const containsStromOrGasCategory = (categories: string[]): boolean => {
  if (!isArrayWithMinOneItem(categories)) {
    return false;
  }

  return categories.some(category => isProductCategoryStromOrGas(category));
};
export const hasOnlyStromOrGasCategoryWithoutStromRueck = (categories: string[]): boolean => {
  if (!isArrayWithMinOneItem(categories)) {
    return false;
  }

  return categories.every(category => {
    return isProductCategoryStromOrGas(category) && !isProductCategoryStromRueck(category);
  });
};
export const containsStromProducts = (products: Product[]): boolean => {
  if (!isArrayWithMinOneItem(products)) {
    return false;
  }

  return products.map(p => p.category).some(c => isProductCategoryStrom(c));
};
export const containsGasProducts = (products: Product[]): boolean => {
  if (!isArrayWithMinOneItem(products)) {
    return false;
  }

  return products.map(p => p.category).some(c => isProductCategoryGas(c));
};
export const containsStromOrGasOnlyProducts = (products: Product[]): boolean => {
  if (!isArrayWithMinOneItem(products)) {
    return false;
  }

  return products.some(p => isProductStromOrGasOnly(p));
};
export const containsEmobilityOrInternet = (categories: string[]): boolean => {
  if (!isArrayWithMinOneItem(categories)) {
    return false;
  }

  return categories.some(category => isProductCategoryEmobilityOrInternet(category));
};
export const isProductCategoryEmobilityOrInternet = (value: string): boolean => {
  return isProductCategoryEmobility(value) || isProductCategoryInternet(value);
};
export const getFormattedCategory = (category: string): string => {
  return category.charAt(0).toLocaleUpperCase() + category.substring(1).toLocaleLowerCase();
};
export const getCategorySequence = (category: string): number => {
  return CATEGORY_SORT_SEQUENCE[ProductCategoryEnum[category.toUpperCase()]] || 1000;
};
export const getCategorySequenceDiff = (categoryA: string, categoryB: string): number => {
  const ordinalDiff = getCategorySequence(categoryA) - getCategorySequence(categoryB);
  return ordinalDiff === 0 ? 0 : ordinalDiff / Math.abs(ordinalDiff);
};
export const isEqualCategoryString = (categoryA: string, categoryB: string): boolean => {
  return categoryA && categoryB ? categoryA.toLowerCase() === categoryB.toLowerCase() : false;
};
export const sortProductsByCategory = (products: Product[] | IProduktAuswahlDetails[] | IOrderDetailsInfo[]) => {
  products.sort((a, b) => {
    if (a.category === b.category) {
      return a.anlageId < b.anlageId ? -1 : 1;
    } else {
      return getCategorySequence(a.category) > getCategorySequence(b.category) ? 1 : -1;
    }
  });
};
export const sortProductsEnumCategory = (categories: string[]) => {
  categories.sort((a, b) => (getCategorySequence(a) > getCategorySequence(b) ? 1 : -1));
};
export const getInvoiceCategories = (invoiceData: Rechnung | RechnungDetails): string[] => {
  if ('produkt' in invoiceData) {
    return (invoiceData as RechnungDetails).produkt.map(p => p.sparte);
  } else {
    return (invoiceData as Rechnung).sparte;
  }
};
export const translateCategory = (category: string, translateService: TranslateService): Promise<string> => {
  if (
    [
      ProductCategoryEnum.FERNWAERME,
      ProductCategoryEnum.STROMRUECK,
      ProductCategoryEnum.EMOBILITY,
      ProductCategoryEnum.IPTV,
      ProductCategoryEnum.VOIP,
    ].includes(ProductCategoryEnum[category.toUpperCase()])
  ) {
    return lastValueFrom(translateService.get(`products.category.${category.toLowerCase()}`));
  }
  return Promise.resolve(category);
};
export const translateCategoryInstant = (category: string, translateService: TranslateService): string => {
  return translateService.instant(`products.category.${category.toLowerCase()}`);
};
export const sortAccountCategoriesMapping = (accountCategoriesMapping: CommonMappingData[]): CommonMappingData[] => {
  accountCategoriesMapping.forEach(item => sortProductsEnumCategory(item.categories));
  accountCategoriesMapping.sort((a, b) => getCategorySequenceDiff(a.categories[0], b.categories[0]));
  accountCategoriesMapping.forEach((item, ix) => (item.orderIdx = ix));
  return accountCategoriesMapping;
};

export const getSystemIDForCategory = (category: string): string => {
  if (isProductCategoryStromOrGas(category)) {
    return 'SGX200';
  } else if (isProductCategoryFernwaerme(category)) {
    return 'ISX100';
  } else {
    return null;
  }
};

export const isSystemIDStromOrGas = (systemId: string): boolean => {
  return systemId === 'SGX200';
};

export const isSystemIDFernwaerme = (systemId: string): boolean => {
  return systemId === 'ISX100';
};

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Address,
  IBankDetails,
  IERechnungsInfo,
  IFormModel,
  IIBANFormConfig,
  IInvoiceConfig,
  IOlavEntry,
  IVertragskonto,
  RECHNUNGSVERSAND_ERECHNUNG,
  RECHNUNGSVERSAND_POST,
  ZAHLUNGSMETHODE_BANKEINZUG,
  ZAHLUNGSMETHODE_ZAHLSCHEIN,
} from '@mwe/models';
import { InternationalAddressInputComponent } from '../../address-input/international-address-input.component';
import { IBANFormComponent } from '../../iban-form/iban-form.component';
import { Validators } from '@angular/forms';
import { equalEmailValidator, getAddressGroupFormatted, getEmailValidatorWithRegExp } from '@mwe/utils';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'mwe-invoice-information',
  templateUrl: './invoice-information.component.html',
  styles: [],
})
export class InvoiceInformationComponent implements OnInit, AfterViewInit {
  @ViewChild(IBANFormComponent, { static: false }) ibanFormRef: IBANFormComponent;
  @ViewChild('emailForm', { static: false }) emailFormRef: FormComponent;
  @ViewChild('paymentForm', { static: false }) paymentFormRef: FormComponent;
  @ViewChild('invoiceForm', { static: false }) invoiceFormRef: FormComponent;
  @ViewChild(InternationalAddressInputComponent, { static: false }) internationalAddressInputRef: InternationalAddressInputComponent;

  @Input() invoiceInfoConfig: IInvoiceConfig;
  @Input() isTitleVisible = true;
  @Input() isSepaOrZahlscheinSliderVisible: boolean | 'readonly' = true;
  @Input() isERechnungVisible = true;
  @Input() isERechnungSliderVisible = true;
  @Input() isInternationalAddressInputVisible = false;
  @Input() newInvoiceAddressTitle = 'invoiceInformation.newInvoiceAddress.title';
  @Input() allowEmailChange = true;

  @Output() onSetInvoiceData = new EventEmitter<IVertragskonto>();
  @Output() onSetErechnungData = new EventEmitter<IERechnungsInfo>();
  @Output() onIBANFormSuccess = new EventEmitter<IBankDetails>();
  @Output() onIBANFormError = new EventEmitter<any>();

  ibanConfig: IIBANFormConfig;
  erechnungData: IERechnungsInfo;
  invoiceData: IVertragskonto;

  toggleInvoiceLabel: string;
  toggleERechnungLabel: string;
  address: string;
  overrideEmailAddress?: string = undefined;

  invoiceSEPA = true;
  erechnungEnabled = true;

  isSepaPflicht = false;
  isERechnungsPflicht = false;

  sepaTitle = 'invoiceInformation.invoiceHow.title';
  eRechnungTitle = 'invoiceInformation.erechnung.title';
  toggleOnInvoiceKey = 'invoiceInformation.invoiceHow.toggleSepa.on';
  toggleOffInvoiceKey = 'invoiceInformation.invoiceHow.toggleSepa.off';
  toggleOnERechnungKey = 'invoiceInformation.erechnung.toggleERechnung.on';
  toggleOffERechnungKey = 'invoiceInformation.erechnung.toggleERechnung.off';
  eRechnungAddressChangeKey = 'invoiceInformation.erechnung.address-change-info';

  addressChangeWarningPlaceholders: Map<string, string> = new Map<string, string>();

  paymentSelectionForm: IFormModel;
  paymentSelectionFormValid: boolean;
  invoiceSelectionForm: IFormModel;
  invoiceSelectionFormValid: boolean;
  emailAddressForm: IFormModel;
  emailAddressFormValid: boolean;
  readonlyAddressForm: IFormModel;

  constructor() {}

  get shouldShowEmailChangeForm(): boolean {
    return this.allowEmailChange && (this.erechnungEnabled || this.isERechnungsPflicht);
  }

  get paymentOptions(): string[] {
    if (this.isSepaPflicht) return [ZAHLUNGSMETHODE_BANKEINZUG];
    return [ZAHLUNGSMETHODE_BANKEINZUG, ZAHLUNGSMETHODE_ZAHLSCHEIN];
  }

  get invoiceOptions(): string[] {
    const options = [RECHNUNGSVERSAND_POST];
    if (this.isERechnungsPflicht) return [RECHNUNGSVERSAND_ERECHNUNG];
    if (this.isERechnungVisible) options.push(RECHNUNGSVERSAND_ERECHNUNG);
    return options;
  }

  get showPaymentSelectForm(): boolean {
    return this.paymentOptions.length > 1 && this.isSepaOrZahlscheinSliderVisible === true;
  }

  get showInvoiceSelectForm(): boolean {
    return this.invoiceOptions.length > 1 && this.isERechnungSliderVisible;
  }

  get showInvoiceAddressChangeWarning(): boolean {
    return this.erechnungEnabled && this.invoiceInfoConfig.invoiceAddressChangesTo && !this.isERechnungsPflicht;
  }

  ngOnInit(): void {
    this.initConfigData();
    this.initInvoiceForm();
    this.initERechnungForm();
    this.initEmailAddressForm();
    this.initReadonlyAddressForm();
  }

  ngAfterViewInit(): void {
    if (this.isInternationalAddressInputVisible) {
      this.checkAddress();
    }
  }

  async validate(): Promise<void> {
    const validationResults: boolean[] = [];
    if (this.showPaymentSelectForm) {
      this.paymentFormRef.fireValidation();
      validationResults.push(this.paymentFormRef.isValid());
    }
    if (this.showInvoiceSelectForm) {
      this.invoiceFormRef.fireValidation();
      validationResults.push(this.invoiceFormRef.isValid());
    }
    if (this.shouldShowEmailChangeForm) {
      this.emailFormRef.fireValidation();
      validationResults.push(this.emailFormRef.isValid());
    }
    if (this.isInternationalAddressInputVisible) {
      validationResults.push(this.validateAddress());
    }
    if (this.invoiceSEPA) {
      await this.ibanFormRef.validateForm();
    }
    if (!validationResults.every(e => e)) {
      throw new Error('Form is not valid');
    }
  }

  initConfigData(): void {
    this.toggleInvoiceLabel = this.toggleOnInvoiceKey;
    this.toggleERechnungLabel = this.toggleOnERechnungKey;
    this.invoiceData = this.invoiceInfoConfig.invoiceData;
    this.erechnungData = this.invoiceInfoConfig.erechnungData;
    this.address = this.invoiceInfoConfig.address;
    this.isERechnungsPflicht = this.invoiceInfoConfig.eRechnungMandatory;
    this.isSepaPflicht = this.invoiceInfoConfig.invoiceMandatory;
  }

  initERechnungForm(): void {
    if (!this.erechnungData) {
      this.erechnungData = { isERechnungsEmpfaenger: true, rechnungEmail: null };
    }

    if (this.isERechnungsPflicht) {
      this.onToggleERechnung(true);
    }

    this.erechnungData.rechnungEmail = this.invoiceInfoConfig.rechnungsMail;

    this.erechnungEnabled = this.erechnungData.isERechnungsEmpfaenger;
    this.toggleERechnungLabel = this.erechnungEnabled ? this.toggleOnERechnungKey : this.toggleOffERechnungKey;
    this.addressChangeWarningPlaceholders.set('contact', this.invoiceInfoConfig.invoiceAddressChangesTo);
    this.initInvoiceSelectionForm(this.erechnungEnabled ? 'eRechnung' : 'Post');
  }

  checkAddress(): void {
    this.address = getAddressGroupFormatted(this.getAddress(), true);
    this.initReadonlyAddressForm();
  }

  onPaymentFormChange({ paymentMethod }): void {
    this.showPaymentSelectForm && this.onToggleIBAN(paymentMethod === ZAHLUNGSMETHODE_BANKEINZUG);
  }

  onInvoiceFormChange({ invoiceMethod }): void {
    this.showInvoiceSelectForm && this.onToggleERechnung(invoiceMethod === 'eRechnung');
  }

  onEmailFormChange({ email }, isValid: boolean): void {
    this.emailAddressFormValid = isValid;
    this.overrideEmailAddress = isValid ? email : undefined;
    if (isValid) {
      this.onSetErechnungData.emit({
        ...this.erechnungData,
        rechnungEmail: this.overrideEmailAddress ? this.overrideEmailAddress : this.erechnungData.rechnungEmail,
      });
    }
  }

  onToggleIBAN(checked: boolean, init?: boolean): void {
    this.invoiceSEPA = checked;
    this.toggleInvoiceLabel = this.invoiceSEPA ? this.toggleOnInvoiceKey : this.toggleOffInvoiceKey;
    if (!this.invoiceSEPA) {
      this.invoiceData.zahlungsmethode = ZAHLUNGSMETHODE_ZAHLSCHEIN;
      this.invoiceData.iban = null;
      this.invoiceData.bic = null;
    } else {
      this.invoiceData.zahlungsmethode = ZAHLUNGSMETHODE_BANKEINZUG;
    }
    this.onSetInvoiceData.emit(this.invoiceData);
  }

  onToggleERechnung(checked: boolean): void {
    this.erechnungEnabled = checked;
    this.toggleERechnungLabel = this.erechnungEnabled ? this.toggleOnERechnungKey : this.toggleOffERechnungKey;
    this.erechnungData.isERechnungsEmpfaenger = checked;
    this.onSetErechnungData.emit({
      ...this.erechnungData,
      rechnungEmail: this.overrideEmailAddress ? this.overrideEmailAddress : this.erechnungData.rechnungEmail,
    });
  }

  ibanFormError(data?: any): void {
    this.invoiceData.iban = null;
    this.onIBANFormError.emit(data);
  }

  ibanFormSubmitted(data: IBankDetails): void {
    this.invoiceData.iban = data.iban;
    this.onIBANFormSuccess.emit(data);
  }

  async validateForm(): Promise<void> {
    await this.ibanFormRef?.validateForm();
  }

  validateAddress(): boolean {
    return this.internationalAddressInputRef?.validate();
  }

  getAddress(): Address {
    return this.internationalAddressInputRef?.getAddress();
  }

  getOlavEntry(): IOlavEntry {
    return this.internationalAddressInputRef?.getOlavEntry();
  }

  private initInvoiceForm(): void {
    if (this.invoiceData) {
      this.invoiceSEPA = this.invoiceData.zahlungsmethode === ZAHLUNGSMETHODE_BANKEINZUG;
    } else {
      this.invoiceData = { vertragskontoId: null, blz: null, kontonummer: null, zahlungsmethode: null, iban: null };
    }
    if (this.isSepaPflicht || this.invoiceSEPA) {
      this.onToggleIBAN(true, true);
    }
    this.toggleInvoiceLabel = this.invoiceSEPA ? this.toggleOnInvoiceKey : this.toggleOffInvoiceKey;
    this.ibanConfig = { iban: this.invoiceData.iban };
    this.initPaymentSelectionForm(this.invoiceSEPA ? ZAHLUNGSMETHODE_BANKEINZUG : ZAHLUNGSMETHODE_ZAHLSCHEIN);
  }

  private initEmailAddressForm(): void {
    this.emailAddressForm = {
      inputs: [
        {
          dataType: 'email',
          name: 'email',
          initialValue: this.erechnungData?.rechnungEmail || '',
          validators: [Validators.required, Validators.email, getEmailValidatorWithRegExp()],
          labelKey: 'invoiceInformation.email.label',
          validate: true,
          validationErrorLabelKey: 'invoiceInformation.email.validationError',
          componentType: 'emailToConfirm',
          updateOn: 'change',
          isReadonly: !this.shouldShowEmailChangeForm,
          emailConfirmationOptions: {
            referenceEmailIsInitialValue: true,
          },
        },
      ],
    };
    if (this.shouldShowEmailChangeForm) {
      this.emailAddressForm.inputs.push({
        dataType: 'email',
        name: 'emailConfirmation',
        initialValue: '',
        validators: [Validators.required, equalEmailValidator('email')],
        labelKey: 'invoiceInformation.email.confirmationLabel',
        validate: true,
        validationErrorLabelKey: 'invoiceInformation.email.confirmationError',
        avoidCopyPaste: true,
        isHidden: true,
        componentType: 'emailConfirmationField',
        updateOn: 'change',
      });
    }
  }

  private initReadonlyAddressForm(): void {
    if (!this.address) {
      return;
    }
    this.readonlyAddressForm = {
      inputs: [
        {
          name: 'address',
          initialValue: this.address,
          labelKey: 'invoiceInformation.invoiceOptions.postal',
          isReadonly: true,
        },
      ],
    };
  }

  private initPaymentSelectionForm(initialValue: string): void {
    if (this.isSepaOrZahlscheinSliderVisible === 'readonly') {
      this.paymentSelectionForm = {
        inputs: [
          {
            name: 'paymentMethod',
            initialValue,
            validators: [Validators.required],
            labelKey: 'invoiceInformation.paymentOptions.title',
            isReadonly: true,
          },
        ],
      };
    } else {
      this.paymentSelectionForm = {
        inputs: [
          {
            name: 'paymentMethod',
            initialValue,
            options: this.paymentOptions.map(option => ({
              value: option,
              text: `invoiceInformation.paymentOptions.${option}`,
            })),
            componentType: 'select',
            validators: [Validators.required],
            labelKey: 'invoiceInformation.paymentOptions.title',
          },
        ],
      };
    }
  }

  private initInvoiceSelectionForm(initialValue: string): void {
    this.invoiceSelectionForm = {
      inputs: [
        {
          name: 'invoiceMethod',
          initialValue,
          labelKey: 'invoiceInformation.invoiceOptions.title',
          options: this.invoiceOptions.map(option => ({
            value: option,
            text: `invoiceInformation.invoiceOptions.${option}`,
          })),
          componentType: 'select',
          validators: [Validators.required],
        },
      ],
    };
  }
}

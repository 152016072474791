import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { Address, AddressProducts, AddressProductsSelectedEvent, NavigatingBackTo, Product, createAddress } from '@mwe/models';
import { AddressLogic, LoggingService } from '@mwe/services';
import { getProductCategories, isArrayWithMinOneItem, isArrayWithOneItem } from '@mwe/utils';
import { ServiceStateEnum } from '@mwe/constants';

@Component({
  selector: 'mwe-address-selection',
  templateUrl: './address-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSelectionComponent implements OnInit {
  noAddressGroupsTitle = input<string>();
  noAddressGroupsText = input<string>();
  extendedLoadingSpinner = input<boolean>(false);
  navigatingBackTo = input<NavigatingBackTo>();
  productsFilterFunc = input<(p: Product[]) => boolean>();

  productSearchBtnClicked = output();
  addressSelected = output<AddressProductsSelectedEvent>();
  hasNoAddressProducts = output();

  state = signal<ServiceStateEnum>(ServiceStateEnum.INIT);
  addressOptions = signal<AddressProducts[]>([]);
  hasNoAddressOption = computed<boolean>(() => !this.addressOptions()?.length);
  getProductCategories = getProductCategories;

  constructor(
    private addressLogic: AddressLogic,
    private loggingService: LoggingService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initAddressOptions();
    this.skipStepForOnlyOneAddress();
  }

  selectOption(address: Address, products: Product[]): void {
    this.addressSelected.emit({
      addressProduct: { address, products },
      selectionWasSkippedDueToOneAddress: false,
    });
  }

  private async initAddressOptions(): Promise<void> {
    this.state.set(ServiceStateEnum.LOADING);

    try {
      const options = await this.addressLogic.loadAddressesWithGroupNames();
      this.addressOptions.set(options);

      if (this.productsFilterFunc()) {
        this.addressOptions.update(old => old.filter(aO => this.productsFilterFunc()(aO.products)));
      }

      if (!isArrayWithMinOneItem(this.addressOptions())) {
        this.hasNoAddressProducts.emit();
      }

      this.state.set(ServiceStateEnum.SUCCESS);
    } catch (ex) {
      this.state.set(ServiceStateEnum.ERROR);
      this.loggingService.logError(ex, 'error on loading possible addresses');
    }
  }

  private skipStepForOnlyOneAddress() {
    if (!isArrayWithOneItem(this.addressOptions())) return;

    const address = createAddress(this.addressOptions()[0].address);
    const products = this.addressOptions()[0].products;

    this.addressSelected.emit({
      addressProduct: { address, products },
      selectionWasSkippedDueToOneAddress: true,
    });
  }
}

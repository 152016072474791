import { Component, computed, input, output, signal } from '@angular/core';
import { InfoText, InlineNotificationType, TextNodeType } from '@mwe/models';
import { v4 } from 'uuid';
import { HeadlineService } from '../headline/headline.service';

const ColorSchemes = {
  success: {
    borderClass: 'border-success',
    backgroundClass: 'bg-green-010',
    iconColorClass: 'text-success',
  },
  danger: {
    borderClass: 'border-danger',
    backgroundClass: 'bg-red-050',
    iconColorClass: 'text-danger',
  },
  info: {
    borderClass: 'border-secondary-light',
    backgroundClass: 'bg-blue-050',
    iconColorClass: 'text-secondary-light',
  },
  warning: {
    borderClass: 'border-warning',
    backgroundClass: 'bg-yellow-050',
    iconColorClass: 'text-warning',
  },
  neutral: {
    borderClass: 'border-neutral-400',
    backgroundClass: 'bg-neutral-010',
    iconColorClass: 'border-neutral-400',
  },
  transparent: {
    borderClass: 'border-neutral-400',
    backgroundClass: '',
    iconColorClass: 'border-neutral-400',
  },
};

@Component({
  selector: 'mwe-infobox',
  templateUrl: './infobox.component.html',
  providers: [HeadlineService],
})
export class InfoboxComponent {
  type = input<InlineNotificationType>('info');
  titleKey = input<string>();
  messageKey = input<string>();
  messageKeys = input<string[]>();
  placeholderMap = input<Map<string, string>>();
  link = input<string>();
  iconClassOverwrite = input<string>('');
  infoText = input<InfoText>();
  headingNodeType = input<TextNodeType>('h5');
  ctaLabelKey = input<string>();
  ctaPrependIconClass = input<string>();
  onLinkClick = output();
  onCTAClick = output();

  colorScheme = computed(() => {
    return ColorSchemes[this.type()];
  });

  CssClasses = computed<string[]>(() => {
    const classes = [];

    classes.push(this.colorScheme().borderClass);
    classes.push(this.colorScheme().backgroundClass);

    if (!this.isVisible()) classes.push('d-none');

    return classes;
  });

  iconClasses = computed<string>(() => {
    if (this.iconClassOverwrite()) {
      return this.iconClassOverwrite();
    }

    switch (this.type()) {
      case 'danger':
        return 'fas fa-circle-xmark';
      case 'warning':
        return 'fas fa-circle-exclamation';
      case 'success':
        return 'fas fa-circle-check';
      default:
        return 'fas fa-circle-info';
    }
  });

  showTitle = computed<boolean>(() => this.titleKey() && this.titleKey().length > 0);

  isVisible = signal(true);
  translateValues = computed<{ [key: string]: string | number }>(() => {
    if (!this.placeholderMap()) return {};
    return [...this.placeholderMap().entries()]?.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );
  });
  uuid = signal<string>(v4());

  fireLinkClickEvent(): void {
    this.onLinkClick.emit();
  }
}

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as NavigationConstants from '@mwe/constants';
import { INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE } from '@mwe/constants';
import { AddressProducts, ILSystem, InvoiceListFilter, Sparte } from '@mwe/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { PartialPaymentStateService } from '../../partial-payment/partial-payment-state.service';
import { InteractiveInvoicesStateService } from './interactive-invoices-state.service';

@Injectable({ providedIn: 'root' })
export class InteractiveInvoicesLogic {
  private currentInvoiceListFilter = new BehaviorSubject<InvoiceListFilter>({ category: undefined });
  private router = inject(Router);
  private stateService = inject(InteractiveInvoicesStateService);
  private partialPaymentStateService = inject(PartialPaymentStateService);

  showInteractiveInvoices(addressProducts: AddressProducts, categoryToFilter?: string): void {
    this.router.navigateByUrl(`/${NavigationConstants.INTERACTIVE_INVOICE_ROUTE}`);
    this.stateService.addressProducts = addressProducts;
    this.setInvoiceListFilter({ category: categoryToFilter as Sparte });
  }

  showInvoicePartialPaymentOverview(addressProducts: AddressProducts, accountNumber: string, systemId: ILSystem): void {
    this.partialPaymentStateService.addressProducts = addressProducts;
    this.partialPaymentStateService.accountId = accountNumber;
    this.partialPaymentStateService.systemId = systemId;

    this.router.navigateByUrl(`/${INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE}`);
  }

  setInvoiceListFilter(filter: InvoiceListFilter): void {
    this.currentInvoiceListFilter.next(filter);
  }

  resetInvoiceListFilter(): void {
    this.currentInvoiceListFilter.next({ category: undefined });
  }

  getCurrentInvoiceListFilter(): Observable<InvoiceListFilter> {
    return this.currentInvoiceListFilter.asObservable();
  }
}

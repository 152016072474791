import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ADD_PRODUCTS_ROUTE,
  ADD_PRODUCTS_SEARCH_ROUTE,
  FeatureToggleEnum,
  PUSH_PDF_POPUP_CLOSED,
  PUSH_SEPA_POPUP_CLOSED,
} from '@mwe/constants';
import {
  ActionType,
  EnergyVoucherMapping,
  AddressProducts,
  ILSystem,
  IVerknuepfungen,
  IVerknuepfungenResponse,
  Product,
  RecentOrder,
  UserJournal,
} from '@mwe/models';
import {
  cloneDeepJSON,
  getCommonMappingDataGroupedByAccountNumber,
  isArrayWithMinOneItem,
  parseRecentOrdersDTO,
  parseVerknuepfungen,
  sortRecentOrdersDTO,
} from '@mwe/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { map, tap } from 'rxjs/operators';
import { AccountLogic } from '../account/account.logic';
import { AppStorageService } from '../cache/app-storage.service';
import { LoggingService } from '../logging/logging.service';
import { ProfileService } from '../profile/profile.service';
import { UserJournalService } from '../user/user.journal.service';
import { ProductStateService } from './product-state.service';
import { ProductService } from './product.service';
import { lastValueFrom } from 'rxjs';

dayjs.extend(duration);

@Injectable({
  providedIn: 'root',
})
export class ProductLogic {
  addProductsSearchRoute = `/${ADD_PRODUCTS_ROUTE}/${ADD_PRODUCTS_SEARCH_ROUTE}`;
  private associationsUpdates: IVerknuepfungenResponse;

  constructor(
    private accountLogic: AccountLogic,
    private productService: ProductService,
    private productStateService: ProductStateService,
    private loggingService: LoggingService,
    private profileService: ProfileService,
    private router: Router,
    private appStorage: AppStorageService,
    private userJournalService: UserJournalService,
  ) {}

  async getProducts(): Promise<Product[]> {
    if (this.productStateService.associations) {
      return this.parseVerknuepfungenFromFilteredAssociations();
    }

    // we need this for DSGVO shenanigans
    // todo refactoring -> should be in backend
    await this.productService
      .loadAssociations()
      .then(response => {
        this.productStateService.associationsOrig = cloneDeepJSON(response) as IVerknuepfungenResponse;
        return response;
      })
      .then(response => this.productService.filterAssociations(response))
      .then(response => (this.productStateService.associations = response));

    return this.parseVerknuepfungenFromFilteredAssociations();
  }

  parseVerknuepfungenFromFilteredAssociations(): Product[] {
    const filteredAssociations = this.getFilteredAssociationsWithVerknuepfungsStatus();
    return parseVerknuepfungen(filteredAssociations.verknuepfungen);
  }

  getFilteredAssociationsWithVerknuepfungsStatus(): IVerknuepfungenResponse {
    return this.productService.filterAssociationsWithValidVerknuepfungsStatus(this.productStateService.associations);
  }

  async checkAssociationsUpdate(): Promise<boolean> {
    return await this.compareAndUpdateAssociations();
  }

  async findPrivateProducts(kn: string, name: string, birthday: string): Promise<IVerknuepfungenResponse> {
    return this.productService.findPrivateProducts(kn, name, birthday).then(response => this.productService.filterAssociations(response));
  }

  async findBusinessStromGasProducts(customerId: string, systemId: ILSystem, accountId: string): Promise<IVerknuepfungenResponse> {
    return this.productService
      .findBusinessStromGasProducts(customerId, systemId, accountId)
      .then(response => this.productService.filterAssociations(response));
  }

  async findBusinessWaermeProducts(customerId: string, systemId: ILSystem, organisationsName: string): Promise<IVerknuepfungenResponse> {
    return this.productService
      .findBusinessWaermeProducts(customerId, systemId, organisationsName)
      .then(response => this.productService.filterAssociations(response));
  }

  clearAssociationsUpdate(): void {
    this.associationsUpdates = null;
  }

  getProductsPerAccountNumber(products: Product[]): Map<string, Product[]> {
    const productsPerAccountNumber: Map<string, Product[]> = new Map<string, Product[]>();
    products.forEach(product => {
      productsPerAccountNumber.set(product.accountNumber, [...(productsPerAccountNumber.get(product.accountNumber) || []), product]);
    });
    return productsPerAccountNumber;
  }

  orderAndGroupByAddresses(products: Product[]): Map<string, AddressProducts> {
    if (!Array.isArray(products)) {
      return new Map();
    }

    const addressGroups = new Map();
    this.sortProductsByAddressHashcode(products);

    for (const p of products) {
      if (!addressGroups.has(p.address?.hashCode)) {
        addressGroups.set(p.address?.hashCode, { address: p.address, products: [] });
      }

      addressGroups.get(p.address?.hashCode).products.push(p);
    }

    return addressGroups;
  }

  getRecentOrders(): Promise<RecentOrder[]> {
    if (this.productStateService.recentOrders) {
      return Promise.resolve(this.productStateService.recentOrders);
    }

    return lastValueFrom(
      this.productService.getRecentOrders().pipe(
        map(recentOrdersDTO => sortRecentOrdersDTO(recentOrdersDTO)),
        map(recentOrdersDTO => parseRecentOrdersDTO(recentOrdersDTO)),
        tap(recentOrders => (this.productStateService.recentOrders = recentOrders)),
      ),
    );
  }

  async getProductDataGroupedByAccountNumber(): Promise<EnergyVoucherMapping[]> {
    const products = await this.getProducts();

    return getCommonMappingDataGroupedByAccountNumber(products);
  }

  async wasPushPdfPopupAlreadyShown(): Promise<boolean> {
    if (this.productStateService.wasPushPdfPopupAlreadyShown !== true) {
      const event = await this.userJournalService.getEvent(PUSH_PDF_POPUP_CLOSED);
      if (event !== UserJournalService.EVENT_NOT_FOUND) {
        const ujevent = event as UserJournal;
        const ms = dayjs.duration(ujevent.additionalData).asMilliseconds();
        return new Date(ujevent.modifiedAt).getTime() > new Date().getTime() - ms;
      }
      return false;
    }
    return true;
  }

  async wasPushSepaPopupAlreadyShown(): Promise<boolean> {
    if (this.productStateService.wasPushSepaPopupAlreadyShown !== true) {
      const event = await this.userJournalService.getEvent(PUSH_SEPA_POPUP_CLOSED);
      if (event !== UserJournalService.EVENT_NOT_FOUND) {
        const ujevent = event as UserJournal;
        const ms = dayjs.duration(ujevent.additionalData).asMilliseconds();
        return new Date(ujevent.modifiedAt).getTime() > new Date().getTime() - ms;
      }
      return false;
    }
    return true;
  }

  private async updateAssociationsAndFindMissing(associationsUpdates: IVerknuepfungenResponse): Promise<IVerknuepfungen[]> {
    const associationsOrig = this.productStateService.associationsOrig;

    const missingAssociations: IVerknuepfungen[] = [];

    for (let i = 0; i < associationsOrig.verknuepfungen.length; i++) {
      const updatedAssociation = associationsUpdates?.verknuepfungen?.find(
        association =>
          association.geschaeftsPartnerId === associationsOrig.verknuepfungen[i].geschaeftsPartnerId && association.produkte?.length,
      );
      if (updatedAssociation) {
        associationsOrig.verknuepfungen[i] = updatedAssociation;
      } else {
        missingAssociations.push(associationsOrig.verknuepfungen[i]);
      }
    }

    this.associationsUpdates = this.productService.filterAssociations(this.associationsUpdates);
    this.associationsUpdates = this.productService.filterAssociationsWithoutDeclinedVerknuepfungsStatus(this.associationsUpdates);

    return missingAssociations;
  }

  private async findNewAssociations(associationsUpdates: IVerknuepfungenResponse): Promise<IVerknuepfungen[]> {
    const newAssociations: IVerknuepfungen[] = [];

    const associationsOrig = this.productStateService.associationsOrig;
    for (let i = 0; i < associationsUpdates?.verknuepfungen?.length; i++) {
      if (
        !associationsOrig.verknuepfungen.some(
          a =>
            a.geschaeftsPartnerId === associationsUpdates.verknuepfungen[i].geschaeftsPartnerId &&
            associationsUpdates.verknuepfungen[i].verknuepfungsStatus?.length > 0,
        )
      ) {
        newAssociations.push(associationsUpdates.verknuepfungen[i]);
      }
    }
    return newAssociations;
  }

  private async logMissingAssociation(missingAssociation: IVerknuepfungen): Promise<void> {
    if (!this.productStateService.hasLoggedMissingAssociation(missingAssociation)) {
      this.loggingService.logAction({
        type: ActionType.ASSOCIATIONS,
        message: 'Missing association for ' + missingAssociation.geschaeftsPartnerId + '. Data: ' + JSON.stringify(missingAssociation),
      });
      this.productStateService.addLoggedMissingAssociation(missingAssociation);
    }
  }

  private async logNewAssociation(newAssociation: IVerknuepfungen): Promise<void> {
    if (!this.productStateService.hasLoggedNewAssociation(newAssociation)) {
      this.loggingService.logAction({
        type: ActionType.ASSOCIATIONS,
        message: 'New association for ' + newAssociation.geschaeftsPartnerId + '. Data: ' + JSON.stringify(newAssociation),
      });
      this.productStateService.addLoggedNewAssociation(newAssociation);
    }
  }

  private async logAssociationSuggestions(associationSuggestions: IVerknuepfungen[]): Promise<void> {
    associationSuggestions = associationSuggestions.filter(
      association => !this.productStateService.hasLoggedAssociationSuggestion(association),
    );
    if (associationSuggestions?.length > 0) {
      const account = this.accountLogic.getAccount();
      this.loggingService.logAction({
        type: ActionType.ASSOCIATIONS,
        message: `[association-suggestion] found (${account.id} / ${account.email} / ${associationSuggestions
          .map(elem => elem.geschaeftsPartnerId)
          .join(',')})`,
      });
      for (const association of associationSuggestions) {
        this.productStateService.addLoggedAssociationSuggestion(association);
      }
    }
  }

  private async compareAndUpdateAssociations(): Promise<boolean> {
    try {
      const email = this.accountLogic.getAccountEmail();
      if (email === undefined || email === null) {
        throw new Error('Missing email address!');
      }

      if (!this.associationsUpdates) {
        this.associationsUpdates = await this.productService.getAssociationsUpdates(email);
      }

      let associationsUpdates = this.associationsUpdates;

      if (!isArrayWithMinOneItem(associationsUpdates?.verknuepfungen)) {
        return false;
      }

      const associationsOrig = this.productStateService.associationsOrig;
      const missingAssociations = await this.updateAssociationsAndFindMissing(associationsUpdates);
      for (const missingAssociation of missingAssociations) {
        await this.logMissingAssociation(missingAssociation);
      }

      // disassociate associationsUpdates from original to avoid changing references in associationsOrig through filter side effects
      associationsUpdates = cloneDeepJSON(associationsUpdates) as IVerknuepfungenResponse;
      associationsUpdates = this.productService.filterAssociations(associationsUpdates);
      associationsUpdates = this.productService.filterAssociationsWithoutDeclinedVerknuepfungsStatus(associationsUpdates);

      const newAssociations = await this.findNewAssociations(associationsUpdates);

      if (
        this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.ASSOCIATION_SUGGESTIONS_ENABLED) &&
        !this.accountLogic.isImpersonate()
      ) {
        this.productStateService.associationSuggestions = newAssociations;
        this.logAssociationSuggestions(newAssociations);
      } else {
        for (const newAssociation of newAssociations) {
          await this.logNewAssociation(newAssociation);
          if (this.appStorage.isEmailInvitation()) {
            this.router.navigate([this.addProductsSearchRoute]);
          }
          return false;
        }
      }

      if (0 === newAssociations.length && this.appStorage.isEmailInvitation()) {
        this.router.navigate([this.addProductsSearchRoute]);
      }

      const associationsWithVerknuepfungsStatus = this.productService.filterAssociationsWithValidVerknuepfungsStatus(associationsOrig);
      this.productStateService.associations = this.productService.filterAssociations(associationsWithVerknuepfungsStatus);

      return true;
    } catch (error) {
      this.loggingService.logError(error, 'error in compareAndUpdateAssociations');
      return false;
    }
  }

  private sortProductsByAddressHashcode(products: Product[]): void {
    products.sort((a, b) => {
      if (a.address?.hashCode === b.address?.hashCode) {
        return 0;
      }

      return a.address?.hashCode > b.address?.hashCode ? 1 : -1;
    });
  }
}

function t(t, r) {
  return t > r ? 1 : -1;
}
function r(o, e) {
  var n = 5381;
  if ("object" == typeof o && null !== o && (o.toString === Object.prototype.toString || o.toString === Array.prototype.toString)) {
    e || (e = new WeakSet());
    for (var i = Object.keys(o).sort(t), a = 0; a < i.length; a++) {
      var c = i[a],
        g = o[c];
      if (n = 33 * n ^ r(c, e), "object" == typeof g && null !== g && (o.toString === Object.prototype.toString || o.toString === Array.prototype.toString)) {
        if (e.has(g)) continue;
        e.add(g);
      }
      n = 33 * n ^ r(g, e);
    }
    return 33 * n ^ r(o.constructor, e);
  }
  var p = typeof o;
  try {
    o instanceof Date ? p += o.getTime() : p += String(o);
  } catch (t) {
    p += String(Object.assign({}, o));
  }
  for (var f = 0; f < p.length; f++) n = 33 * n ^ p.charCodeAt(f);
  return n;
}
export { r as hash, t as sortNumbers };

import { ServiceStateEnum } from '@mwe/constants';
import { StepIdentifier, SubmitIdentifier } from './backend-process-step.model';
import { IBulletConfirmationStatusChangeResponse } from '@mwe/models';
import { BackendProcessStore } from './backend-process.store';
import { BackendProcessHandlerInterface, StopWatchProcessFn } from './backend-process-handler.model';
import { inject } from '@angular/core';

const SYM_PROCESS = Symbol('BackendProcessStore');

export abstract class AbstractBackendProcessHandler implements BackendProcessHandlerInterface {
  protected updateInterval = 1500;
  protected maxUpdates = 1000;
  protected [SYM_PROCESS]: BackendProcessStore;
  protected stopProcessWatcher: StopWatchProcessFn;

  constructor() {
    this[SYM_PROCESS] = inject(BackendProcessStore);
  }

  /**
   * Initialize the process. (Build steps / DTO / etc.)
   */
  // eslint-disable-next-line unused-imports/no-unused-vars
  async initializeProcess(process: BackendProcessStore, isRetry: boolean): Promise<void> {
    // noop
  }
  /**
   * Send the confirm request to start the process.
   */
  abstract sendConfirm(): Promise<IBulletConfirmationStatusChangeResponse>;
  /**
   * Get the current status of the process.
   */
  abstract getConfirmStatus(submitId: SubmitIdentifier): Promise<IBulletConfirmationStatusChangeResponse>;
  /**
   * Send the retry request to restart the process.
   */
  // eslint-disable-next-line unused-imports/no-unused-vars
  sendRetry(submitId: SubmitIdentifier): Promise<IBulletConfirmationStatusChangeResponse> {
    throw new Error('Retry not supported');
  }
  /**
   * Override this method to enable automatic retries on error. Keep in mind that you need to implement the sendRetry method as well.
   * Also make sure to not allow infinite retries.
   * @returns If the process should automatically retry on error
   */
  shouldAutoRetry(): boolean {
    return false;
  }

  /**
   * Start the process and append the watcher. If withSubmitId is set, the process will start with the given submitId and skip the sendConfirm request
   * @param withSubmitId Optional - The submitId to start the process with (used for status polling)
   * @param isRetry Optional - If the process is in retry mode (will use sendRetry instead of sendConfirm)
   * @returns StopWatchProcessFn - A function to stop the process watcher if called.
   */
  startProcess(withSubmitId?: SubmitIdentifier, isRetry = false): StopWatchProcessFn {
    this[SYM_PROCESS].setSubmitId(withSubmitId);
    const isProcessInFinalState = [ServiceStateEnum.SUCCESS, ServiceStateEnum.ERROR].includes(this[SYM_PROCESS].processState());
    if (isProcessInFinalState && !isRetry) {
      return;
    }
    const { startWatcher, stopWatcher } = this.createProcessWatcher(isRetry);
    this.initializeProcess(this[SYM_PROCESS], isRetry).then(() => {
      this.onProcessWillStart(this[SYM_PROCESS]);
      if (withSubmitId) {
        this[SYM_PROCESS].setSubmitId(withSubmitId);
      }
      startWatcher();
    });
    return (this.stopProcessWatcher = stopWatcher);
  }

  /**
   * Calls sendConfirm / getConfirmStatus / sendRetry based on existing submitId and useRetry param.
   * Will pass the response to parseStatusChangeResponse.
   * Will stop the process watcher if the process is in a final state (Success / Error)
   * Will stop the process watcher if the maxUpdates variable is reached
   * */
  protected createProcessWatcher(useRetry = false): { stopWatcher: StopWatchProcessFn; startWatcher: () => void } {
    let interrupt = false;
    let numUpdates = 0;
    let retried = false;
    const updateProcess = async () => {
      if (useRetry && !retried) {
        const response = await this.sendRetry(this[SYM_PROCESS].submitId());
        this.parseStatusChangeResponse(response, this[SYM_PROCESS]);
        retried = true;
      } else if (!this[SYM_PROCESS].submitId()) {
        // if we don't have a submitId yet, we need to send the confirm request to start the process
        const response = await this.sendConfirm();
        this[SYM_PROCESS].setSubmitId(response.submitId);
        this.parseStatusChangeResponse(response, this[SYM_PROCESS]);
      } else {
        const response = await this.getConfirmStatus(this[SYM_PROCESS].submitId());
        this.parseStatusChangeResponse(response, this[SYM_PROCESS]);
      }
      // if the process is in a final state (Success / Error), we don't need to update anymore
      if (this[SYM_PROCESS].processState() === ServiceStateEnum.SUCCESS || this[SYM_PROCESS].processState() === ServiceStateEnum.ERROR) {
        interrupt = true;
      }
      numUpdates++;
      if (this.maxUpdates > 0 && numUpdates > this.maxUpdates) {
        interrupt = true;
      }
      if ([ServiceStateEnum.SUCCESS, ServiceStateEnum.ERROR].includes(this[SYM_PROCESS].processState())) {
        interrupt = true; // stop the watcher if the process is in a final state after parsing the response
      }
      if (!interrupt) {
        setTimeout(() => updateProcess(), this.updateInterval);
      } else {
        this.onProcessStopped(this[SYM_PROCESS]);
      }
    };
    return {
      stopWatcher: () => {
        interrupt = true;
      },
      startWatcher: () => {
        numUpdates = 0;
        updateProcess();
      },
    };
  }

  parseStatusChangeResponse(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    const status = response.status;
    const processingParts = status.split('_');
    const processState = processingParts[0];
    const handlerMethodName = `onProcess${processState.charAt(0).toUpperCase() + processState.slice(1)}`;
    if (handlerMethodName in this) {
      this[handlerMethodName](response, process);
    } else {
      this.onProcessUndefinedState(processState, response, process);
    }
  }

  getStepFromResponse(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    const status = response.status;
    const processingParts = status.split('_');
    if (processingParts.length < 4) {
      console.warn(`Unexpected status format: ${status}`);
      return;
    }
    const [_, stepName, stepIndex] = processingParts;
    const stepId = `${stepName}_${stepIndex}`;
    return process.getStepById(stepId);
  }

  getStepStateFromResponse(response: IBulletConfirmationStatusChangeResponse): ServiceStateEnum {
    const status = response.status;
    const processingParts = status.split('_');
    if (processingParts.length < 4) {
      console.warn(`Unexpected status format: ${status}`);
      return ServiceStateEnum.ERROR;
    }
    const stepState = processingParts[3];
    return stepState as ServiceStateEnum;
  }

  dispatchStepStateChange(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    const step = this.getStepFromResponse(response, process);
    const stepState = this.getStepStateFromResponse(response);
    const stepId = step.stepId;
    if (!step) {
      this.onProcessUndefinedStep(stepId, response, process);
      return;
    }
    const handlerMethod = `onStep${stepState.charAt(0).toUpperCase() + stepState.slice(1)}`;
    if (handlerMethod in this) {
      this[handlerMethod](stepId, response, process);
    }
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onProcessUndefinedStep(stepId: StepIdentifier, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore): void {
    console.warn(`Step with id ${stepId} not found in process. Skipping status update.`);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onProcessWillStart(process: BackendProcessStore): void {
    // noop
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onProcessStopped(process: BackendProcessStore): void {
    // noop
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onProcessInit(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    // noop
  }

  onProcessProcessing(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    this.dispatchStepStateChange(response, process);
  }

  onProcessSuccess(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    process.setStepState(process.getSteps(), { state: ServiceStateEnum.SUCCESS });
    process.overrideProcessState(ServiceStateEnum.SUCCESS);
    this.stopProcessWatcher();
  }

  onProcessError(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    this.onProcessFailure(response, process);
    this.stopProcessWatcher();
    if (this.shouldAutoRetry() && process.retryAllowed()) {
      this.startProcess(process.submitId(), true);
    } else {
      process.overrideProcessState(ServiceStateEnum.ERROR);
    }
  }

  onProcessFailure(response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    this.dispatchStepStateChange(response, process);
    const step = this.getStepFromResponse(response, process);
    process.overrideErrorCodes(new Map([[step, response.errorCode]]));
    process.overrideRetryAllowed(response.retryAllowed);
    // a failure doe not stop the process watcher while an error does
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onProcessUndefinedState(processState: string, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore): void {
    console.warn(`No handler method found for process state ${processState}. Skipping status update.`);
  }

  onStepError(stepId: StepIdentifier, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    // all steps before the current step should be successful
    process.setStepState(process.getStepsBefore(stepId, false), { state: ServiceStateEnum.SUCCESS });
    process.setStepState(stepId, { state: ServiceStateEnum.ERROR, errorCode: response.errorCode, retryAllowed: response.retryAllowed });
  }

  onStepFailure(stepId: StepIdentifier, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    // all steps before the current step should be successful
    process.setStepState(process.getStepsBefore(stepId, false), { state: ServiceStateEnum.SUCCESS });
    process.setStepState(stepId, { state: ServiceStateEnum.FAILED, errorCode: response.errorCode, retryAllowed: response.retryAllowed });
  }

  onStepSuccess(stepId: StepIdentifier, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    // all steps before the current step should be successful
    process.setStepState(
      process.getStepsBefore(stepId, true), // include the current step using true in getStepsBefore
      { state: ServiceStateEnum.SUCCESS, errorCode: response.errorCode, retryAllowed: response.retryAllowed },
    );
  }

  onStepInit(stepId: StepIdentifier, response: IBulletConfirmationStatusChangeResponse, process: BackendProcessStore) {
    // all steps before the current step should be successful
    process.setStepState(process.getStepsBefore(stepId, false), { state: ServiceStateEnum.SUCCESS });
    process.setStepState(stepId, { state: ServiceStateEnum.LOADING });
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  BannerData,
  FrontpageAlertData,
  FrontpageData,
  ICampaignData,
  ILiveChatConfiguration,
  IMG_PATH,
  InfoTextList,
  InteractiveInvoiceDemoData,
  InteractiveInvoiceStaticData,
  InternetProducts,
  IPdfBillActivationConfiguration,
  ISepaBillActivationConfiguration,
  IVkiOfferTexts,
  JSON_PATH,
  MaintenanceStaticResponse,
  PreisIndikationenApiResponse,
  ProductDetailsStaticData,
  PushPdfData,
  PushPdfInfo,
} from '@mwe/models';
import { ENVIRONMENT_SERVICE_TOKEN, FeatureToggleEnum } from '@mwe/constants';
import dayjs from 'dayjs';
import { ProfileService } from '../profile/profile.service';
import { EnvironmentService } from '../environment/environment.service';
import { isInDateRange } from '@mwe/utils';

@Injectable({ providedIn: 'root' })
export class StaticContentService {
  private url: string;
  private staticContentUrl: string;

  private frontpageData: FrontpageData;
  private campaignData: ICampaignData;
  private bannerData: BannerData[];
  private maintenanceData: MaintenanceStaticResponse;
  private infoTextList: InfoTextList;
  private infoTextListPromise: Promise<InfoTextList>;
  private interactiveInvoiceStaticData: InteractiveInvoiceStaticData;
  private interactiveInvoiceStaticDataPromise: Promise<InteractiveInvoiceStaticData>;
  private invoiceDemoData: InteractiveInvoiceDemoData;
  private invoiceDemoDataPromise: Promise<InteractiveInvoiceDemoData>;
  private pushPdfData: PushPdfData;
  private pushPdfDataPromise: Promise<PushPdfData>;
  private productDetailsPromise: Promise<ProductDetailsStaticData>;
  private productDetailsStaticData: ProductDetailsStaticData;
  private spotData: Record<string, PushPdfInfo>;
  private spotDataPromise: Promise<Record<string, PushPdfInfo>>;

  constructor(
    private profileService: ProfileService,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
  ) {
    this.staticContentUrl = this.environmentService.staticContent();
    this.url = `${this.staticContentUrl}${JSON_PATH}`;
  }

  getImagePath(): string {
    return this.environmentService.staticContent() + IMG_PATH;
  }

  async getBannerContent(): Promise<BannerData[]> {
    if (!this.bannerData) {
      const response = await this.getContentFromCustomerTypeDirectory<BannerData[]>('banner.json');
      const serverTime = this.profileService.getProfileInfo()?.serverTimestamp;

      this.bannerData =
        response?.filter(banner => {
          const validFrom = banner.validFrom;
          const validTo = banner.validTo;

          return !validFrom || !validTo || isInDateRange(serverTime, validFrom, validTo);
        }) || [];
    }

    return this.bannerData;
  }

  async getFrontpageContent(): Promise<FrontpageData> {
    this.frontpageData = await this.getContentFromCustomerTypeDirectory<FrontpageData>('frontpage.json');
    if (this.frontpageData?.content?.imageUri) {
      this.frontpageData.content.imageUri = `${this.staticContentUrl}${IMG_PATH}${this.frontpageData?.content?.imageUri}`;
    }
    return this.frontpageData;
  }

  async getCampaignContent(): Promise<ICampaignData> {
    if (this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.CAMPAIGN_ENABLED) && !this.campaignData) {
      this.campaignData = await this.getContentFromCustomerTypeDirectory<ICampaignData>('campaign.json');

      const serverTime = this.profileService.getProfileInfo()?.serverTimestamp;
      const validFrom = this.campaignData.validFrom;
      const validTo = this.campaignData.validTo;

      if (!!validFrom && !!validTo && !isInDateRange(serverTime, validFrom, validTo)) {
        this.campaignData = null;
      }
    }
    return this.campaignData;
  }

  async getSpotData(): Promise<Record<string, PushPdfInfo>> {
    if (this.spotData) {
      return Promise.resolve(this.spotData);
    }

    if (this.spotDataPromise) {
      return this.spotDataPromise;
    }

    this.spotDataPromise = this.getContentFromCustomerTypeDirectory<Record<string, PushPdfInfo>>('spot.json');
    this.spotData = await this.spotDataPromise;
    return this.spotData;
  }

  loadLiveChatConfiguration(): Promise<ILiveChatConfiguration> {
    return this.getContent<ILiveChatConfiguration>('findPaymentSolutionLiveChatConfig.json');
  }

  loadPdfBillActivationConfiguration(): Promise<IPdfBillActivationConfiguration> {
    return this.getContent<IPdfBillActivationConfiguration>('pdfBillActivation.json');
  }

  loadInternetProductsConfiguration(): Promise<InternetProducts> {
    return this.getContent<InternetProducts>('internet.products.config.json');
  }

  loadSepaBillActivationConfiguration(): Promise<ISepaBillActivationConfiguration> {
    return this.getContent<ISepaBillActivationConfiguration>('sepaBillActivation.json');
  }

  async getMaintenanceContent(): Promise<MaintenanceStaticResponse> {
    this.maintenanceData = await this.getContentFromCustomerTypeDirectory<MaintenanceStaticResponse>('/maintenance.json');
    return this.maintenanceData;
  }

  async getInfoTextList(): Promise<InfoTextList> {
    if (this.infoTextList) {
      return this.infoTextList;
    }

    if (!this.infoTextListPromise) {
      this.infoTextListPromise = this.getContentFromCustomerTypeDirectory('infoTextList.json');
    }

    this.infoTextList = await this.infoTextListPromise;
    return this.infoTextList;
  }

  async getInteractiveInvoiceStaticData(): Promise<InteractiveInvoiceStaticData> {
    if (this.interactiveInvoiceStaticData) {
      return this.interactiveInvoiceStaticData;
    }

    if (!this.interactiveInvoiceStaticDataPromise) {
      this.interactiveInvoiceStaticDataPromise =
        this.getContentFromCustomerTypeDirectory<InteractiveInvoiceStaticData>('interactiveInvoice.json');
    }

    this.interactiveInvoiceStaticData = await this.interactiveInvoiceStaticDataPromise;
    return this.interactiveInvoiceStaticData;
  }

  getFrontpageAlertData(): FrontpageAlertData {
    if (this.frontpageData && this.frontpageData.alert) {
      const validFrom = dayjs(this.frontpageData.alert.validFrom);
      const validTo = dayjs(this.frontpageData.alert.validTo);

      if (validFrom.isBefore(dayjs()) && validTo.isAfter(dayjs())) {
        return this.frontpageData.alert;
      }
    }

    return undefined;
  }

  async getInvoiceDemoData(): Promise<InteractiveInvoiceDemoData> {
    if (this.invoiceDemoData) {
      return this.invoiceDemoData;
    }

    if (!this.invoiceDemoDataPromise) {
      this.invoiceDemoDataPromise = this.getContent<InteractiveInvoiceDemoData>('interactiveInvoiceDemo.json');
    }

    this.invoiceDemoData = await this.invoiceDemoDataPromise;
    return this.invoiceDemoData;
  }

  async getPushPdfData(): Promise<PushPdfData> {
    if (this.pushPdfData) {
      return this.pushPdfData;
    }

    if (!this.pushPdfDataPromise) {
      this.pushPdfDataPromise = this.getContentFromCustomerTypeDirectory<PushPdfData>('pushPdf.json');
    }

    this.pushPdfData = await this.pushPdfDataPromise;
    return this.pushPdfData;
  }

  // temp mock request, remove once T2/IL are ready
  async getPriceIndications(): Promise<PreisIndikationenApiResponse> {
    return await this.getContent<PreisIndikationenApiResponse>('get-preisindikationen-emobility.json');
  }

  async getVkiOfferTexts(): Promise<IVkiOfferTexts> {
    return await this.getContent<IVkiOfferTexts>('vki.json');
  }

  async getProductDetailsStaticData(): Promise<ProductDetailsStaticData> {
    if (this.productDetailsStaticData) {
      return this.productDetailsStaticData;
    }

    if (!this.productDetailsPromise) {
      this.productDetailsPromise = this.getContent('productDetails.json');
    }

    this.productDetailsStaticData = await this.productDetailsPromise;
    return this.productDetailsStaticData;
  }

  reset() {
    this.campaignData = null;
    this.infoTextList = null;
    this.infoTextListPromise = null;
  }

  private getContentFromCustomerTypeDirectory<T>(contentName: string): Promise<T> {
    const name = `${this.environmentService.getPortalCustomerType()}/${contentName}`;
    return this.getContent(name);
  }

  /**
   * =WARNING= do not use for other purposes than fetching from static.wienenergie.at
   */
  private async getContent<T>(contentName: string): Promise<T> {
    const url = `${this.url}${contentName}?time=${new Date().getMilliseconds()}`;
    const response = await fetch(url);
    return await response.json();
  }
}

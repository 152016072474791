import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HTTPError,
  IInvoiceDataChangeResponse,
  InvoiceDataChangeDTO,
  IOrderDetailsInfo,
  newInvoiceDataChangeDetailsInfo,
  parseILCustomerAddress,
  parseInvoiceDataChangeItemDTO,
  Product,
} from '@mwe/models';
import { getInvoiceAddress, parseInvoiceAccount } from '@mwe/utils';
import { EnvironmentService } from '../../../environment/environment.service';
import { ClearingAccountLogic } from '../../clearing-account/clearing-account.logic';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataChangeService {
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    private clearingAccountLogic: ClearingAccountLogic,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/invoicedata/';
  }

  async confirm(dataDTO: InvoiceDataChangeDTO): Promise<IInvoiceDataChangeResponse> {
    try {
      return await lastValueFrom(this.http.post<IInvoiceDataChangeResponse>(this.getResourceUrl('change/confirm'), dataDTO));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: InvoiceDataChangeDTO): Promise<IInvoiceDataChangeResponse> {
    try {
      return await lastValueFrom(
        this.http.put<IInvoiceDataChangeResponse>(this.getResourceUrl('change/retry') + '?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IInvoiceDataChangeResponse> {
    return lastValueFrom(this.http.get<IInvoiceDataChangeResponse>(this.getResourceUrl('change/confirmStatus') + '?submitId=' + submitId));
  }

  getOrderStatusDetails(orderId: string): Promise<InvoiceDataChangeDTO> {
    return lastValueFrom(this.http.get(this.resourceUrl + 'orderStatusDetails/' + orderId)).then(res => {
      return parseInvoiceDataChangeItemDTO(res);
    });
  }

  async loadDetailsData(products: Product[]): Promise<IOrderDetailsInfo[]> {
    const newOrderDetails: IOrderDetailsInfo[] = [];

    if (products) {
      for (const element of products) {
        const product = element;
        const newOrderDetail = newInvoiceDataChangeDetailsInfo(product.category, product.customerNumber, product.accountNumber);

        try {
          const result = await this.clearingAccountLogic.getAccountInfo(
            product.accountNumber,
            product.businessPartnerNumber,
            product.systemId,
          );
          newOrderDetail.verrechnungskonto = result.verrechnungskonto;
          newOrderDetail.invoiceAddress = parseILCustomerAddress(
            getInvoiceAddress(newOrderDetail.verrechnungskonto.geschaeftspartnerBeziehungen),
          );

          parseInvoiceAccount(newOrderDetail);
          newOrderDetails.push(newOrderDetail);
        } catch {
          throw new Error('general_error');
        }
      }
    }
    return newOrderDetails;
  }

  private getResourceUrl(path: string): string {
    return this.resourceUrl + path;
  }
}

import { ProductCategoryEnum } from '@mwe/constants';
import { isProductCategory } from '@mwe/utils';
import { Component, Input } from '@angular/core';
import { Product } from '@mwe/models';

@Component({
  selector: 'mwe-product-card-tariff-list',
  templateUrl: './product-card-tariff-list.component.html',
})
export class ProductCardTariffListComponent {
  @Input() product: Product;
  @Input() tariffClasses: string[];
  @Input() showWaermeTariffClassesAsideOfIcon = false;

  isProductCategory = isProductCategory;
  productCategoryEnum = ProductCategoryEnum;

  constructor() {}
}

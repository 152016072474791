import { Injectable } from '@angular/core';
import { AddressProducts, Rechnung, createAddress } from '@mwe/models';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { Observable } from 'rxjs';

const interactiveInvoicesAddressProductsKey = 'interactiveInvoices_addressProducts';
const interactiveInvoicesInvoiceListKey = 'interactiveInvoices_invoiceList';
const interactiveInvoicesSelectedInvoiceKey = 'interactiveInvoices_selectedInvoice';
const interactiveInvoicesSelectedDetailProductKey = 'interactiveInvoices_selectedDetailProduct';

@Injectable({ providedIn: 'root' })
export class InteractiveInvoicesStateService {
  keepSessionStorage = false;

  constructor(private storage: SessionStorageService) {}

  @SessionStorage(interactiveInvoicesAddressProductsKey)
  private _addressProducts: AddressProducts;

  get addressProducts(): AddressProducts {
    if (!this._addressProducts || !this._addressProducts.address || !isArrayWithMinOneItem(this._addressProducts.products)) {
      return undefined;
    }

    this._addressProducts.address = createAddress(this._addressProducts.address);
    return this._addressProducts;
  }

  set addressProducts(addressProducts: AddressProducts) {
    if (!addressProducts) {
      this.storage.clear(interactiveInvoicesAddressProductsKey);
    } else {
      this._addressProducts = addressProducts;
    }
  }

  @SessionStorage(interactiveInvoicesInvoiceListKey)
  private _invoiceList: Rechnung[];

  get invoiceList(): Rechnung[] {
    return this._invoiceList;
  }

  set invoiceList(invoiceList: Rechnung[]) {
    if (!invoiceList) {
      this.storage.clear(interactiveInvoicesInvoiceListKey);
    } else {
      this._invoiceList = invoiceList;
    }
  }

  @SessionStorage(interactiveInvoicesSelectedInvoiceKey)
  private _selectedInvoice: Rechnung;

  get selectedInvoice(): Rechnung {
    return this._selectedInvoice;
  }

  set selectedInvoice(rechnung: Rechnung) {
    if (!rechnung) {
      this.storage.clear(interactiveInvoicesSelectedInvoiceKey);
    } else {
      this._selectedInvoice = rechnung;
    }
  }

  @SessionStorage(interactiveInvoicesSelectedDetailProductKey)
  private _selectedDetailProduct: number;

  get selectedDetailProduct(): number {
    return this._selectedDetailProduct;
  }

  set selectedDetailProduct(productIndex: number) {
    this._selectedDetailProduct = productIndex;
  }

  clear(): void {
    if (this.keepSessionStorage) {
      this.keepSessionStorage = false;
      return;
    }

    this.storage.clear(interactiveInvoicesAddressProductsKey);
    this.storage.clear(interactiveInvoicesInvoiceListKey);
    this.storage.clear(interactiveInvoicesSelectedInvoiceKey);
    this.storage.clear(interactiveInvoicesSelectedDetailProductKey);
  }

  observeAddressProducts(): Observable<AddressProducts> {
    return this.storage.observe(interactiveInvoicesAddressProductsKey);
  }

  observeInvoiceList(): Observable<Rechnung[]> {
    return this.storage.observe(interactiveInvoicesInvoiceListKey);
  }
}

import {
  Address,
  CoverageArea,
  createAddress,
  AddressProducts,
  IOlavEntry,
  IProduktVerfuegbarkeit,
  Product,
  RechnungsAdresse,
  Sparte,
} from '@mwe/models';
import { isArrayWithMinOneItem } from '../common/mwe-util';
import { DetailsLinkTypeEnum } from '@mwe/constants';

export const setAddressGroupName = (address: Address, groupName: string): Address => {
  if (!address || !address?.addressGroup) {
    return address;
  }

  return createAddress({
    ...address,
    addressGroup: {
      ...address.addressGroup,
      groupName,
    },
  });
};

export const getAddressGroupFormatted = (address: Address, withCountryCode = false): string => {
  if (!address) {
    return '';
  }

  let formattedAddressGroup = `${address.postcode} ${address.city}, ${address.street}`;

  if (address.streetNumber) {
    formattedAddressGroup = formattedAddressGroup.concat(` ${address.streetNumber}`);
  }

  if (address.block) {
    formattedAddressGroup = formattedAddressGroup.concat(`/${address.block}`);
  }

  if (address.doorNumber) {
    formattedAddressGroup = formattedAddressGroup.concat(`/${address.doorNumber}`);
  }

  /* MWE-4939-ignore hint: */
  //if (this.hint) {
  //  addressString += `/${this.hint}`;
  //}

  if (withCountryCode) {
    formattedAddressGroup = `${address.country}, ${formattedAddressGroup}`;
  }

  return formattedAddressGroup;
};

export const getAddressStreetFormatted = (address: Address): string => {
  if (!address) {
    return '';
  }

  let formattedStreet = `${address.street}`;

  if (address.streetNumber) {
    formattedStreet = formattedStreet.concat(` ${address.streetNumber}`);
  }

  if (address.block) {
    formattedStreet = formattedStreet.concat(`/${address.block}`);
  }

  if (address.doorNumber) {
    formattedStreet = formattedStreet.concat(`/${address.doorNumber}`);
  }

  /* MWE-4939-ignore hint: */
  //if (this.hint) {
  //  addressString += `/${this.hint}`;
  //}

  return formattedStreet;
};

export const getAddressPostCodeFormatted = (address: Address): string => {
  if (!address) {
    return '';
  }

  return `${address.postcode} ${address.city}`;
};

export const getAddressPostCodeAndStreetFormatted = (address: Address): string => {
  if (!address) {
    return '';
  }

  return `${getAddressPostCodeFormatted(address)}, ${getAddressStreetFormatted(address)}`;
};

export const getAddressStreetAndPostCodeFormatted = (address: Address): string => {
  if (!address) {
    return '';
  }

  return `${getAddressStreetFormatted(address)}, ${getAddressPostCodeFormatted(address)}`;
};

export const isAddressInRelocation = (address: Address): boolean => {
  if (!address?.addressGroup) {
    return false;
  }

  return address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.RELOCATION;
};
export const isAddressInMoveOut = (address: Address): boolean => {
  if (!address?.addressGroup) {
    return false;
  }

  return (
    address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT ||
    address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR
  );
};

export const isAddressInRelocationOrMoveOut = (address: Address): boolean => {
  return isAddressInRelocation(address) || isAddressInMoveOut(address);
};

export const parseAddressCode = (addressCode: string): Address => {
  if (!addressCode) {
    return null;
  }

  const acTokens = addressCode.split('_');
  const addressNumbers = acTokens[2].split('/');

  return createAddress({
    id: null,
    street: acTokens[1],
    streetNumber: addressNumbers[0],
    block: addressNumbers.length > 1 ? addressNumbers[1] : null,
    doorNumber: addressNumbers.length > 2 ? addressNumbers.slice(2).join('/') : null,
    hint: null,
    postcode: acTokens[0],
    city: acTokens[3],
    country: acTokens[4],
  });
};

export const getFromOlavKey = (olavEntry: IOlavEntry): Address => {
  if (!olavEntry) {
    return null;
  }

  return createAddress({
    id: null,
    street: olavEntry?.strasse,
    streetNumber: olavEntry?.gebadr1,
    block: '',
    doorNumber: olavEntry?.tuerNr,
    hint: '',
    postcode: olavEntry?.plz,
    city: olavEntry?.gemeinde,
    country: olavEntry?.isuKZS,
  });
};

export const parseRechnungsAdresse = (rechnungsAdresse: RechnungsAdresse): Address => {
  if (!rechnungsAdresse) {
    return null;
  }

  return createAddress({
    id: '',
    street: rechnungsAdresse?.strasse,
    streetNumber: rechnungsAdresse?.hausnummer,
    block: rechnungsAdresse?.stiege,
    doorNumber: rechnungsAdresse?.tuernummer || rechnungsAdresse?.raumnummer,
    hint: rechnungsAdresse?.adresshinweis,
    postcode: rechnungsAdresse?.plz,
    city: rechnungsAdresse?.ort,
    country: rechnungsAdresse?.laendercode,
  });
};

export const getRechnungsAdresse = (address: Address, olavEntry: IOlavEntry): RechnungsAdresse => {
  if (!address) {
    return undefined;
  }

  return {
    adressart: 'Rechnungsadresse',
    strasse: address.street,
    hausnummer: address.streetNumber,
    stiege: address.block,
    tuernummer: address.doorNumber,
    plz: address.postcode,
    ort: address.city,
    laendercode: address.country,
    olavGaa: olavEntry?.gebaeudeKey,
    olavGba: olavEntry?.gebaeudeKeyIdent,
    olavAna: olavEntry?.anlageKey,
    olavAaa: olavEntry?.anlageAdressKey,
  };
};

export function getAllProductsFromAddressProducts(addressProducts: AddressProducts[]): Product[] {
  if (!isArrayWithMinOneItem(addressProducts)) {
    return [];
  }

  return addressProducts.map(ap => ap.products).flat();
}

export function isAddressInMundlCity(address: Address): boolean {
  if (!address) {
    return false;
  }

  return address.postcode?.[0] === '1';
}

export function isOlavAddressSupportedByCoverageArea(olav: IOlavEntry, coverageArea: CoverageArea[]): boolean {
  if (!olav || !isArrayWithMinOneItem(coverageArea)) {
    return false;
  }

  return coverageArea
    .filter(coverageArea => Number(olav.plz) == coverageArea.bezirk)
    .some(coverageArea => olav.gemeinde == coverageArea.gemeinde);
}

// todo this should be solved by backend
export async function specialHackToCheckIfGasIsAvailable(
  olav: IOlavEntry,
  availabilites: IProduktVerfuegbarkeit[],
  loadGasCoverageFunc: () => Promise<CoverageArea[]>,
): Promise<void> {
  if (!olav || !isArrayWithMinOneItem(availabilites) || !loadGasCoverageFunc) {
    return;
  }

  const isVienna = isAddressInMundlCity(getFromOlavKey(olav));
  if (isVienna) {
    return;
  }
  const coverageAreaGas = await loadGasCoverageFunc();
  const isGasSupported = isOlavAddressSupportedByCoverageArea(olav, coverageAreaGas);

  if (isGasSupported) {
    return;
  }

  const gas = availabilites.find(a => a.sparte === Sparte.Gas);
  if (gas) {
    gas.verfuegbar = 'NEIN';
  }
}

import { assertInInjectionContext, inject } from '@angular/core';
import { EnvironmentCustomerType } from '@mwe/environment';
import { EnvironmentService } from '@mwe/services';

/**
 * Check if we are in the business portal
 * @returns true if the current customer type is BUSINESS
 */
export function inBusinessPortal(): boolean {
  assertInInjectionContext(() => console.debug('This function can only be used in an injection context'));
  return inject(EnvironmentService).getPortalCustomerType() === EnvironmentCustomerType.BUSINESS;
}

/**
 * Run a method only if we are in the business portal
 * @param fn method to run if we are in the business portal
 */
export function runInBusinessPortal(fn: () => void): void {
  if (inBusinessPortal()) {
    fn();
  }
}

/**
 * Check if we are in the privat portal
 * @returns true if the current customer type is PRIVAT
 */
export function inPrivatPortal(): boolean {
  assertInInjectionContext(() => console.debug('This function can only be used in an injection context'));
  return inject(EnvironmentService).getPortalCustomerType() === EnvironmentCustomerType.PRIVAT;
}

/**
 * Run a method only if we are in the privat portal
 * @param fn method to run if we are in the privat portal
 */
export function runInPrivatPortal(fn: () => void): void {
  if (inPrivatPortal()) {
    fn();
  }
}

/**
 * Run a method that depends on the customer type.
 * @param fn method to run
 */
export function forCustomerType<T>(fn: (customerType: EnvironmentCustomerType) => T): T {
  return fn(inject(EnvironmentService).getPortalCustomerType());
}

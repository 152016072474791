import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  FeatureToggleEnum,
  UNPAID_INVOICES_PAYMENT_OPTIONS_ROUTE,
  UNPAID_INVOICES_ROUTE,
  UNPAID_INVOICES_SELECTION_ROUTE,
} from '@mwe/constants';
import { Address, ClearingAccount, AddressProducts, ILiveChatConfiguration, ILiveChatConfigurationOpeningTimes, Sparte } from '@mwe/models';
import dayjs from 'dayjs';
import { FindPaymentSolutionStateService } from './find-payment-solution-state.service';
import { LoggingService } from '../../logging/logging.service';
import { ProfileService } from '../../profile/profile.service';
import { StaticContentService } from '../../static-content/static-content.service';

@Injectable({ providedIn: 'root' })
export class FindPaymentSolutionLogic {
  constructor(
    private stateService: FindPaymentSolutionStateService,
    private router: Router,
    private profileService: ProfileService,
    private staticContentService: StaticContentService,
    private loggingService: LoggingService,
  ) {}

  navigateToSelection(clearingAccounts: ClearingAccount[], addressProducts: AddressProducts): void {
    this.stateService.unpaidInvoicesSelectionViewModel = {
      addressProducts,
      clearingAccounts,
    };
    this.router.navigateByUrl(`/${UNPAID_INVOICES_ROUTE}/${UNPAID_INVOICES_SELECTION_ROUTE}`);
  }

  navigateToUnpaidInvoices(categories: Sparte[], clearingAccount: ClearingAccount, address: Address): void {
    this.stateService.categories = categories;
    this.stateService.clearingAccount = clearingAccount;
    this.stateService.address = address;
    this.router.navigateByUrl(`/${UNPAID_INVOICES_ROUTE}/${UNPAID_INVOICES_PAYMENT_OPTIONS_ROUTE}`);
  }

  async isLiveChatActive(): Promise<boolean> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.FIND_PAYMENT_SOLUTION_LIVECHAT_ENABLED)) {
      return false;
    }

    try {
      const config = await this.staticContentService.loadLiveChatConfiguration();
      const currentDateTime = dayjs();
      const currentDay = currentDateTime.day();
      const openingTimes = this.getCorrectLiveChatOpeningTimes(currentDay, config);

      if (!openingTimes) {
        return false;
      }

      const fromDateTime = dayjs()
        .hour(parseInt(openingTimes.openFrom.split(':')[0]))
        .minute(parseInt(openingTimes.openFrom.split(':')[1]));

      const untilDateTime = dayjs()
        .hour(parseInt(openingTimes.openUntil.split(':')[0]))
        .minute(parseInt(openingTimes.openUntil.split(':')[1]))
        .subtract(30, 'minutes');

      return currentDateTime.isAfter(fromDateTime) && currentDateTime.isBefore(untilDateTime);
    } catch (e) {
      this.loggingService.logError(e, 'error on loading chatbot configuration');
      return false;
    }
  }

  private getCorrectLiveChatOpeningTimes(currentDayIndex: number, config: ILiveChatConfiguration): ILiveChatConfigurationOpeningTimes {
    if (currentDayIndex === 0 || currentDayIndex === 6) {
      return null;
    }

    if (currentDayIndex === 5) {
      return config.friday;
    }

    return config.mondayToThursday;
  }
}

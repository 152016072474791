import { GetAddressPostCodeAndStreetPipe } from './get-address-post-code-and-street.pipe';
import { GetAddressStreetAndPostCodePipe } from './get-address-street-and-post-code.pipe';
import { GetAddressPostCodePipe } from './get-address-post-code.pipe';
import { GetAddressStreetPipe } from './get-address-street.pipe';

export * from './get-address-post-code.pipe';
export * from './get-address-post-code-and-street.pipe';
export * from './get-address-street.pipe';
export * from './get-address-street-and-post-code.pipe';

export const allAddressPipes = [
  GetAddressPostCodePipe,
  GetAddressPostCodeAndStreetPipe,
  GetAddressStreetPipe,
  GetAddressStreetAndPostCodePipe,
];

import { Produkt, Zaehlpunkt } from '../invoices/invoice-overview.model';
import { Address, createAddress } from '../address/address.model';
import { TarifOption } from '../availabilities/availabilities.model';
import { Verbrauchsstelle } from './tarifwechsel.model';

export enum EingangsZahlweg {
  Sepa = 'SEPA-Mandat',
  Zahlschein = 'Zahlschein',
}

export interface ICustomerVertragskonto {
  vertragskontoId: string;
  status: string;
  geschaeftspartnerBeziehungen: IGeschaeftspartnerBeziehung[];
  vertraege: IVertrag[];
  eingangskonto: ICustomerBankKonto;
  ausgangskonto: ICustomerBankKonto;
  eingangszahlweg: string;
}

export interface ICustomerBankKonto {
  iban?: string;
  inhaber?: string;
  swift?: string;
  bank?: {
    land: string;
  };
}

export enum GeschaeftsPartnerArt {
  S001 = <any>'WSTW-Kunde',
  SWEG = <any>'WE GmbH Kunde',
  G001 = <any>'Grosskunde',
  RDAW = <any>'Druck: Abw. Empf.',
  RDGP = <any>'Druck: abw. Empf. UID von GP',
  SVHV = <any>'Hausverwaltung',
  VPAW = <any>'Druck: VP - UID von abw. Empf',
  SV01 = <any>'WSTW SV - Tarifkunde',
}

export interface IGeschaeftspartnerBeziehung {
  geschaeftsPartnerId?: string;
  geschaeftsPartnerArt?: string;
  systemId?: string;
  kundensegment?: string;
  beziehungsTyp?: string;
  adresse?: ICustomerAdressDetails;
  adressen?: IAdressDetails[];
  email?: { eMail: string };
  telefon?: { land: string; nummer: string; typ?: string; adressabhaengig?: string };
  organisation?: IOrganisation;
  physischePerson?: IPhysischePerson;
  kontaktdetails?: IKontaktDetails[];
  bankkonten?: IBankkontenDetails[];
  verrechnungskonten?: AbrechnungsKonstrukt[];
}

export interface ICustomerAdressDetails {
  adressart?: string;
  adresshinweis?: string;
  adresszusatz?: string;
  strasse: string;
  hausnummer: string;
  hausnummerZusatz?: string;
  land?: string;
  ort: string;
  plz: string;
  laendercode?: string;
  tuernummer?: string;
  raumnummer?: string;
  stock?: string;
  postfach?: string;
  ortsteil?: string;
  bundesland?: string;
  standard?: string;
  stiege?: string;
  olavGaa?: string;
  olavGba?: string;
  olavAna?: string;
  olavAaa?: string;
}

export type VertragsStatusType = 'Aktiv' | 'Beendet';

export interface IVertrag {
  anlage: IAnlage;
  sparte: string;
  tarif: { tarifName: string; tarifTyp?: string; tarifOptionen?: TarifOption[]; tarifKey?: string };
  vertragsnummer: string;
  verrechnungssKonto?: string;
  vertragsbeginn?: string;
  vertragsende?: string;
  einzugsDatum?: string;
  auszugsDatum?: string;
  preisStichtag?: string;
  vertragsstatus: VertragsStatusType;
  kartenNummer?: string;
  fruehestesTarifwechselDatum?: string;
}

interface IAnlage {
  anlageId: string;
  verbrauchsstelle: IVerbrauchsstelle;
  zaehlpunkt?: Zaehlpunkt;
  abDatum?: string;
}

export interface IVerbrauchsstelle {
  verbrauchsstelleId?: string;
  verbrauchsstelle: ICustomerAdressDetails;
  anlageId?: string;
  anschlussobjektnummer?: string;
  aenderungsDatum?: string;
  geraete?: Geraet[];
}

export interface IGeschaeftsPartner {
  adressen: IAdressDetails[];
  bankkonten: IBankkontenDetails[];
  geschaeftsPartnerId: string;
  kontaktdetails: IKontaktDetails[];
  kundensegment: string;
  nickname: string;
  organisation: IOrganisation;
  physischePerson: IPhysischePerson;
  systemId: string;
  geburtsDatum?: string;
}

export interface IOrganisation {
  organisationsArt?: string;
  dvrNummer?: string;
  gruendungsdatumt?: string;
  liquidationsdatumt?: string;
  gruendungsdatum?: string;
  rechtsform?: string;
  liquidationsdatum?: string;
  istStaatlicherBereich?: boolean;
  WEVerbundeneOrganisation?: string;
  organisationsname?: string;
  organisationsname2?: string;
  markenname?: string;
  standortanzahl?: number;
  gerichtsstand?: string;
  taetigkeitsBereich?: string;
  umsatzsteuerId?: string;
  steuernummer?: string;
  firmenbuchnummer?: string;
  lfbisnummer?: string;
  dunsnummer?: string;
  zvrzahl?: string;
  genossenschaftsregisternummer?: string;
  steueridentifikationsnummer?: string;
  umsatz?: number;
}

export interface IAdressDetails {
  adressart?: string;
  bundesland?: string;
  hausnummer?: string;
  laendercode?: string;
  land?: string;
  ort: string;
  ortsteil?: string;
  plz: string;
  postfach?: string;
  raumnummer?: string;
  stiege: string;
  stock?: string;
  strasse: string;
  tuernummer: string;
  adresszusatz?: string;
}

export interface IBankkontenDetails {
  bank: { bankleitzahl: string };
  iban: string;
  inhaber: string;
  swift: string;
}

export interface IKontaktDetails {
  email?: { eMail: string; standard?: boolean };
  fax?: { land: string; nummer: string };
  telefon?: { land: string; nummer: string; typ?: string; adressabhaengig?: boolean; standard?: string };
}

export interface IPhysischePerson {
  anrede?: string;
  familienstand?: string;
  geburtsdatum?: string;
  sterbedatum?: string;
  geschlecht?: string;
  nachname?: string;
  staatsbuergerschaft?: string;
  titel?: string;
  titelNachgestellt?: string;
  titelVorgestellt?: string;
  vorname?: string;
}

export const parseILCustomerAddress = (addressIn?: ICustomerAdressDetails): Address => {
  return createAddress({
    id: null,
    street: addressIn.strasse,
    streetNumber: addressIn.hausnummer,
    block: addressIn.stiege,
    doorNumber: addressIn.raumnummer,
    hint: addressIn.adresshinweis,
    postcode: addressIn.plz,
    city: addressIn.ort,
    country: addressIn.land,
  });
};

export interface GeschaeftsPartner {
  geschaeftsPartner: IGeschaeftspartnerBeziehung[];
}

export interface VertragDetails {
  vertragsnummer: string;
  bezeichnung?: string;
  verrechnungssKonto: string;
  geschaeftsPartnerId: string;
  sparte: string;
  vertragsbeginn?: string;
  vertragsende?: string;
  vertragsstatus?: string;
  einzugsDatum?: string;
  auszugsDatum?: string;
  kuendigungsFrist?: number;
  kuendigungsFristEinheit?: string;
  kuendigungsDatum: string;
  verlaengerungsDatum?: string;
  verlaengerungsFrist?: number;
  verlaengerungsFristEinheit?: string;
  gemeinsameFakturierung?: string;
  abrechnungsKonstrukt: AbrechnungsKonstrukt;
  bestellteProdukte: BestellteProdukte[];
}

export interface AbrechnungsKonstrukt {
  id: string;
  idBezeichnung?: string;
  sparte?: string;
  versorgteEinheit?: VersorgteEinheit;
  zaehlpunkt?: Zaehlpunkt;
  konditionen?: VertragsKonditionen[];
}

export interface VersorgteEinheit {
  verbrauchsstelle: Verbrauchsstelle;
}

export interface VertragsKonditionen {
  typ: string;
  operand: string;
  bezeichnung: string;
  faktor: number;
  aktiv: boolean;
  gueltigVon: string;
  gueltigBis: string;
}

export interface BestellteProdukte {
  produkt: Produkt;
  ab?: string;
  bis?: string;
}

export interface VertragQueryParams {
  sparte?: string;
  sysId?: string;
  nurAktive?: string;
  // exactly one needs to be provided
  verbrauchsstellenId?: string;
  zaehlpunktnummer?: string;
  verrechnungskontoId?: string;
  olavAaa?: string;
  tkconnid?: string;
}

export interface VersorgteEinheiten {
  verbrauchsstelle: IVerbrauchsstelle;
}

export interface Geraet {
  typ: string;
  beschreibung: string;
  art: string;
}

import { Component, computed, Host, input, OnInit, Optional, viewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IFormInput, Sparte } from '@mwe/models';
import { FormComponent } from '../../form.component';

@Component({
  selector: 'mwe-selectable-card-checkbox-input',
  templateUrl: './selectable-card-checkbox-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectableCardCheckboxInputComponent,
      multi: true,
    },
  ],
})
export class SelectableCardCheckboxInputComponent implements ControlValueAccessor, OnInit {
  parentForm = input<UntypedFormGroup>();
  inputElem = input<IFormInput>();
  isLoading = input<boolean>(false);
  formControlDirective = viewChild<FormControlDirective>(FormControlDirective);

  ariaDescribedBy = computed<string | undefined>(() => {
    if (this.formComponent) {
      // Error State hat Vorrang für Screenreader
      if (this.formComponent.isInputErrorVisible(this.inputElem().componentType)) {
        return `form-input-error--${this.inputElem().name}`;
      } else if (this.formComponent.isInputHelpInfoVisible(this.inputElem())) {
        return `form-input-info--${this.inputElem().name}`;
      }
    }
    return undefined;
  });

  isValid = computed<boolean>(() => {
    return this.formControlDirective()?.control?.valid;
  });

  isRequired: boolean;

  constructor(@Optional() @Host() private formComponent?: FormComponent) {}

  ngOnInit() {
    this.isRequired = this.inputElem().validators?.indexOf(Validators.required) > -1;
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective().valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective().valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective().valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective().valueAccessor.setDisabledState(isDisabled);
  }
}

<header class="bg-primary" style="height: 60px">
  <div class="row h-100 justify-content-between align-items-center flex-nowrap">
    <div class="col-auto flex-shrink-1 minw-0">
      <div class="d-flex align-items-center">
        <mwe-header-logo-image aria-hidden="true" class="d-block ml-3" />
        <div class="h6 mb-0 text-white text-truncate" data-test="mwe-wizard-header-label">{{ titleKeyTranslated }}</div>
      </div>
    </div>
    <div class="col-auto">
      <button
        class="btn btn-clean text-white mr-3 focus-border-large"
        *ngIf="isClosingAllowed"
        id="mwe-wizard-header-close"
        data-test="mwe-wizard-header-close"
        (click)="cancelOnHeader()"
        [title]="'shared.wizard.header.cancelAria' | translate: { name: titleKeyTranslated }"
      >
        <i class="fas fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</header>

import { IMultiProductCard, Product } from '../products/product.model';
import { AddressGroup } from './address-group.model';

export interface Address {
  id: any;
  street: string;
  streetNumber: string;
  block: string;
  doorNumber: string;
  hint?: string;
  postcode: string;
  city: string;
  country: string;
  hashCode?: string;
  addressGroup?: AddressGroup;
}

export const createAddress = (input: string | Address): Address => {
  if (!input) return null;
  try {
    const parsedInput = (typeof input === 'string' ? JSON.parse(input) : input) as Address;

    const newAddress = {
      id: parsedInput?.id,
      street: parsedInput?.street,
      streetNumber: parsedInput?.streetNumber,
      block: parsedInput?.block,
      doorNumber: parsedInput?.doorNumber,
      hint: parsedInput?.hint,
      postcode: parsedInput?.postcode,
      city: parsedInput?.city,
      country: parsedInput?.country,
    } as Address;

    newAddress.hashCode = calculateAddressHashCode(newAddress);

    newAddress.addressGroup = {
      addressCode: parsedInput?.addressGroup?.addressCode ?? newAddress.hashCode,
      createdAt: parsedInput?.addressGroup?.createdAt ?? null,
      customerType: parsedInput?.addressGroup?.customerType ?? null,
      detailsLinkType: parsedInput?.addressGroup?.detailsLinkType ?? null,
      groupName: parsedInput?.addressGroup?.groupName ?? null,
      id: parsedInput?.addressGroup?.id ?? null,
      matchedGroup: parsedInput?.addressGroup?.matchedGroup ?? null,
      modifiedAt: parsedInput?.addressGroup?.modifiedAt ?? null,
      userId: parsedInput?.addressGroup?.userId ?? null,
    };
    return newAddress;
  } catch (e) {
    return null;
  }
};

const calculateAddressHashCode = (address: Address): string => {
  if (!address) {
    return '';
  }

  const hashValue = (value: string): string => {
    if (value) {
      return value;
    }
    return '@';
  };

  let hash = `${hashValue(address.postcode)}_${hashValue(address.street)}_${hashValue(address.streetNumber)}`;
  if (address.block) {
    hash = hash.concat(`/${hashValue(address.block)}`);
  }

  if (address.doorNumber) {
    hash = hash.concat(`/${hashValue(address.doorNumber)}`);
  }

  return hash.replaceAll(/\s*\/\s*/g, '/');
};

export interface IOlavEntry {
  plz: string;
  plzOrt?: string[];
  gemeinde: string;
  isuKZS: string;
  strasse: string;
  identStrasse?: string;
  gebadr1: string;
  identGebadr1?: string;
  anladr1: string;
  tuerNr?: string;
  gebaeudeKey: string;
  gebaeudeKeyIdent: string;
  anlageAdressKey: string;
  anlageKey: string;
  olavKey?: string;
  origOlavKey?: string;
  vstelle?: string;
  identAdresse?: IOlavEntry;
  tuerNrZusatz?: string;
  staat?: string;
  alleEintraegeKZ?: boolean;
  anlagenSucheKZ?: boolean;
  internetTarifKZ?: boolean;
  _version_?: number;
}

export interface IOlavKey {
  gebaeudeKey: string;
  gebaeudeKeyIdent: string;
  anlageAdressKey: string;
  anlageKey: string;
}

export interface ISolrResponse {
  responseHeader: any;
  grouped: {
    groupValue?: string;
    gemeinde?: ISolrGroup;
    strasse?: ISolrGroup;
    gebadr1?: ISolrGroup;
    tuerNr?: ISolrGroup;
    anladr1?: ISolrGroup;
    plz?: ISolrGroup;
  };
  facet_counts?: {
    facet_pivot: {
      'strasse,gemeinde,plz': ISolrPivot[];
    };
  };
}

export interface ISolrPivot {
  count: number;
  field: string;
  value: string;
  pivot: ISolrPivot[];
}

export interface ISolrGroup {
  matches: number;
  groups: {
    groupValue: string;
    doclist: ISolrDocList;
  }[];
}

export interface ISolrDocList {
  numFound: number;
  identNumFound?: number;
  start: number;
  docs: IOlavEntry[];
  groupValue?: string;
}

export interface ISolrOlavKeyAddress {
  numFound: number;
  docs: IOlavEntry[];
  start: number;
}

export interface AddressSelectionEntry {
  text: string;
  state: SelectionState;
}

export enum SelectionState {
  INITIAL = 'initial',
  LOCATION = 'location',
  STREET = 'street',
  BUILDING = 'building',
  DOOR = 'door',
  COMPLETE = 'complete',
}

export interface AddressProducts {
  address: Address;
  products: Product[];
  // can be removed once we have ChangeDetection.OnPush and StateManagement
  areSomeProductsWhiteListed?: boolean;
}

export interface AddressProductsSelectedEvent {
  addressProduct: AddressProducts;
  selectionWasSkippedDueToOneAddress: boolean;
}

export interface AddressMultiProductCards {
  address: Address;
  multiProductCards: IMultiProductCard[];
}

export class CoverageArea {
  bezirk: number;
  gemeinde: string;

  constructor(bezirk: number, gemeinde: string) {
    this.bezirk = bezirk;
    this.gemeinde = gemeinde;
  }
}

export const availableZipCodes = [
  '1010',
  '1020',
  '1030',
  '1040',
  '1050',
  '1060',
  '1070',
  '1080',
  '1090',
  '1100',
  '1110',
  '1120',
  '1130',
  '1140',
  '1150',
  '1160',
  '1170',
  '1180',
  '1190',
  '1200',
  '1210',
  '1220',
  '1230',
  '1300',
  '2100',
  '2102',
  '2103',
  '2201',
  '2202',
  '2203',
  '2232',
  '2280',
  '2281',
  '2282',
  '2301',
  '2304',
  '2320',
  '2322',
  '2325',
  '2326',
  '2331',
  '2332',
  '2333',
  '2334',
  '2340',
  '2344',
  '2345',
  '2351',
  '2352',
  '2353',
  '2355',
  '2361',
  '2362',
  '2371',
  '2372',
  '2380',
  '2381',
  '2384',
  '2391',
  '2392',
  '2393',
  '2401',
  '2431',
  '2432',
  '2435',
  '2440',
  '2441',
  '2442',
  '2481',
  '2482',
  '2483',
  '2484',
  '2485',
  '2486',
  '2490',
  '2491',
  '2492',
  '2493',
  '2500',
  '2504',
  '2511',
  '2512',
  '2514',
  '2521',
  '2522',
  '2523',
  '2524',
  '2525',
  '2531',
  '2540',
  '2542',
  '2544',
  '2551',
  '2552',
  '2601',
  '2602',
  '2603',
  '2604',
  '2751',
  '3001',
  '3002',
  '3003',
  '3011',
  '3012',
  '3264',
  '3291',
  '3292',
  '3293',
  '3342',
  '3343',
  '3344',
  '3345',
  '3400',
  '3420',
  '3421',
  '3423',
];

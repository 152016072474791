import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  SEPA_INVOICE_CHANGE_FORM_ROUTE,
  SEPA_INVOICE_CHANGE_ROUTE,
  INVOICE_ADDRESS_CHANGE_FORM_ROUTE,
  INVOICE_ADDRESS_CHANGE_ROUTE,
  PDF_INVOICE_CHANGE_FORM_ROUTE,
  PDF_INVOICE_CHANGE_ROUTE,
} from '@mwe/constants';
import { Address, CommonMappingData, DataChangeType, IMultiProductCard, InvoiceDataChangeDTO, Product } from '@mwe/models';
import { everyTrue, isArrayWithMinOneItem } from '@mwe/utils';
import { InvoiceDataChangeStateService } from './invoice-data-change-state.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataChangeLogic {
  constructor(
    private router: Router,
    private stateService: InvoiceDataChangeStateService,
  ) {}

  startBankAccountChangeWithAddressSelection(): void {
    this.stateService.isAddressSelectionVisible = true;
    this.router.navigateByUrl(`/${SEPA_INVOICE_CHANGE_ROUTE}`);
  }

  startBankAccountChangeWithoutAddressSelection(products: Product[], address: Address): void {
    this.stateService.products = products;
    this.stateService.address = address;
    this.stateService.isAddressSelectionVisible = false;
    this.router.navigateByUrl(`/${SEPA_INVOICE_CHANGE_ROUTE}/${SEPA_INVOICE_CHANGE_FORM_ROUTE}`);
  }

  startPdfInvoiceChangeWithAddressSelection(): void {
    this.startProcessWithAddressSelection(`/${PDF_INVOICE_CHANGE_ROUTE}`);
  }

  startPdfInvoiceChangeWithoutAddressSelection(products: Product[], address: Address): void {
    this.startProcessWithoutAddressSelection(`/${PDF_INVOICE_CHANGE_ROUTE}/${PDF_INVOICE_CHANGE_FORM_ROUTE}`, products, address);
  }

  startInvoiceAddressChangeWithAddressSelection(): void {
    this.startProcessWithAddressSelection(`/${INVOICE_ADDRESS_CHANGE_ROUTE}`);
  }

  startInvoiceAddressChangeWithoutAddressSelection(products: Product[], address: Address): void {
    this.startProcessWithoutAddressSelection(`/${INVOICE_ADDRESS_CHANGE_ROUTE}/${INVOICE_ADDRESS_CHANGE_FORM_ROUTE}`, products, address);
  }

  errorIsNotFirstError(processStatus: string, mode: DataChangeType): boolean {
    return mode === DataChangeType.BankAccountChangeOnly
      ? !processStatus.endsWith('sepa_0_error')
      : !processStatus.endsWith('erechnung_0_error');
  }

  isBankAccountChangeModeActive(): boolean {
    return (
      this.stateService.mode === DataChangeType.BankAccountChangeOnly || this.stateService.mode === DataChangeType.BankAccountAndPdfInvoice
    );
  }

  isPdfInvoiceChangeModeActive(): boolean {
    return (
      this.stateService.mode === DataChangeType.PdfInvoiceChangeOnly || this.stateService.mode === DataChangeType.BankAccountAndPdfInvoice
    );
  }

  isInvoiceAddressChangeModeActive(): boolean {
    return this.stateService.mode === DataChangeType.InvoiceAddressChangeOnly;
  }

  getI18NChangeType(dataDTO: InvoiceDataChangeDTO): string {
    let changeTypeI18nKey = 'invoiceData.change.all';
    if (everyTrue(!!dataDTO.eRechnungsInfo, !dataDTO.sepaMandat, !dataDTO.rechnungsAdresse)) {
      changeTypeI18nKey = 'invoiceData.change.eRechnungOnly';
    } else if (everyTrue(!dataDTO.eRechnungsInfo, !!dataDTO.sepaMandat, !dataDTO.rechnungsAdresse)) {
      changeTypeI18nKey = 'invoiceData.change.sepaOnly';
    } else if (everyTrue(!dataDTO.eRechnungsInfo, !dataDTO.sepaMandat, !!dataDTO.rechnungsAdresse)) {
      changeTypeI18nKey = 'invoiceData.change.rechnungsAdresseOnly';
    }
    return changeTypeI18nKey;
  }

  setAccountCategoriesMapping(cards: IMultiProductCard[], products: Product[]): void {
    this.stateService.accountCategoriesMapping = this.mapProductsToAccountCategoryMapping(
      cards.map(c => c.accountNumber),
      products,
    );
  }

  mapProductsToAccountCategoryMapping(selectedAccountNumbers: string[], products: Product[]): CommonMappingData[] {
    return selectedAccountNumbers.map(accountNumber => {
      const correspondingProducts: Product[] = products.filter(product => product.accountNumber === accountNumber);
      const customerNumber = this.getCustomerNumber(correspondingProducts[0]);
      const businessPartnerNumber = correspondingProducts[0].businessPartnerNumber;
      const productCategories: string[] = correspondingProducts.map(product => product.category);
      return {
        accountNumber,
        customerNumber,
        businessPartnerNumber,
        categories: productCategories,
      };
    });
  }

  getCustomerNumber(product: Product): string {
    return product.customerNumber;
  }

  hasFormStepAllNeededData(): boolean {
    return !!this.stateService.address && isArrayWithMinOneItem(this.stateService.products);
  }

  private startProcessWithAddressSelection(route: string): void {
    this.stateService.isAddressSelectionVisible = true;
    this.router.navigateByUrl(route);
  }

  private startProcessWithoutAddressSelection(route: string, products: Product[], address: Address): void {
    this.stateService.products = products;
    this.stateService.address = address;
    this.stateService.isAddressSelectionVisible = false;
    this.router.navigateByUrl(route);
  }
}

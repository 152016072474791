{
  "availability": {
    "strom": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "adoptedProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "adoptedProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "adoptedProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "adoptedProducts"
      }
    ],
    "gas": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "adoptedProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "furtherProducts"
      }
    ],
    "fernwaerme": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": "furtherProducts"
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "adoptedProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "furtherProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "furtherProducts"
      }
    ],
    "nachtstrom": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "notAvailableProducts"
      }
    ],
    "stromrueck": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "notAvailableProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "notAvailableProducts"
      }
    ],
    "emobility": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "noOnlineProducts"
      }
    ],
    "internet": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "noOnlineProducts"
      }
    ],
    "iptv": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "noOnlineProducts"
      }
    ],
    "voip": [
      {
        "currentAddress": false,
        "newAddress": "JA",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "NEIN",
        "display": "onlineNotAvailable"
      },
      {
        "currentAddress": false,
        "newAddress": "UNBEKANNT",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": null
      },
      {
        "currentAddress": false,
        "newAddress": "IM_GEBAEUDE",
        "display": null
      },
      {
        "currentAddress": true,
        "newAddress": "JA",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "NEIN",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "UNBEKANNT",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "TECHNISCHER_FEHLER",
        "display": "noOnlineProducts"
      },
      {
        "currentAddress": true,
        "newAddress": "IM_GEBAEUDE",
        "display": "notAvailableProducts"
      }
    ]
  },
  "tariffs": {
    "strom": {
      "privat": {
        "name": "OPTIMA Entspannt",
        "shortcut": "SOPTB_0001",
        "key": "optimaBasic"
      },
      "business": {
        "name": "Strom MEGA",
        "shortcut": "SMEGE_0001",
        "key": "megaEntspannt"
      }
    },
    "gas": {
      "privat": {
        "name": "OPTIMA Entspannt",
        "shortcut": "GEOPTB__01",
        "key": "optimaBasic"
      },
      "business": {
        "name": "Gas MEGA",
        "shortcut": "GEMEGE__01",
        "key": "megaEntspannt"
      }
    },
    "internet": {
      "privat": {
        "name": "SuperSchnell 500",
        "shortcut": "WEBTK24FIBER500",
        "key": "WEBTK24FIBER500"
      },
      "business": {
        "name": "N/A",
        "shortcut": "N/A",
        "key": "N/A"
      }
    },
    "voip": {
      "privat": {
        "name": "SuperSchnell Festnetz-Telefonie Ö 500",
        "shortcut": "WEBTELÖ500",
        "key": "WEBTELÖ500"
      },
      "business": {
        "name": "N/A",
        "shortcut": "N/A",
        "key": "N/A"
      }
    },
    "iptvPremium": {
      "privat": {
        "name": "SuperSchnell Premium TV",
        "shortcut": "WEBIPTVPREMIUM",
        "key": "WEBIPTVPREMIUM"
      },
      "business": {
        "name": "N/A",
        "shortcut": "N/A",
        "key": "N/A"
      }
    },
    "iptvLight": {
      "privat": {
        "name": "SuperSchnell Light TV",
        "shortcut": "WEBIPTVLIGHT",
        "key": "WEBIPTVLIGHT"
      },
      "business": {
        "name": "N/A",
        "shortcut": "N/A",
        "key": "N/A"
      }
    },
    "fernwaerme": {
      "privat": {
        "name": "",
        "shortcut": "",
        "key": ""
      },
      "business": {
        "name": "",
        "shortcut": "",
        "key": ""
      }
    }
  },
  "possibleCategories": ["Strom", "Gas", "Fernwaerme", "Internet", "Iptv"],
  "multiProductCardCategories": { "Internet": "Internet", "Iptv": "Internet" }
}

import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { ClearingAccountLogic, ProfileService } from '@mwe/services';
import { ActivePaymentPlanRouteData, Address, Product, Ratenplan } from '@mwe/models';
import { Router } from '@angular/router';
import { ACTIVE_PAYMENT_PLAN_ROUTE, FeatureToggleEnum, PRIVATE_ROUTE } from '@mwe/constants';

@Component({
  selector: 'mwe-active-payment-plan-alert',
  templateUrl: './active-payment-plan-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivePaymentPlanAlertComponent implements OnInit {
  products = input.required<Product[]>();
  address = input.required<Address>();
  linkClicked = output<void>();

  paymentPlan = signal<Ratenplan>(null);

  private logic = inject(ClearingAccountLogic);
  private router = inject(Router);
  private profileService = inject(ProfileService);

  async ngOnInit(): Promise<void> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.ACTIVE_PAYMENT_PLAN_DATA_ENABLED)) {
      return;
    }

    // no error handling, just keep info-alert invisible
    const response = await this.logic.getActivePaymentPlan(this.products());
    this.paymentPlan.set(response);
  }

  onActivePaymentLinkClicked(): void {
    this.linkClicked.emit();

    const state: ActivePaymentPlanRouteData = {
      paymentPlan: this.paymentPlan(),
      addressProducts: { address: this.address(), products: this.products() },
    };
    this.router.navigateByUrl(`${PRIVATE_ROUTE}/${ACTIVE_PAYMENT_PLAN_ROUTE}`, { state });
  }
}

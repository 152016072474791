import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Address, Product, createAddress } from '@mwe/models';
import { ProductsWhiteListLogic } from '../products-white-list.logic';

const PRODUCT_BILL_INFO_PRODUCTS_KEY = 'productBillInfo_products';
const PRODUCT_BILL_INFO_ADDRESS_KEY = 'productBillInfo_address';

@Injectable({
  providedIn: 'root',
})
export class ProductBillInfoStateService {
  constructor(
    private storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {}

  @SessionStorage(PRODUCT_BILL_INFO_PRODUCTS_KEY)
  private _products: Product[];

  get products(): Product[] {
    return this._products;
  }

  set products(products: Product[]) {
    this._products = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
  }

  @SessionStorage(PRODUCT_BILL_INFO_ADDRESS_KEY)
  private _address: Address;

  get address(): Address {
    if (!this._address) {
      return undefined;
    }

    return createAddress(this._address);
  }

  set address(address: Address) {
    this._address = address;
  }

  clearData(): void {
    this.storage.clear(PRODUCT_BILL_INFO_PRODUCTS_KEY);
    this.storage.clear(PRODUCT_BILL_INFO_ADDRESS_KEY);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Address, IMultiProductCard } from '@mwe/models';

@Component({
  selector: 'mwe-process-summary',
  templateUrl: './process-summary.component.html',
  styles: [],
})
export class ProcessSummaryComponent implements OnInit {
  @Input() header: string;
  @Input() description: string;
  @Input() address: Address;
  @Input() processTitle: string;
  @Input() productGroups: string[][];

  multiProductCards: IMultiProductCard[];

  constructor() {}

  ngOnInit(): void {
    if (this.productGroups) {
      this.multiProductCards = this.productGroups.map(group => {
        return { categories: group };
      });
    }
  }
}

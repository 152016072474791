import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { buttonColor } from '@mwe/models';

@Component({
  selector: 'mwe-button',
  templateUrl: './button.component.html',
  styles: [],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() buttonId: string;
  @Input() labelKey: string;
  @Input() buttonType: 'button' | 'submit' = 'button';
  @Input() disabled = false;
  @Input() showSpinner = false;
  @Input() emitEventWithRoute = false;
  @Output() clicked = new EventEmitter<MouseEvent>();
  @Input() hasFullWidth = false;
  @Input() route: string;
  @Input() color: buttonColor = 'orange';
  @Input() category: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() showHoverEffect = false;
  @Input() hasBlueBackground = false;
  @Input() size = 'lg' || 'md' || 'sm' || 'xs';
  @Input() title: string;
  @Input() prependIconClass: string;
  @Input() isIconBtn = false;
  @ViewChild('btnElement') buttonElement: ElementRef<HTMLElement>;

  btnCssClasses: string[];
  waveCssClass: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initCssClasses();
  }

  ngOnChanges(): void {
    this.initCssClasses();
  }

  onClick($event: MouseEvent): void {
    // $event.stopPropagation();
    if (this.disabled) {
      $event.preventDefault();
      return;
    }

    if (this.route) {
      this.router.navigateByUrl(this.route);

      if (this.emitEventWithRoute) {
        this.clicked.emit($event);
      }
      return;
    }

    this.clicked.emit($event);
  }

  private initCssClasses(): void {
    const btnTypeCss = `btn-${this.category}--${this.color}`;
    const hoverClass = this.showHoverEffect && this.category === 'primary' ? 'hover-effect' : '';
    this.btnCssClasses = ['btn', 'focus-border-large', btnTypeCss, hoverClass];

    this.waveCssClass = this.color !== 'orange' ? `wave--${this.color}` : '';

    if (this.hasFullWidth) {
      this.btnCssClasses.push('w-100');
    }

    if (this.isIconBtn) {
      this.btnCssClasses.push('btn-icon');
    }

    if (this.hasBlueBackground) {
      this.btnCssClasses.push('btn-focus-white');
    }

    if (this.size && this.size !== 'lg') {
      this.btnCssClasses.push(`btn-${this.size}`);
    }
  }
}

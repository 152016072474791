export const SYM_SIGNAL_STATE_PROPERTIES = Symbol('SYM_SIGNAL_STATE_PROPERTY_FLAG');
export type SignalPropertyOptions = object;
export type SignalPropertyDefinition = {
  propertyKey: string | symbol;
  options?: SignalPropertyOptions;
};
export function SignalStateProperty(options?: SignalPropertyOptions) {
  return function (constructor: object, propertyKey: string | symbol) {
    // Append Metadata to the constructor. Can also be handled by a map outside of the class. But this way it is more encapsulated.
    if (!constructor.constructor[SYM_SIGNAL_STATE_PROPERTIES]) {
      constructor.constructor[SYM_SIGNAL_STATE_PROPERTIES] = [] as SignalPropertyDefinition[];
    }
    constructor.constructor[SYM_SIGNAL_STATE_PROPERTIES].push({ propertyKey, options });
  };
}

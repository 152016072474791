import { AfterViewInit, Component, computed, input, OnInit, signal, viewChild } from '@angular/core';
import { IFormInput, IFormModel, IMultiProductCard, Product } from '@mwe/models';
import { createMultiProductCardsFromProducts, isArrayWithOneItem } from '@mwe/utils';
import { AbstractControl } from '@angular/forms';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'mwe-select-products-form',
  templateUrl: './select-products-form.component.html',
})
export class SelectProductsFormComponent implements OnInit, AfterViewInit {
  products = input<Product[]>([]);
  errorLabel = input<string>('');
  isReadOnly = input<boolean>(false);
  form = viewChild<FormComponent>(FormComponent);

  multiProductCards = computed<IMultiProductCard[]>(() => {
    return createMultiProductCardsFromProducts(this.products());
  });

  isErrorVisible = signal<boolean>(false);
  formModel: IFormModel;

  private inputNamePrefix = 'select-product-';

  ngOnInit() {
    this.createFormModel();
  }

  ngAfterViewInit(): void {
    this.getSelectedProducts();
  }

  getSelectedProducts(): IMultiProductCard[] {
    return this.multiProductCards().filter(c => {
      const formControlValue = this.getFormField(c.accountNumber).value;
      return !!formControlValue;
    });
  }

  onFormChanged() {
    this.isErrorVisible.set(this.getSelectedProducts().length === 0);
    this.setFormError();
  }

  validate(): boolean {
    this.form().fireValidation();
    return this.form().isValid();
  }

  createFormModel(): void {
    this.formModel = {
      updateOn: 'change',
      inputs: [
        {
          name: 'selectable-categories',
          inputs: this.multiProductCards().map(card => {
            return {
              name: this.getFormFieldName(card.accountNumber),
              initialValue: 'true',
              inputClasses: 'col-md-6',
              validate: true,
              componentType: 'selectable-card-check',
              content: {
                categories: card.categories,
              },
              isReadonly: this.isReadOnly() || isArrayWithOneItem(this.multiProductCards()),
            } as IFormInput;
          }),
        },
      ],
    };
  }

  private setFormError(): void {
    // no card selected -> invalid
    // we only have one error-message, so we have to mimick some form-validation
    this.multiProductCards().forEach(c => {
      const error = this.isErrorVisible() ? { nothingSelected: true } : null;
      this.getFormField(c.accountNumber).setErrors(error);
    });
  }

  private getFormField(accountNumber): AbstractControl {
    const formFieldName = this.getFormFieldName(accountNumber);
    return this.form().form.get(formFieldName);
  }

  private getFormFieldName(accountNumber: string): string {
    return `${this.inputNamePrefix}${accountNumber}`;
  }
}

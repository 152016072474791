[
  {
    "name": "Afghanistan",
    "dialingNumber": "+93",
    "countryCode": "AF"
  },
  {
    "name": "Ägypten",
    "dialingNumber": "+20",
    "countryCode": "EG"
  },
  {
    "name": "Albanien",
    "dialingNumber": "+355",
    "countryCode": "AL"
  },
  {
    "name": "Algerien",
    "dialingNumber": "+213",
    "countryCode": "DZ"
  },
  {
    "name": "Amerikanisch-Samoa",
    "dialingNumber": "+1684",
    "countryCode": "AS"
  },
  {
    "name": "Andorra",
    "dialingNumber": "+376",
    "countryCode": "AD"
  },
  {
    "name": "Angola",
    "dialingNumber": "+244",
    "countryCode": "AO"
  },
  {
    "name": "Anguilla",
    "dialingNumber": "+1264",
    "countryCode": "AI"
  },
  {
    "name": "Antigua und Barbuda",
    "dialingNumber": "+1268",
    "countryCode": "AG"
  },
  {
    "name": "Äquatorial-Guinea",
    "dialingNumber": "+240",
    "countryCode": "GQ"
  },
  {
    "name": "Argentinien",
    "dialingNumber": "+54",
    "countryCode": "AR"
  },
  {
    "name": "Armenien",
    "dialingNumber": "+374",
    "countryCode": "AM"
  },
  {
    "name": "Aruba",
    "dialingNumber": "+297",
    "countryCode": "AW"
  },
  {
    "name": "Ascensioninsel",
    "dialingNumber": "+247",
    "countryCode": "AC"
  },
  {
    "name": "AserbaidsChan",
    "dialingNumber": "+994",
    "countryCode": "AZ"
  },
  {
    "name": "Äthiopien",
    "dialingNumber": "+251",
    "countryCode": "ET"
  },
  {
    "name": "Australien",
    "dialingNumber": "+61",
    "countryCode": "AU"
  },
  {
    "name": "Bahamas",
    "dialingNumber": "+1242",
    "countryCode": "BS"
  },
  {
    "name": "Bahrain",
    "dialingNumber": "+973",
    "countryCode": "BH"
  },
  {
    "name": "Bangladesch",
    "dialingNumber": "+880",
    "countryCode": "BD"
  },
  {
    "name": "Barbados",
    "dialingNumber": "+1246",
    "countryCode": "BB"
  },
  {
    "name": "Belgien",
    "dialingNumber": "+32",
    "countryCode": "BE"
  },
  {
    "name": "Belize",
    "dialingNumber": "+501",
    "countryCode": "BZ"
  },
  {
    "name": "Benin",
    "dialingNumber": "+229",
    "countryCode": "BJ"
  },
  {
    "name": "Bermuda",
    "dialingNumber": "+1441",
    "countryCode": "BM"
  },
  {
    "name": "Bhutan",
    "dialingNumber": "+975",
    "countryCode": "BT"
  },
  {
    "name": "Bolivien",
    "dialingNumber": "+591",
    "countryCode": "BO"
  },
  {
    "name": "Bosnien und Herzegowina",
    "dialingNumber": "+387",
    "countryCode": "BA"
  },
  {
    "name": "Botswana",
    "dialingNumber": "+267",
    "countryCode": "BW"
  },
  {
    "name": "Brasilien",
    "dialingNumber": "+55",
    "countryCode": "BR"
  },
  {
    "name": "Brunei",
    "dialingNumber": "+673",
    "countryCode": "BN"
  },
  {
    "name": "Bulgarien",
    "dialingNumber": "+359",
    "countryCode": "BG"
  },
  {
    "name": "Burkina Faso",
    "dialingNumber": "+226",
    "countryCode": "BF"
  },
  {
    "name": "Burundi",
    "dialingNumber": "+257",
    "countryCode": "BI"
  },
  {
    "name": "Kaimaninseln",
    "dialingNumber": "+1345",
    "countryCode": "KY"
  },
  {
    "name": "Chagos-Archipel (Diego Garcia)",
    "dialingNumber": "+246",
    "countryCode": "DG"
  },
  {
    "name": "Chile",
    "dialingNumber": "+56",
    "countryCode": "CL"
  },
  {
    "name": "China",
    "dialingNumber": "+86",
    "countryCode": "CN"
  },
  {
    "name": "Cook-Inseln",
    "dialingNumber": "+682",
    "countryCode": "CK"
  },
  {
    "name": "Costa Rica",
    "dialingNumber": "+506",
    "countryCode": "CR"
  },
  {
    "name": "Dänemark",
    "dialingNumber": "+45",
    "countryCode": "DK"
  },
  {
    "name": "Deutschland",
    "dialingNumber": "+49",
    "countryCode": "DE"
  },
  {
    "name": "Dschibuti",
    "dialingNumber": "+253",
    "countryCode": "DJ"
  },
  {
    "name": "Dominica",
    "dialingNumber": "+1767",
    "countryCode": "DM"
  },
  {
    "name": "Dominikanische Republik",
    "dialingNumber": "+1809",
    "countryCode": "DO"
  },
  {
    "name": "Ecuador",
    "dialingNumber": "+593",
    "countryCode": "EC"
  },
  {
    "name": "El Salvador",
    "dialingNumber": "+503",
    "countryCode": "SV"
  },
  {
    "name": "Eritrea",
    "dialingNumber": "+291",
    "countryCode": "ER"
  },
  {
    "name": "Estland",
    "dialingNumber": "+372",
    "countryCode": "EE"
  },
  {
    "name": "Falkland-Inseln",
    "dialingNumber": "+500",
    "countryCode": "FK"
  },
  {
    "name": "Färöer-Inseln",
    "dialingNumber": "+298",
    "countryCode": "FO"
  },
  {
    "name": "Fidschi",
    "dialingNumber": "+679",
    "countryCode": "FJ"
  },
  {
    "name": "Finnland",
    "dialingNumber": "+358",
    "countryCode": "FI"
  },
  {
    "name": "Föderierte Staaten von Mikronesien",
    "dialingNumber": "+691",
    "countryCode": "FM"
  },
  {
    "name": "Frankreich",
    "dialingNumber": "+33",
    "countryCode": "FR"
  },
  {
    "name": "Französisch-Guayana",
    "dialingNumber": "+594",
    "countryCode": "GF"
  },
  {
    "name": "Französisch-Polynesien",
    "dialingNumber": "+689",
    "countryCode": "PF"
  },
  {
    "name": "Gabun",
    "dialingNumber": "+241",
    "countryCode": "GA"
  },
  {
    "name": "Gambia",
    "dialingNumber": "+220",
    "countryCode": "GM"
  },
  {
    "name": "Georgien",
    "dialingNumber": "+995",
    "countryCode": "GE"
  },
  {
    "name": "Ghana",
    "dialingNumber": "+233",
    "countryCode": "GH"
  },
  {
    "name": "Gibraltar",
    "dialingNumber": "+350",
    "countryCode": "GI"
  },
  {
    "name": "Grenada",
    "dialingNumber": "+1473",
    "countryCode": "GD"
  },
  {
    "name": "Griechenland",
    "dialingNumber": "+30",
    "countryCode": "GR"
  },
  {
    "name": "Grönland",
    "dialingNumber": "+299",
    "countryCode": "GL"
  },
  {
    "name": "Guadeloupe",
    "dialingNumber": "+590",
    "countryCode": "GP"
  },
  {
    "name": "Guam",
    "dialingNumber": "+1671",
    "countryCode": "GU"
  },
  {
    "name": "Guatemala",
    "dialingNumber": "+502",
    "countryCode": "GT"
  },
  {
    "name": "Guinea",
    "dialingNumber": "+224",
    "countryCode": "GN"
  },
  {
    "name": "Guinea-Bissau",
    "dialingNumber": "+245",
    "countryCode": "GW"
  },
  {
    "name": "Guyana",
    "dialingNumber": "+592",
    "countryCode": "GY"
  },
  {
    "name": "Haiti",
    "dialingNumber": "+509",
    "countryCode": "HT"
  },
  {
    "name": "Hawaii",
    "dialingNumber": "+1808",
    "countryCode": "US"
  },
  {
    "name": "Honduras",
    "dialingNumber": "+504",
    "countryCode": "HN"
  },
  {
    "name": "Hong Kong",
    "dialingNumber": "+852",
    "countryCode": "HK"
  },
  {
    "name": "Indien",
    "dialingNumber": "+91",
    "countryCode": "IN"
  },
  {
    "name": "Indonesien",
    "dialingNumber": "+62",
    "countryCode": "ID"
  },
  {
    "name": "Irak",
    "dialingNumber": "+964",
    "countryCode": "IQ"
  },
  {
    "name": "Iran",
    "dialingNumber": "+98",
    "countryCode": "IR"
  },
  {
    "name": "Irland",
    "dialingNumber": "+353",
    "countryCode": "IE"
  },
  {
    "name": "Island",
    "dialingNumber": "+354",
    "countryCode": "IS"
  },
  {
    "name": "Israel",
    "dialingNumber": "+972",
    "countryCode": "IL"
  },
  {
    "name": "Italien",
    "dialingNumber": "+39",
    "countryCode": "IT"
  },
  {
    "name": "Jamaika",
    "dialingNumber": "+1876",
    "countryCode": "JM"
  },
  {
    "name": "Japan",
    "dialingNumber": "+81",
    "countryCode": "JP"
  },
  {
    "name": "Jemen",
    "dialingNumber": "+967",
    "countryCode": "YE"
  },
  {
    "name": "Jordanien",
    "dialingNumber": "+962",
    "countryCode": "JO"
  },
  {
    "name": "Kambodscha",
    "dialingNumber": "+855",
    "countryCode": "KH"
  },
  {
    "name": "Kamerun",
    "dialingNumber": "+237",
    "countryCode": "CM"
  },
  {
    "name": "Kap Verde",
    "dialingNumber": "+238",
    "countryCode": "CV"
  },
  {
    "name": "Kenia",
    "dialingNumber": "+254",
    "countryCode": "KE"
  },
  {
    "name": "Kiribati",
    "dialingNumber": "+686",
    "countryCode": "KI"
  },
  {
    "name": "Kokosinseln",
    "dialingNumber": "+6722",
    "countryCode": "CC"
  },
  {
    "name": "Kolumbien",
    "dialingNumber": "+57",
    "countryCode": "CO"
  },
  {
    "name": "Mayotte",
    "dialingNumber": "+269",
    "countryCode": "YT"
  },
  {
    "name": "Demok. Rep. Kongo",
    "dialingNumber": "+243",
    "countryCode": "ZR"
  },
  {
    "name": "Kroatien",
    "dialingNumber": "+385",
    "countryCode": "HR"
  },
  {
    "name": "Kuba",
    "dialingNumber": "+53",
    "countryCode": "CU"
  },
  {
    "name": "Kuwait",
    "dialingNumber": "+965",
    "countryCode": "KW"
  },
  {
    "name": "Kirgisistan",
    "dialingNumber": "+996",
    "countryCode": "KG"
  },
  {
    "name": "Laos",
    "dialingNumber": "+856",
    "countryCode": "LA"
  },
  {
    "name": "Lesotho",
    "dialingNumber": "+266",
    "countryCode": "LS"
  },
  {
    "name": "Lettland",
    "dialingNumber": "+371",
    "countryCode": "LV"
  },
  {
    "name": "Libanon",
    "dialingNumber": "+961",
    "countryCode": "LB"
  },
  {
    "name": "Liberia",
    "dialingNumber": "+231",
    "countryCode": "LR"
  },
  {
    "name": "Libyen",
    "dialingNumber": "+218",
    "countryCode": "LY"
  },
  {
    "name": "Liechtenstein",
    "dialingNumber": "+423",
    "countryCode": "LI"
  },
  {
    "name": "Litauen",
    "dialingNumber": "+370",
    "countryCode": "LT"
  },
  {
    "name": "Luxemburg",
    "dialingNumber": "+352",
    "countryCode": "LU"
  },
  {
    "name": "Macau",
    "dialingNumber": "+853",
    "countryCode": "MO"
  },
  {
    "name": "Madagaskar",
    "dialingNumber": "+261",
    "countryCode": "MG"
  },
  {
    "name": "Malawi",
    "dialingNumber": "+265",
    "countryCode": "MW"
  },
  {
    "name": "Malaysia",
    "dialingNumber": "+60",
    "countryCode": "MY"
  },
  {
    "name": "Malediven",
    "dialingNumber": "+960",
    "countryCode": "MV"
  },
  {
    "name": "Mali",
    "dialingNumber": "+223",
    "countryCode": "ML"
  },
  {
    "name": "Malta",
    "dialingNumber": "+356",
    "countryCode": "MT"
  },
  {
    "name": "Marokko",
    "dialingNumber": "+212",
    "countryCode": "MA"
  },
  {
    "name": "Marshall-Inseln",
    "dialingNumber": "+692",
    "countryCode": "MH"
  },
  {
    "name": "Martinique",
    "dialingNumber": "+596",
    "countryCode": "MQ"
  },
  {
    "name": "Mauretanien",
    "dialingNumber": "+222",
    "countryCode": "MR"
  },
  {
    "name": "Mauritius",
    "dialingNumber": "+230",
    "countryCode": "MU"
  },
  {
    "name": "Mazedonien (ehem. jugoslawische Republik)",
    "dialingNumber": "+389",
    "countryCode": "MK"
  },
  {
    "name": "Mexiko",
    "dialingNumber": "+52",
    "countryCode": "MX"
  },
  {
    "name": "Mosambik",
    "dialingNumber": "+258",
    "countryCode": "MZ"
  },
  {
    "name": "Moldawien",
    "dialingNumber": "+373",
    "countryCode": "MD"
  },
  {
    "name": "Monaco",
    "dialingNumber": "+377",
    "countryCode": "MC"
  },
  {
    "name": "Mongolei",
    "dialingNumber": "+976",
    "countryCode": "MN"
  },
  {
    "name": "Montenegro",
    "dialingNumber": "+382",
    "countryCode": "XM"
  },
  {
    "name": "Montserrat",
    "dialingNumber": "+1664",
    "countryCode": "MS"
  },
  {
    "name": "Myanmar",
    "dialingNumber": "+95",
    "countryCode": "MM"
  },
  {
    "name": "Namibia",
    "dialingNumber": "+264",
    "countryCode": "NA"
  },
  {
    "name": "Nauru",
    "dialingNumber": "+674",
    "countryCode": "NR"
  },
  {
    "name": "Nepal",
    "dialingNumber": "+977",
    "countryCode": "NP"
  },
  {
    "name": "Neukaledonien",
    "dialingNumber": "+687",
    "countryCode": "NC"
  },
  {
    "name": "Neuseeland",
    "dialingNumber": "+64",
    "countryCode": "NZ"
  },
  {
    "name": "Nicaragua",
    "dialingNumber": "+505",
    "countryCode": "NI"
  },
  {
    "name": "Niederlande",
    "dialingNumber": "+31",
    "countryCode": "NL"
  },
  {
    "name": "Niederländische Antillen",
    "dialingNumber": "+599",
    "countryCode": "AN"
  },
  {
    "name": "Niger",
    "dialingNumber": "+227",
    "countryCode": "NE"
  },
  {
    "name": "Nigeria",
    "dialingNumber": "+234",
    "countryCode": "NG"
  },
  {
    "name": "Niue",
    "dialingNumber": "+683",
    "countryCode": "NU"
  },
  {
    "name": "Norfolkinsel",
    "dialingNumber": "+6723",
    "countryCode": "NF"
  },
  {
    "name": "Norwegen",
    "dialingNumber": "+47",
    "countryCode": "NO"
  },
  {
    "name": "Nordkorea",
    "dialingNumber": "+850",
    "countryCode": "KP"
  },
  {
    "name": "Oman",
    "dialingNumber": "+968",
    "countryCode": "OM"
  },
  {
    "name": "Ost-Timor",
    "dialingNumber": "+670",
    "countryCode": "TP"
  },
  {
    "name": "Österreich",
    "dialingNumber": "+43",
    "countryCode": "AT"
  },
  {
    "name": "Pakistan",
    "dialingNumber": "+92",
    "countryCode": "PK"
  },
  {
    "name": "Palästina",
    "dialingNumber": "+970",
    "countryCode": "PS"
  },
  {
    "name": "Palau",
    "dialingNumber": "+680",
    "countryCode": "PW"
  },
  {
    "name": "Panama",
    "dialingNumber": "+507",
    "countryCode": "PA"
  },
  {
    "name": "Papua - Neu Guinea",
    "dialingNumber": "+675",
    "countryCode": "PG"
  },
  {
    "name": "Paraguay",
    "dialingNumber": "+595",
    "countryCode": "PY"
  },
  {
    "name": "Peru",
    "dialingNumber": "+51",
    "countryCode": "PE"
  },
  {
    "name": "Philippinen",
    "dialingNumber": "+63",
    "countryCode": "PH"
  },
  {
    "name": "Pitcairn",
    "dialingNumber": "+649",
    "countryCode": "PN"
  },
  {
    "name": "Polen",
    "dialingNumber": "+48",
    "countryCode": "PL"
  },
  {
    "name": "Portugal",
    "dialingNumber": "+351",
    "countryCode": "PT"
  },
  {
    "name": "Puerto Rico",
    "dialingNumber": "+1787",
    "countryCode": "PR"
  },
  {
    "name": "Katar",
    "dialingNumber": "+974",
    "countryCode": "QA"
  },
  {
    "name": "Reunion",
    "dialingNumber": "+262",
    "countryCode": "RE"
  },
  {
    "name": "Ruanda",
    "dialingNumber": "+250",
    "countryCode": "RW"
  },
  {
    "name": "Rumänien",
    "dialingNumber": "+40",
    "countryCode": "RO"
  },
  {
    "name": "Russland",
    "dialingNumber": "+7",
    "countryCode": "RU"
  },
  {
    "name": "Salomonen",
    "dialingNumber": "+677",
    "countryCode": "SB"
  },
  {
    "name": "Sambia",
    "dialingNumber": "+260",
    "countryCode": "ZM"
  },
  {
    "name": "Samoa",
    "dialingNumber": "+685",
    "countryCode": "WS"
  },
  {
    "name": "San Marino",
    "dialingNumber": "+378",
    "countryCode": "SM"
  },
  {
    "name": "Sao Tome und Principe",
    "dialingNumber": "+239",
    "countryCode": "ST"
  },
  {
    "name": "Saudi-Arabien",
    "dialingNumber": "+966",
    "countryCode": "SA"
  },
  {
    "name": "Schweden",
    "dialingNumber": "+46",
    "countryCode": "SE"
  },
  {
    "name": "Schweiz",
    "dialingNumber": "+41",
    "countryCode": "CH"
  },
  {
    "name": "Senegal",
    "dialingNumber": "+221",
    "countryCode": "SN"
  },
  {
    "name": "Serbien",
    "dialingNumber": "+381",
    "countryCode": "RS"
  },
  {
    "name": "Seychellen",
    "dialingNumber": "+248",
    "countryCode": "SC"
  },
  {
    "name": "Sierra Leone",
    "dialingNumber": "+232",
    "countryCode": "SL"
  },
  {
    "name": "Singapur",
    "dialingNumber": "+65",
    "countryCode": "SG"
  },
  {
    "name": "Slowakei",
    "dialingNumber": "+421",
    "countryCode": "SK"
  },
  {
    "name": "Slowenien",
    "dialingNumber": "+386",
    "countryCode": "SI"
  },
  {
    "name": "Somalia",
    "dialingNumber": "+252",
    "countryCode": "SO"
  },
  {
    "name": "Spanien",
    "dialingNumber": "+34",
    "countryCode": "ES"
  },
  {
    "name": "Sri Lanka",
    "dialingNumber": "+94",
    "countryCode": "LK"
  },
  {
    "name": "St. Helena",
    "dialingNumber": "+290",
    "countryCode": "SH"
  },
  {
    "name": "St. Kitts und Nevis",
    "dialingNumber": "+1869",
    "countryCode": "KN"
  },
  {
    "name": "St. Lucia",
    "dialingNumber": "+1758",
    "countryCode": "LC"
  },
  {
    "name": "St. Pierre und Miquelon",
    "dialingNumber": "+508",
    "countryCode": "PM"
  },
  {
    "name": "St. Vincent und die Grenadinen",
    "dialingNumber": "+1784",
    "countryCode": "VC"
  },
  {
    "name": "Südafrika",
    "dialingNumber": "+27",
    "countryCode": "ZA"
  },
  {
    "name": "Sudan",
    "dialingNumber": "+249",
    "countryCode": "SD"
  },
  {
    "name": "Südkorea",
    "dialingNumber": "+82",
    "countryCode": "KR"
  },
  {
    "name": "Suriname",
    "dialingNumber": "+597",
    "countryCode": "SR"
  },
  {
    "name": "Swasiland",
    "dialingNumber": "+268",
    "countryCode": "SZ"
  },
  {
    "name": "Syrien",
    "dialingNumber": "+963",
    "countryCode": "SY"
  },
  {
    "name": "Tadshikistan",
    "dialingNumber": "+992",
    "countryCode": "TJ"
  },
  {
    "name": "Taiwan",
    "dialingNumber": "+886",
    "countryCode": "TW"
  },
  {
    "name": "Tansania",
    "dialingNumber": "+255",
    "countryCode": "TZ"
  },
  {
    "name": "Thailand",
    "dialingNumber": "+66",
    "countryCode": "TH"
  },
  {
    "name": "Togo",
    "dialingNumber": "+228",
    "countryCode": "TG"
  },
  {
    "name": "Tokelau",
    "dialingNumber": "+690",
    "countryCode": "TK"
  },
  {
    "name": "Tonga",
    "dialingNumber": "+676",
    "countryCode": "TO"
  },
  {
    "name": "Trinidad und Tobago",
    "dialingNumber": "+1868",
    "countryCode": "TT"
  },
  {
    "name": "Tschad",
    "dialingNumber": "+235",
    "countryCode": "TD"
  },
  {
    "name": "Tschechische Republik",
    "dialingNumber": "+420",
    "countryCode": "CZ"
  },
  {
    "name": "Tunesien",
    "dialingNumber": "+216",
    "countryCode": "TN"
  },
  {
    "name": "Türkei",
    "dialingNumber": "+90",
    "countryCode": "TR"
  },
  {
    "name": "Turkmenistan",
    "dialingNumber": "+993",
    "countryCode": "TM"
  },
  {
    "name": "Turks- und Caicosinseln",
    "dialingNumber": "+1649",
    "countryCode": "TC"
  },
  {
    "name": "Tuvalu",
    "dialingNumber": "+688",
    "countryCode": "TV"
  },
  {
    "name": "Uganda",
    "dialingNumber": "+256",
    "countryCode": "UG"
  },
  {
    "name": "Ukraine",
    "dialingNumber": "+380",
    "countryCode": "UA"
  },
  {
    "name": "Ungarn",
    "dialingNumber": "+36",
    "countryCode": "HU"
  },
  {
    "name": "Uruguay",
    "dialingNumber": "+598",
    "countryCode": "UY"
  },
  {
    "name": "USA",
    "dialingNumber": "+1",
    "countryCode": "US"
  },
  {
    "name": "Usbekistan",
    "dialingNumber": "+998",
    "countryCode": "UZ"
  },
  {
    "name": "Vanuatu",
    "dialingNumber": "+678",
    "countryCode": "VU"
  },
  {
    "name": "Vatikan",
    "dialingNumber": "+379",
    "countryCode": "VA"
  },
  {
    "name": "Venezuela",
    "dialingNumber": "+58",
    "countryCode": "VE"
  },
  {
    "name": "Vereinigte Arabische Emirate",
    "dialingNumber": "+971",
    "countryCode": "AE"
  },
  {
    "name": "Vereinigtes Königreich",
    "dialingNumber": "+44",
    "countryCode": "GB"
  },
  {
    "name": "Vietnam",
    "dialingNumber": "+84",
    "countryCode": "VN"
  },
  {
    "name": "Wallis und Futuna",
    "dialingNumber": "+681",
    "countryCode": "WF"
  },
  {
    "name": "Weihnachtsinsel",
    "dialingNumber": "+6724",
    "countryCode": "CX"
  },
  {
    "name": "Weißrussland",
    "dialingNumber": "+375",
    "countryCode": "BY"
  },
  {
    "name": "Zentralafrikanische Republik",
    "dialingNumber": "+236",
    "countryCode": "CF"
  },
  {
    "name": "Simbabwe",
    "dialingNumber": "+263",
    "countryCode": "ZW"
  },
  {
    "name": "Zypern",
    "dialingNumber": "+357",
    "countryCode": "CY"
  },
  {
    "name": "Elfenbeinküste",
    "dialingNumber": "+225",
    "countryCode": "CI"
  },
  {
    "name": "Nördl. Marianen",
    "dialingNumber": "+1670",
    "countryCode": "MP"
  },
  {
    "name": "Brit.Jungferni.",
    "dialingNumber": "+1340",
    "countryCode": "VG"
  },
  {
    "name": "Amer.Jungferni.",
    "dialingNumber": "+1284",
    "countryCode": "VI"
  },
  {
    "name": "Jugoslawien",
    "dialingNumber": "+38",
    "countryCode": "YU"
  }
]

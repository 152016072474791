@if (showLoadingState()) {
  <ng-content select="loading">
    <mwe-basic-card [hasBackground]="false">
      <dotlottie-player
        class="mx-auto mb-5"
        aria-hidden="true"
        autoplay
        loop
        playMode="normal"
        id="dot-lottie-confirmation-loading"
        [mweDotLottiePlayerSrc]="getImagePath() + 'lottie/windmills.lottie'"
        playerId="dot-lottie-confirmation-loading"
        style="max-width: 273px; display: block"
      >
      </dotlottie-player>
      <h1 data-test="mwe-confirmation-view-header-loading" class="h5 text-center" [translate]="workInProgressMsgKey"></h1>
      <span class="text--large text-center d-block" *ngIf="workInProgressSubtextKey" [translate]="workInProgressSubtextKey"></span>
    </mwe-basic-card>
  </ng-content>
}
@if (showErrorState()) {
  <ng-content select="error">
    <div class="mb-5" *ngIf="errorTitleKey" data-test="mwe-confirmation-view-error">
      <mwe-alert
        [type]="'danger'"
        [titleKey]="errorTitleKey"
        [messageKey]="retryAllowed() && errorMsgRetryKey ? errorMsgRetryKey : errorMsgKey"
        [placeholderMap]="alertPlaceholders"
      ></mwe-alert>
    </div>
    <mwe-basic-card *ngIf="!isFirstStepError()" [hasBackground]="false">
      <ng-container *ngTemplateOutlet="stepsList; context: { steps: steps() }"></ng-container>
    </mwe-basic-card>
  </ng-content>
}
@if (showSuccessState()) {
  <ng-content select="success">
    <mwe-basic-card *ngIf="infoSteps && infoSteps.length" data-test="mwe-confirmation-view-success-info">
      <mwe-next-steps [title]="infoTitleKey" [infoSteps]="infoSteps">
        <img
          src="https://static.wienenergie.at/projects/meine.wienenergie.at/assets/images/success.svg"
          alt="Erfolg"
          class="mx-auto mb-5"
        />
      </mwe-next-steps>
    </mwe-basic-card>
    <mwe-basic-card *ngIf="!(infoSteps && infoSteps.length)" [hasBackground]="false" data-test="mwe-confirmation-view-success-no-info">
      <div class="d-flex flex-column align-items-center">
        <img src="/assets/img/success-animation_no_text.gif" alt="Success" class="mx-auto mb-5" style="max-width: 214px" />
        <h1 *ngIf="successMsgKey" class="h4" [translate]="successMsgKey"></h1>
        <span class="text--large text-center d-block" *ngIf="successSubtextKey" [translate]="successSubtextKey"></span>
      </div>
    </mwe-basic-card>
  </ng-content>
}

<ng-template #stepsList let-steps="steps">
  @for (item of steps; track item; let last = $last) {
    @if (stepRenderer) {
      <ng-container *ngComponentOutlet="stepRenderer; inputs: { step: item.step, state: item.state }"></ng-container>
    } @else {
      <div class="backend-process-step d-flex align-items-center" [ngClass]="{ 'mb-3': !last }">
        <i *ngIf="getIconClassForStep(item)" class="fas backend-process-step-icon mr-3" [ngClass]="getIconClassForStep(item)"></i>
        <div class="backend-process-step-content">
          <span
            class="backend-process-step-title"
            [translate]="getTitleForStep(item)"
            [translateParams]="item.step.translationParams"
          ></span>
          <ng-container *ngFor="let label of getLabelsForStep(item)"
            >&nbsp;<span class="backend-process-step-label" [translate]="label" [translateParams]="item.step.translationParams"></span
          ></ng-container>
        </div>
      </div>
    }
  }
</ng-template>

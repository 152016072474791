import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import {
  Address,
  CommonMappingData,
  DataChangeType,
  IBankDetails,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
  IOlavEntry,
  Product,
  createAddress,
} from '@mwe/models';
import { ProductsWhiteListLogic } from '../../../products/products-white-list.logic';

const PRODUCTS_KEY = 'invoiceDataChange_products';
const ADDRESS_KEY = 'invoiceDataChange_address';
const IS_ADDRESS_SELECTION_VISIBLE_KEY = 'invoiceDataChange_isAddressSelectionVisible';
const LAST_ORDER_ID_KEY = 'invoiceDataChange_lastOrderId';
const RETRIED_ON_ERROR_KEY = 'invoiceDataChange_retriedOnError';
const SUBMIT_ID_KEY = 'invoiceDataChange_submitId';
const BANK_ACCOUNT_DATA_KEY = 'invoiceDataChange_bankAccountData';
const ERECHNUNGS_INFO_KEY = 'invoiceDataChange_eRechnungsInfo';
const INVOICE_ADDRESS_KEY = 'invoiceDataChange_invoiceAddress';
const INVOICE_OLAV_ENTRY_KEY = 'invoieDataChange_invoiceOlavEntry';
const ACCOUNT_CATEGORIES_MAPPING_KEY = 'invoiceDataChange_accountCategoriesMapping';
const DIFFERENT_INVOICE_RECIPIENT_KEY = 'invoiceDataChange_differentInvoiceRecipient';
const MODE_KEY = 'invoiceDataChange_mode';
const UNSUPPORTED_CATEGORIES_KEY = 'invoiceDataChange_unsupportedCategories';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataChangeStateService {
  keepStorageOnce = false;

  constructor(
    private storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {}

  @SessionStorage(PRODUCTS_KEY)
  private _products: Product[];

  get products(): Product[] {
    if (!Array.isArray(this._products)) {
      return undefined;
    }

    return this._products;
  }

  set products(products: Product[]) {
    if (!Array.isArray(products)) {
      this.storage.clear(PRODUCTS_KEY);
      return;
    }

    this._products = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
  }

  @SessionStorage(ADDRESS_KEY)
  private _address: Address;

  get address(): Address {
    if (!this._address) {
      return undefined;
    }

    return createAddress(this._address);
  }

  set address(address: Address) {
    if (!address) {
      this.storage.clear(ADDRESS_KEY);
    }

    this._address = address;
  }

  @SessionStorage(IS_ADDRESS_SELECTION_VISIBLE_KEY)
  private _isAddressSelectionVisible: boolean;

  get isAddressSelectionVisible(): boolean {
    return !!this._isAddressSelectionVisible;
  }

  set isAddressSelectionVisible(value: boolean) {
    if (!value) {
      this.storage.clear(IS_ADDRESS_SELECTION_VISIBLE_KEY);
      return;
    }

    this._isAddressSelectionVisible = value;
  }

  @SessionStorage(LAST_ORDER_ID_KEY)
  private _lastOrderId: string;

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(LAST_ORDER_ID_KEY);
    }
  }

  @SessionStorage(RETRIED_ON_ERROR_KEY)
  private _retriedOnError: boolean;

  get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(RETRIED_ON_ERROR_KEY);
    }
  }

  @SessionStorage(SUBMIT_ID_KEY)
  private _submitId: number;

  get submitId(): number {
    return this._submitId || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(SUBMIT_ID_KEY);
    }
  }

  @SessionStorage(BANK_ACCOUNT_DATA_KEY)
  private _bankAccountData: IBankDetails;

  get bankAccountData(): IBankDetails {
    return this._bankAccountData || undefined;
  }

  set bankAccountData(bankAccountData: IBankDetails) {
    if (bankAccountData) {
      this._bankAccountData = bankAccountData;
    } else {
      this.storage.clear(BANK_ACCOUNT_DATA_KEY);
    }
  }

  @SessionStorage(ERECHNUNGS_INFO_KEY)
  private _eRechnungsInfo: IERechnungsInfo;

  get eRechnungsInfo(): IERechnungsInfo {
    return this._eRechnungsInfo || undefined;
  }

  set eRechnungsInfo(eRechnungsInfo: IERechnungsInfo) {
    if (eRechnungsInfo) {
      this._eRechnungsInfo = eRechnungsInfo;
    } else {
      this.storage.clear(ERECHNUNGS_INFO_KEY);
    }
  }

  @SessionStorage(INVOICE_ADDRESS_KEY)
  private _invoiceAddress: Address;

  get invoiceAddress(): Address {
    return this._invoiceAddress || undefined;
  }

  set invoiceAddress(invoiceAddress: Address) {
    if (invoiceAddress) {
      this._invoiceAddress = invoiceAddress;
    } else {
      this.storage.clear(INVOICE_ADDRESS_KEY);
    }
  }

  @SessionStorage(INVOICE_OLAV_ENTRY_KEY)
  private _invoiceOlavEntry: IOlavEntry;

  get invoiceOlavEntry(): IOlavEntry {
    return this._invoiceOlavEntry || undefined;
  }

  set invoiceOlavEntry(invoiceOlavEntry: IOlavEntry) {
    if (invoiceOlavEntry) {
      this._invoiceOlavEntry = invoiceOlavEntry;
    } else {
      this.storage.clear(INVOICE_OLAV_ENTRY_KEY);
    }
  }

  @SessionStorage(ACCOUNT_CATEGORIES_MAPPING_KEY)
  private _accountCategoriesMapping: CommonMappingData[];

  get accountCategoriesMapping(): CommonMappingData[] {
    return this._accountCategoriesMapping || undefined;
  }

  set accountCategoriesMapping(accountCategoriesMapping: CommonMappingData[]) {
    if (accountCategoriesMapping) {
      this._accountCategoriesMapping = accountCategoriesMapping;
    } else {
      this.storage.clear(ACCOUNT_CATEGORIES_MAPPING_KEY);
    }
  }

  @SessionStorage(DIFFERENT_INVOICE_RECIPIENT_KEY)
  private _differentInvoiceRecipient: IDifferentInvoiceRecipient;

  get differentInvoiceRecipient(): IDifferentInvoiceRecipient {
    return this._differentInvoiceRecipient;
  }

  set differentInvoiceRecipient(value: IDifferentInvoiceRecipient) {
    if (value === undefined) {
      this.storage.clear(DIFFERENT_INVOICE_RECIPIENT_KEY);
      return;
    }
    this._differentInvoiceRecipient = value;
  }

  @SessionStorage(MODE_KEY)
  private _mode: DataChangeType;

  get mode(): DataChangeType {
    return this._mode;
  }

  set mode(mode: DataChangeType) {
    this._mode = mode;
  }

  @SessionStorage(UNSUPPORTED_CATEGORIES_KEY)
  private _unsupportedCategories: string[][];

  get unsupportedCategories(): string[][] {
    return this._unsupportedCategories;
  }

  set unsupportedCategories(value: string[][]) {
    this._unsupportedCategories = value;
  }

  clear(): void {
    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(MODE_KEY);
    this.storage.clear(IS_ADDRESS_SELECTION_VISIBLE_KEY);
    this.storage.clear(LAST_ORDER_ID_KEY);
    this.clearForNewAddress();
  }

  clearForNewAddress(): void {
    this.storage.clear(PRODUCTS_KEY);
    this.storage.clear(ADDRESS_KEY);
    this.storage.clear(RETRIED_ON_ERROR_KEY);
    this.storage.clear(SUBMIT_ID_KEY);
    this.storage.clear(BANK_ACCOUNT_DATA_KEY);
    this.storage.clear(ERECHNUNGS_INFO_KEY);
    this.storage.clear(DIFFERENT_INVOICE_RECIPIENT_KEY);
    this.storage.clear(ACCOUNT_CATEGORIES_MAPPING_KEY);
    this.storage.clear(INVOICE_ADDRESS_KEY);
    this.storage.clear(INVOICE_OLAV_ENTRY_KEY);
    this.storage.clear(UNSUPPORTED_CATEGORIES_KEY);
  }
}

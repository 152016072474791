<div class="item" role="listitem">
  <div class="image">
    <div class="bubble align-items-center justify-content-center align-items-center d-flex" [ngClass]="bubbleClassList">
      <i *ngIf="!useTextIndex" class="fas" [ngClass]="bubbleIconClass" aria-hidden="true"></i>
      <div *ngIf="useTextIndex" class="bubble-text text-center">{{ index }}</div>
    </div>
  </div>
  <div class="details">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>

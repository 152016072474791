import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ServiceStateEnum } from '@mwe/constants';

export const copyToClipboard = <T>(data: T) => {
  if (!navigator?.clipboard || !navigator?.clipboard?.writeText || !data) {
    return throwError(() => ServiceStateEnum.ERROR) as Observable<ServiceStateEnum>;
  }

  const dataToCopy$ = of(data).pipe(map(data => (typeof data === 'string' ? data : JSON.stringify(data))));

  return dataToCopy$.pipe(
    switchMap(dataToCopy => fromPromise(navigator.clipboard.writeText(dataToCopy))),
    catchError(_ => {
      return throwError(() => ServiceStateEnum.ERROR) as Observable<ServiceStateEnum>;
    }),
    map(() => ServiceStateEnum.SUCCESS),
  );
};

@if (paymentPlan()) {
  <div class="mb-5 mb-lg-6">
    <mwe-infobox type="info" infoText="active-payment-plan.entry.message">
      <p
        class="mb-0"
        mweLinkInLabelClick="active-payment-plan-entry"
        (linkClicked)="onActivePaymentLinkClicked()"
        [innerHTML]="'active-payment-plan.entry.message' | translate | safeHtml"
      ></p>
    </mwe-infobox>
  </div>
}

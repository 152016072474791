import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@mwe/models';
import { getAddressPostCodeFormatted } from '@mwe/utils';

@Pipe({
  name: 'getAddressPostCode',
})
export class GetAddressPostCodePipe implements PipeTransform {
  transform(address: Address): string {
    return getAddressPostCodeFormatted(address);
  }
}
